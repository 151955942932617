import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import defaultSalon1 from "../../assets/images/images/defaultSalon.avif";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const GalleryModal = ({ show, setShow, data }) => {
  const [imageLoadStatus, setImageLoadStatus] = useState([]);
  const handleImageLoadError = (index) => {
    setImageLoadStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = true;
      return newStatus;
    });
  };
  return (
    <Modal size="lg" centered show={show} onHide={setShow}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>{data?.name}'s gallery</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Swiper
          pagination={{
            type: "progressbar",
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {data?.gallery?.map((image, i) => (
            <SwiperSlide key={i}>
              <img
                src={imageLoadStatus[i] ? defaultSalon1 : image}
                className="galleryImage"
                alt="Gallery Im"
                onError={() => {
                  handleImageLoadError(i);
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal.Body>
    </Modal>
  );
};
export default GalleryModal;
