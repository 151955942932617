import React from "react";
import { useSelector } from "react-redux";

export const StepBar = () => {
  const { step: activeStep } = useSelector((state) => state?.service);
  return (
    <div className="position-relative m-2 ">
      <div className="progress w-100" style={{ height: "3px" }}>
        <div
          className="progress-bar bg__pink"
          role="progressbar"
          style={{ width: `${(activeStep - 1) * 33.33}%` }}
          aria-valuenow={activeStep * 33.33}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <button
        type="button"
        className={`position-absolute top-0 custom-start-0 translate-middle btn btn-sm ${
          activeStep >= 1 ? "btn-dark-pink" : "btn-secondary"
        } rounded-pill`}
        style={{ width: "2rem", height: "2rem" }}
      >
        1
      </button>
      <p className="position-absolute top-0 custom-start-0 translate-middle mt-4 pt-3">
        Service
      </p>
      <button
        type="button"
        className={`position-absolute top-0 custom-start-25 translate-middle btn btn-sm ${
          activeStep >= 2 ? "btn-dark-pink" : "btn-secondary"
        } rounded-pill`}
        style={{ width: "2rem", height: "2rem" }}
      >
        2
      </button>
      <p className="position-absolute top-0 custom-start-25 translate-middle mt-4 pt-3">
        Staff
      </p>
      <button
        type="button"
        className={`position-absolute top-0 custom-start-50 translate-middle btn btn-sm ${
          activeStep >= 3 ? "btn-dark-pink" : "btn-secondary"
        } rounded-pill`}
        style={{ width: "2rem", height: "2rem" }}
      >
        3
      </button>
      <p className="position-absolute top-0 custom-start-50 translate-middle mt-4 pt-3">
        Time
      </p>
      <button
        type="button"
        className={`position-absolute top-0 custom-start-75 translate-middle btn btn-sm ${
          activeStep >= 4 ? "btn-dark-pink" : "btn-secondary"
        } rounded-pill`}
        style={{ width: "2rem", height: "2rem" }}
      >
        4
      </button>
      <p className="position-absolute top-0 custom-start-75 translate-middle mt-4 pt-3">
      Confirm
      </p>
    </div>
  );
};
