import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStaff, setStep } from "../../redux/slices/serviceSlice";
import { Link, useNavigate } from "react-router-dom";
import { StepBar } from "../StepBar";

const AppointmentStepThree = () => {
  const { staff: staffList, store } = useSelector(
    (e) => e?.store?.storeDetails
  );
  const navigate = useNavigate();
  const { staff, services } = useSelector((e) => e?.service);
  useEffect(() => {
    dispatch(setStep(2));
    // eslint-disable-next-line
  }, []);
  const totalAmount = services?.reduce((acc, obj) => {
    const valueToAdd = obj?.value;
    if (typeof valueToAdd === "number") {
      return acc + valueToAdd;
    } else {
      return acc;
    }
  }, 0);

  const totalDuration = services?.reduce((acc, obj) => {
    const valueToAdd = obj?.duration;
    if (typeof valueToAdd === "number") {
      return acc + valueToAdd;
    } else {
      return acc;
    }
  }, 0);

  const dispatch = useDispatch();
  const [selectedStaff, setSelectedStaff] = useState(staff);
  const [staffImageError, setStaffImageError] = useState(false);
  const handleCardClick = (id) => {
    setStaffImageError(false)
    dispatch(setStaff(id));
    setSelectedStaff(id);
  };

  useEffect(() => {
    if (!services?.length > 0) {
      navigate("/salon/select-service");
    }
  }, []);

  const [imageLoadStatus, setImageLoadStatus] = useState([]);
  const handleImageLoadError = (index) => {
    // Set the corresponding index in the imageLoadStatus array to true
    setImageLoadStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = true;
      return newStatus;
    });
  };

  return (
    <div id="react" className="react--loading">
      <div className="GXRSVM notranslate">
        <div className="mre0Hq w+g1+Q" featureflags="[object Object]">
          <div className="_32iRow">
            <div className="Df5Lk6">
              <div className="E4hVuT JdS1yT xa-rvT">
                <div className="_7nJ-pT JdS1yT">
                  <div className="wwqFAT JdS1yT">
                    <Link to="/salon/select-service">
                      <button
                        id="button-header-flow-back"
                        data-qa="header-flow-back"
                        className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU DetaJT"
                        type="button"
                        aria-pressed="false"
                        aria-labelledby="label-header-flow-back"
                        aria-live="polite"
                      >
                        <span className="G9I_8U va37oU">
                          <span
                            id="label-header-flow-back"
                            className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                          >
                            Go back
                          </span>
                        </span>
                        <span className="_0f0EDU">
                          <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                  <div className="p1pdhT JdS1yT">
                    <span className="_-wKhBx rfrUux Bnx6rx dUG8gx ZCvJqx KfKZXx font-default-header-s-semibold font-tablet-header-s-semibold font-laptop-header-s-semibold font-desktop-header-s-semibold _zngzT">
                      Select professional
                    </span>
                  </div>

                  <div
                    className="wwqFAT ibymkT"
                    onClick={() =>
                      navigate(`/salon`, { state: { salon: store } })
                    }
                  >
                    <button
                      id="button-close-button"
                      data-qa="close-button"
                      className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU"
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="label-close-button"
                      aria-live="polite"
                    >
                      <span className="G9I_8U va37oU">
                        <span
                          id="label-close-button"
                          className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                        >
                          Close
                        </span>
                      </span>
                      <span className="_0f0EDU">
                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="YAjmY7 NekGj7">
                <div className="MZ1PW7" />
                <div className="otg7W7 NekGj7 tUf8c7">
                  <div className="iWARt7">
                    <div className="display-default-none display-laptop-flex">
                      <div data-qa="header-title" className="dYmf3l w-100 ">
                        <div className="container my-5">
                          <div className="col-11 mx-auto">
                            <StepBar />
                          </div>
                        </div>

                        <div className="fWGqQa fwrHUa _4zQoha Aocp_a gap-default-200x fWGqQa">
                          <h1
                            className="_-wKhBx rfrUux font-default-header-l-semibold font-tablet-header-xl-bold font-laptop-header-xl-bold font-desktop-header-xl-bold"
                            style={{ flexGrow: 1 }}
                          >
                            Select professional
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="display-default-flex display-laptop-none">
                      <div className="dYmf3l OZAYBl w-100 ">
                        <div className="container mt-3 mb-5">
                          <div className="col-11 mx-auto">
                            <StepBar />
                          </div>
                        </div>
                        <div className="fWGqQa fwrHUa _4zQoha Aocp_a gap-default-200x fWGqQa">
                          <h1
                            className="_-wKhBx rfrUux font-default-header-l-semibold font-tablet-header-xl-bold font-laptop-header-xl-bold font-desktop-header-xl-bold"
                            style={{ flexGrow: 1 }}
                          >
                            Select professional
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gbuHog Nekhlg aBzANg XHPkvf">
                    <div
                      aria-labelledby="label-react-aria-146"
                      role="radiogroup"
                      aria-orientation="vertical"
                      id="react-aria-147"
                      className="XWBwtF XWBwtF"
                    >
                      <div className="KfGRUx _-YxP9x" />
                      <div className="dmunbz OGODKz xa-PAz">
                        <div
                          onClick={() => handleCardClick("")}
                          data-qa="any-staff-tile"
                          id="wrapper-"
                          className={`yzh99b lEX9sW dQMzVz  ${
                            staff?._id === undefined ? "Hm5sqz" : ""
                          }`}
                          style={{ animationOrder: 1 }}
                        >
                          <div className="_5zCVQX gap-default-100 direction-default-vertical display-default-inline-flex">
                            <div className="MCrK4z OGODKz">
                              <div className="b6xJNc ZoPHjc GmK7bc _0alsKn">
                                <span
                                  className="_-6pwFd gQFycd"
                                  aria-hidden="true"
                                >
                                  <svg
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M12 10.125a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-4.5 3a4.5 4.5 0 1 1 7.23 3.578 6.76 6.76 0 0 1 3.344 3.218.75.75 0 0 1-1.348.658 5.26 5.26 0 0 0-9.452 0 .75.75 0 0 1-1.348-.658 6.76 6.76 0 0 1 3.345-3.218A4.493 4.493 0 0 1 7.5 13.125Z"
                                      clipRule="evenodd"
                                    />
                                    <path d="M4.926 5.735a2.25 2.25 0 0 1 2.906 1.718.75.75 0 1 0 1.473-.281 3.75 3.75 0 1 0-6.21 3.474 6.356 6.356 0 0 0-2.571 2.03.75.75 0 1 0 1.202.898 4.856 4.856 0 0 1 3.9-1.949.75.75 0 0 0 0-1.5 2.25 2.25 0 0 1-.7-4.39ZM17.82 5.696a2.25 2.25 0 1 1 .554 4.429.75.75 0 0 0 0 1.5 4.857 4.857 0 0 1 3.9 1.949.75.75 0 1 0 1.202-.898 6.356 6.356 0 0 0-2.571-2.03 3.751 3.751 0 1 0-6.21-3.474.75.75 0 0 0 1.473.281 2.25 2.25 0 0 1 1.651-1.758Z" />
                                  </svg>
                                </span>
                              </div>
                              <div className="_5zCVQX gap-default-25x direction-default-vertical display-default-inline-flex">
                                <p className="_-wKhBx rfrUux ZCvJqx font-default-body-s-medium">
                                  Any professional
                                </p>
                                <p className="_-wKhBx u4x-xx ZCvJqx font-default-body-xs-regular">
                                  Maximum availability
                                </p>
                              </div>
                            </div>
                            {/* <div className="_5zCVQX gap-default-100 direction-default-vertical display-default-inline-flex"> */}
                            {/* <p className="_-wKhBx rfrUux ZCvJqx font-default-body-xs-medium">from £170</p> */}
                            {/* </div> */}
                          </div>
                          {/* <input id tabIndex={0} type="radio" aria-live="polite" className="_7AUnpW" data-qa="input-any-staff-tile" defaultValue defaultChecked /> */}
                        </div>
                        {/* <div value="multi-staff" data-qa="multi-staff-tile" id="wrapper-multi-staff" className="yzh99b _7VUjlM lEX9sW dQMzVz _XdmmM" style={{ animationOrder: 2 }}>
                                                    <div className="_5zCVQX gap-default-100 direction-default-vertical display-default-inline-flex">
                                                        <div className="MCrK4z OGODKz">
                                                            <div className="b6xJNc ZoPHjc GmK7bc _0alsKn">
                                                                <span className="_-6pwFd gQFycd" aria-hidden="true">
                                                                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                        <path fillRule="evenodd" d="M6.747 5.696a2.25 2.25 0 1 0-.56 4.429h.001a.75.75 0 0 1 0 1.5h-.001a4.867 4.867 0 0 0-3.9 1.95.75.75 0 0 1-1.2-.9 6.367 6.367 0 0 1 2.574-2.03 3.75 3.75 0 1 1 6.21-3.474.75.75 0 1 1-1.473.282 2.25 2.25 0 0 0-1.65-1.757ZM20.063 4.5a.75.75 0 0 1 .75.75v1.5h1.5a.75.75 0 0 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0v-1.5h-1.5a.75.75 0 1 1 0-1.5h1.5v-1.5a.75.75 0 0 1 .75-.75Zm-7.5 5.625a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm2.73 6.577a4.5 4.5 0 1 0-5.462 0 6.75 6.75 0 0 0-3.34 3.22.75.75 0 1 0 1.35.656 5.252 5.252 0 0 1 9.444 0 .75.75 0 0 0 1.349-.656 6.753 6.753 0 0 0-3.34-3.22Z" clipRule="evenodd" /></svg>
                                                                </span>
                                                            </div>
                                                            <div id="label-multi-staff" className="_5zCVQX gap-default-25x direction-default-vertical display-default-inline-flex">
                                                                <p className="_-wKhBx rfrUux ZCvJqx font-default-body-s-medium">Select professional per service</p>
                                                            </div>
                                                        </div>
                                                        <div id="description-multi-staff" className="_5zCVQX gap-default-100 direction-default-vertical display-default-inline-flex">
                                                            <p className="_-wKhBx rfrUux ZCvJqx font-default-body-xs-medium">from £170</p>
                                                        </div>
                                                    </div><input aria-labelledby="label-multi-staff" id="multi-staff" tabIndex={-1} type="radio" aria-describedby="description-multi-staff" aria-live="polite" className="_7AUnpW" data-qa="input-multi-staff-tile" defaultValue="multi-staff" />
                                                </div> */}
                        {staffList?.length > 0 &&
                          staffList?.map((e, i) => (
                            <div
                              key={i}
                              onClick={() => handleCardClick(e)}
                              data-qa="employee-tile-830245"
                              id="wrapper-830245"
                              className={`yzh99b lEX9sW dQMzVz _XdmmM ${
                                staff?._id === e?._id && "Hm5sqz"
                              }`}
                              style={{ animationOrder: 3 }}
                            >
                              <div
                                className={`_5zCVQX gap-default-100 direction-default-vertical display-default-inline-flex ${
                                  staff?._id === e._id ? "" : ""
                                }`}
                              >
                                <div
                                  id="react-aria-153"
                                  className="Q-7_if gQFhIf"
                                  aria-labelledby="name-react-aria-153"
                                  aria-describedby="description-react-aria-153"
                                >
                                  <div className="zx4_3f gQFhIf">
                                    <div
                                      id="avatar-react-aria-153"
                                      className={`WiWLh_ gQFkA_`}
                                      data-qa="avatar-react-aria-153"
                                    >
                                      <div className="_-d_QU_">
                                        <picture
                                          data-qa="avatar-react-aria-153-image"
                                          className="rC5TQo aDoOGo oN-sZo"
                                        >
                                          <img
                                            src={e?.image}
                                            alt={e?.name}
                                            className="N3bmGo aDoOGo aQLF7o RwsSFo"
                                            style={{
                                              display: imageLoadStatus[i]
                                                ? "none"
                                                : "block",
                                            }}
                                            loading="eager"
                                            onError={() => {
                                              // Image failed to load, set imageLoadStatus for this index to true
                                              handleImageLoadError(i);
                                            }}
                                          />
                                        </picture>
                                        {imageLoadStatus[i] && (
                                          <div className="custom-fallback-div-staff">
                                            {e?.name?.charAt(0)?.toUpperCase()}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      id="rating-react-aria-153"
                                      data-qa="rating-react-aria-153"
                                      className="pC6Klq dCte9f"
                                    >
                                      <div className="aZptzi _-YxI9i">
                                        <p
                                          id="rating-react-aria-153-aria"
                                          className="qTs2Ai"
                                        >
                                          4.9 rating
                                        </p>
                                        <div className="ADCqki _-YxI9i">
                                          <span
                                            aria-hidden="true"
                                            className="_-wKhBx rfrUux _9CRQ7x font-default-body-xs-semibold"
                                          >
                                            5.0
                                          </span>
                                          <div
                                            className="mKS7Nt _-YxMpt"
                                            aria-hidden="true"
                                          >
                                            <span
                                              className="_-6pwFd Ua79ad"
                                              aria-hidden="true"
                                            >
                                              <svg
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id="label-830245"
                                    className="_5zCVQX gap-default-25x direction-default-vertical display-default-flex"
                                  >
                                    <p
                                      id="name-react-aria-153"
                                      data-qa="name-react-aria-153"
                                      className="_-wKhBx ZCvJqx font-default-body-s-medium"
                                    >
                                      {e?.name}
                                    </p>
                                    <p
                                      id="description-react-aria-153"
                                      data-qa="description-react-aria-153"
                                      className="_-wKhBx u4x-xx ZCvJqx font-default-body-xs-regular"
                                    >
                                      {e?.title}
                                    </p>
                                  </div>
                                </div>
                                {/* <div id="description-830245" className="_5zCVQX gap-default-100 direction-default-vertical display-default-inline-flex">
                                                            <p className="_-wKhBx rfrUux ZCvJqx font-default-body-xs-medium">from £225</p>
                                                        </div> */}
                              </div>
                              {/* <input aria-labelledby="label-830245" id={830245} tabIndex={-1} type="radio" aria-describedby="description-830245" aria-live="polite" className="_7AUnpW" data-qa="input-employee-tile-830245" defaultValue={830245} /> */}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="QrHNxj Aqljbj UuHXUj NekZnj aBz4Xj">
                    <div
                      id="booking-flow-cart"
                      data-qa="cart"
                      className="pzS8Sv OGOgmv Y5Bsdv whlC2v _10Cwnv Mnqemv PqhSRN"
                    >
                      <div className="h0MWJS">
                        <div className="POY5Gv XXoKXv BSVEBv dTkYBv WfxCyR">
                          <div className="hB0YAs">
                            <div className="b6xJNc J0Qdgc GmK7bc W-cbgF">
                              <picture className="rC5TQo _-rOGgo oN-sZo gsRUJF GmKi4F">
                                <img
                                  src={store?.image}
                                  alt={store?.name}
                                  className="N3bmGo _-rOGgo p0zJqo gsRUJF GmKi4F object-fit-cover"
                                  loading="lazy"
                                />
                              </picture>
                            </div>

                            <div
                              className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa"
                              style={{ minWidth: "0px" }}
                            >
                              <p className="_-wKhBx font-default-body-s-semibold">
                                {" "}
                                {store?.name}
                              </p>
                              <div
                                className="gQF-0-"
                                id="rating"
                                data-qa="rating"
                              >
                                <div className="aZptzi I-8Vzi">
                                  <p id="rating-aria" className="qTs2Ai">
                                    5 rating
                                  </p>
                                  <div className="ADCqki I-8Vzi">
                                    <span
                                      aria-hidden="true"
                                      className="_-wKhBx rfrUux _9CRQ7x font-default-body-s-semibold"
                                    >
                                      5.0
                                    </span>
                                    <div
                                      className="mKS7Nt I-8Lrt"
                                      aria-hidden="true"
                                    >
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="#ffff"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                    </div>
                                    <span
                                      aria-hidden="true"
                                      className="_-wKhBx rfrUux font-default-body-s-regular MRYHBB I-8UmB"
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <p className="_-wKhBx u4x-xx ZCvJqx KfKZXx font-default-body-s-regular">
                                {store?.location}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv ZRg0kv _8WTxMv zsU6-w">
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Overview
                          </p>
                          <ul className="_5zCVQX gap-default-100 direction-default-vertical display-default-flex">
                            {services?.length > 0 &&
                              services?.map((e, i) => (
                                <li className="p_e4TX" key={i}>
                                  <div
                                    data-qa="booking-info-service-colour-consultation"
                                    className="yhiMeE EuGs2E"
                                  >
                                    <div className="_6lhOdE">
                                      <div className="_002iGE">
                                        <div className="_nTKkE">
                                          <div className="_2rJeYE">
                                            <p
                                              data-qa="list-item-name"
                                              className="_-wKhBx font-default-body-s-medium"
                                            >
                                              {e?.name}
                                            </p>
                                            <p
                                              data-qa="list-item-description"
                                              className="_-wKhBx u4x-xx font-default-body-s-regular"
                                            >
                                              {e?.duration} min
                                            </p>
                                          </div>
                                          <div className="AzPtSE beZrJE">
                                            <div className="Yer71q">
                                              <p
                                                data-qa="list-item-retail-price"
                                                className="_-wKhBx rfrUux font-default-body-s-medium"
                                              >
                                                {e?.value} PKR
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}

                            <li className="p_e4TX">
                              <div
                                data-qa="booking-info-service-colour-consultation"
                                className="yhiMeE EuGs2E"
                              >
                                <div className="_6lhOdE">
                                  <div className="_002iGE">
                                    <div className="_nTKkE">
                                      <div className="_2rJeYE">
                                        <p
                                          data-qa="list-item-name"
                                          className="_-wKhBx font-default-body-s-medium"
                                        >
                                          Duration
                                        </p>
                                      </div>
                                      <div className="AzPtSE beZrJE">
                                        <div className="Yer71q">
                                          <p
                                            data-qa="list-item-retail-price"
                                            className="_-wKhBx rfrUux font-default-body-s-medium"
                                          >
                                            {" "}
                                            {totalDuration + " Minutes"}{" "}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="POY5Gv XXoKXv c0imxv _8WTxMv oEA38h">
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 pz1vF1 oM6NSq"
                          />
                          <div className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa">
                            <div className="yhiMeE EuGs2E">
                              <div className="_6lhOdE">
                                <div className="_002iGE">
                                  <div className="_nTKkE">
                                    <div className="_2rJeYE">
                                      <p className="_-wKhBx font-default-body-m-semibold">
                                        Total
                                      </p>
                                    </div>
                                    <div className="AzPtSE beZrJE">
                                      <p
                                        data-qa="total-price"
                                        className="_-wKhBx font-default-body-m-semibold"
                                      >
                                        {totalAmount} PKR
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv _5l5B-v _8WTxMv vM7X54">
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Appointment
                          </p>
                          {staff ? (
                            <div
                              data-qa="cart-employee-info"
                              className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex lx2Dn1 mt-2"
                            >
                              <div
                                className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="b6xJNc _SbjAc _1WMFBc _0alsKn">
                                  {staffImageError ? (
                                    <div className="noStaff">{staff?.name?.charAt(0).toUpperCase()}</div>
                                  ) : (
                                    <img
                                      src={staff?.image}
                                      className="img-fluid object-fit-cover "
                                      style={{ height: "100%" }}
                                      alt=""
                                      onError={() => {
                                        setStaffImageError(true);
                                      }}
                                    />
                                  )}
                                </div>
                                <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-first-name"
                                      className="_-wKhBx font-default-body-s-medium"
                                    >
                                      {staff?.name}
                                    </p>
                                  </li>
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-title"
                                      className="_-wKhBx u4x-xx font-default-body-s-regular"
                                    >
                                      {staff?.title}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <div
                              data-qa="cart-employee-info"
                              className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex lx2Dn1 mt-2"
                            >
                              <div
                                className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="b6xJNc _SbjAc _1WMFBc _0alsKn">
                                  <span
                                    className="_-6pwFd I-8RVd"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M12 3.75a5.25 5.25 0 0 0-.078 10.5h.156A5.25 5.25 0 0 0 12 3.75Zm3.463 11.045a6.75 6.75 0 1 0-6.925 0 11.25 11.25 0 0 0-6.281 5.08.75.75 0 1 0 1.299.75 9.75 9.75 0 0 1 16.888 0 .75.75 0 1 0 1.3-.75 11.25 11.25 0 0 0-6.281-5.08Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </div>
                                <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-first-name"
                                      className="_-wKhBx font-default-body-s-medium"
                                    >
                                      Any professional
                                    </p>
                                  </li>
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-title"
                                      className="_-wKhBx u4x-xx font-default-body-s-regular"
                                    >
                                      Assigned on the day
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="POY5Gv XXoKXv QpAXqv xv4qgv GNmo0c">
                          <Link to="/salon/select-time">
                            <button
                              id="button-continue-button"
                              data-qa="continue-button"
                              type="button"
                              className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU"
                              aria-pressed="false"
                              aria-labelledby="label-continue-button"
                              aria-disabled="false"
                              aria-live="polite"
                            >
                              <span className="G9I_8U">
                                <span
                                  id="label-continue-button"
                                  className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                                >
                                  Continue
                                </span>
                              </span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="MZ1PW7" />
              </div>
            </div>
            <div
              data-qa="bottom-bar"
              className="gMnlBP bjDb5P xa-C1P DETZNP zFOHKP display-default-block display-laptop-none"
            >
              <div className="K9p22P">
                <ul
                  className="_5zCVQX gap-default-0 direction-default-horizontal display-default-flex"
                  style={{ justifyContent: "space-between", flexGrow: 1 }}
                >
                  <li className="p_e4TX">
                    <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                      <li className="p_e4TX">
                        <p className="_-wKhBx font-default-body-s-semibold">
                          Total {totalAmount} PKR
                        </p>
                      </li>
                      <li className="p_e4TX">
                        <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                          {services?.length} services
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className="p_e4TX">
                    <button
                      onClick={() => {
                        navigate("/salon/select-time");
                      }}
                      id="button-react-aria-106"
                      data-qa="continue-button-mobile"
                      className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU JKAulU"
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="label-react-aria-106"
                      aria-live="polite"
                    >
                      <span className="G9I_8U">
                        <span
                          id="label-react-aria-106"
                          className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                        >
                          Continue
                        </span>
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentStepThree;
