import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";

import { BiArrowBack } from "react-icons/bi";

const ForgotPassword = () => {
  return (
    <div className="container my-5 zoom-up">
      <div className="col-lg-5 col-md-8 login_border m-auto p-3 p-lg-4 shadow">
      
        <h2 className="fw-bold text-animation-fadeOut text-center my-4">Forgot Password</h2>
        <p className="h5 text-center">No Worries, we'll send you reset instructions.</p>

        <div className="my-3 mt-4 position-relative text-animation-fadeOut">
          <input
            type="email"
            className="form-control login_input shadow-sm"
            id="email"
            name="email"
            placeholder="name@example.com"
          />
          <AiOutlineMail className="msg_icon" />
        </div>

        <button
          className="btn btn-primary w-100 pink_btn mt-1 text-animation-fadeOut shadow-sm "
          type="button"
        >
          Reset Password
        </button>
        <Link to="/login">
          <button className="btn btn-light w-100 mt-3 mb-4 shadow d-flex align-items-center justify-content-center">
           <BiArrowBack className="me-2"/> Back to Login
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
