import React from "react";
import { useMutation } from "@tanstack/react-query";
import apis from "../../services";
import toast from "react-hot-toast";
import { setEmail } from "../../redux/slices/userSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const ForgotPasswordForm = ({ setCurrent }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [email_input, setEmailInput] = useState();

  const { mutate, isLoading } = useMutation(apis.authForget, {
    onError: function ({ message }) {
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
        console.log(user,"user")
      if (status === 200) {
        dispatch(setEmail(email_input));
        setCurrent("rest-password");
        toast.success(user?.message, { id: 1 });
      }
    },
  });

  const sendOtp = (e) => {
    e.preventDefault();
    if (email_input) {
      mutate({ email: email_input, role: "user" });
    } else {
      setError("Email is Required");
    }
  };

  return (
    <form onSubmit={sendOtp}>
      <h1 className="text-center">Forgot Password</h1>
      <div className="mb-3">
        <label htmlFor="email" className="ms-2 mb-1">
          Email
        </label>
        <input
          type="email"
          required
          onChange={(e) => setEmailInput(e.target.value)}
          className="form-control form-control-login"
          placeholder="user@example.com"
        />
        {error && <p className="text-danger">{error}</p>}
      </div>
      <p className="text-center m-0 mb-2">We will send you OTP via Email.</p>
      <button className="loginButton border-0 w-100" type="submit">
        {isLoading ? 
        // <Spinner animation="border" />
        <BeatLoader color="#fff" />
         : "SEND OTP"}
      </button>
    </form>
  );
};

export default ForgotPasswordForm;
