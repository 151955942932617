import React, { useState } from "react";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import easypaisa from "./assests/easypaisa.png";
import visacard from "./assests/visacard.png";
import mastercards from "./assests/mastercard.jpg";
import SalonRecipt from "./SalonRecipt";

const PaymentCard = () => {
  const [state, setState] = useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
  });

  const handleInputChange = (evt) => {
    const { name, value } = evt.target;
    
    setState((prev) => ({ ...prev, [name]: value }));
  }

  const handleInputFocus = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  }
  return (
    <>
      <div className="container mt-5 pt-lg-5 mb-4">
        {/* <div className="row">
          <div className="col-lg-7 ">
            <div className="payment-card p-3 p-lg-4">
              <div className="d-flex">
                <img
                  src={mastercards}
                  className="payment-img"
                  alt="master card"
                />
                <img
                  src={visacard}
                  className="payment-img mx-5 "
                  alt="Visa Card"
                />
                <img src={easypaisa} className="payment-img" alt="EasyPaisa" />
              </div>
              <form className="paymentForm">
                <div className="row mt-4">
                  <div className="col-md-6 mb-2">
                    <div className="d-flex flex-column">
                      <label className="ms-3">Name</label>
                      <input type="text" placeholder="Name" required />
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="d-flex flex-column">
                      <label className="ms-3">Email</label>
                      <input type="text" placeholder="Email" required />
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="d-flex flex-column">
                      <label className="ms-3">Phone</label>
                      <input type="text" placeholder="Phone" required />
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="d-flex flex-column">
                      <label className="ms-3">Phone</label>
                      <input type="text" placeholder="phone" />
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    <div className="d-flex flex-column">
                      <label className="ms-3">Card Number:</label>
                      <input type="text" placeholder="Card Number" />
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2">
                    <div className="d-flex flex-column">
                      <label className="ms-3">Expiry Date</label>
                      <input type="text" placeholder="MM/YY" />
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2">
                    <div className="d-flex flex-column">
                      <label className="ms-3">CVV</label>
                      <input type="text" placeholder="CVV" />
                    </div>
                  </div>
                  <div className="col-sm-6 mt-2">
                    <button type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-5">
            <SalonRecipt />
          </div>
        </div> */}
        <Cards
        number={state.number}
        expiry={state.expiry}
        cvc={state.cvc}
        name={state.name}
        focused={state.focus}
      />
      <form>
        <input
          type="number"
          name="number"
          placeholder="Card Number"
          value={state.number}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        <input
          type="text"
          name="name"
          placeholder="Card Number"
          value={state.name}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
        />
        
      </form>
      </div>
    </>
  );
};

export default PaymentCard;
