import React, { useEffect, useRef } from "react";

const SearchLocationMobile = ({ setLocationMobile, data, setData }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const options = {
    componentRestrictions: { country: "pk" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef?.current?.addListener("place_changed", handlePlaceSelect);
  }, [options]);

  const handlePlaceSelect = () => {
    const selectedPlace = autoCompleteRef?.current?.getPlace();

    if (selectedPlace) {
      if (selectedPlace.geometry && selectedPlace.geometry.location) {
        const latitude = selectedPlace?.geometry?.location?.lat() || null;
        const longitude = selectedPlace?.geometry?.location?.lng() || null;
        setData((prev) => ({
          ...prev,
          longitude: longitude,
          latitude: latitude,
        }));
      } else {
        console.error(
          "Geometry information is not available for the selected place:",
          selectedPlace
        );
      }
    } else {
      console.error("Unable to retrieve information for the selected place");
    }
  };

  useEffect(() => {
    setData((pre) => ({ ...pre, location: inputRef?.current?.value }));
  }, [inputRef?.current?.value]);
  
  return (
    <div className="fresha-partner-react-portal-wrapper">
      <div>
        <div className="display-default-block display-laptop-none">
          <div className="efxtmb vQhmf5 rPV9JO">
            <div>
              <div className="_0UuadL u0oPO3 rPV9JO" data-qa="overlay">
                <div className="RBvj7V rPV9JO">
                  <div>
                    <div className="efxtmb _9dsAbT KiIPz9 rPV9JO" role="dialog">
                      <div className="DGeIbr" data-scroll="true">
                        <div className="wh2NbF">
                          <div className="BZrTWG">
                            <button
                              onClick={() => setLocationMobile(false)}
                              id="button-nom"
                              className="bXwNAZ _XdmmM Dd8ZaL"
                              type="button"
                              aria-pressed="false"
                              aria-label="label-back-button"
                              aria-labelledby="label-nom"
                              aria-live="polite"
                            >
                              <span
                                className="_-6pwFd _8oa-nd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  style={{ width: "24px" }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </button>
                            <p className="_-wKhBx font-default-header-l-semibold LiZoI4">
                              Location
                            </p>
                          </div>
                          <div
                            className="KRX-Tm UbcpRt _6j6RNv avz66h display-default-flex display-laptop-none gJ1v3P"
                            data-qa="location-search"
                          >
                            <p className="_-wKhBx font-default-body-xs-semibold -iA7Wy">
                              Location
                            </p>
                            <div className="tyeEeg">
                              <span
                                className="_-6pwFd I-8RVd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M11.543 22.345a.84.84 0 0 0 .983-.05 21.987 21.987 0 0 0 1.131-.9 25.156 25.156 0 0 0 2.659-2.622c1.918-2.205 3.934-5.378 3.934-9.023a8.25 8.25 0 1 0-16.5 0c0 3.645 2.016 6.818 3.934 9.023a25.146 25.146 0 0 0 3.538 3.334 14.102 14.102 0 0 0 .32.238ZM12 3a6.75 6.75 0 0 0-6.75 6.75c0 3.105 1.734 5.932 3.566 8.04A23.64 23.64 0 0 0 12 20.812a23.633 23.633 0 0 0 3.184-3.023c1.832-2.108 3.566-4.935 3.566-8.04A6.75 6.75 0 0 0 12 3Z"
                                    clipRule="evenodd"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M12 6a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 6Zm0 1.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              placeholder="Current location"
                              data-qa="location-search-input"
                              id="myInput"
                              defaultValue={data?.location}
                              ref={inputRef}
                            />
                            <button
                              aria-label="Clear"
                              className="tyeEeg pQGfZy"
                              type="button"
                              data-qa="location-search-clear"
                            >
                              <span
                                className="_-6pwFd I-8RVd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm6.22-3.53a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 0-1.06Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchLocationMobile;
