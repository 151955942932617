import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

//  StyleSheets
import './index.css';
import './styles/styles.css'
import './styles/responsive.css'
import  './styles/bootstrap.min.css'

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import {Provider} from 'react-redux';
import store from "./redux/Store"
import { BrowserRouter} from "react-router-dom";
import { Toaster } from 'react-hot-toast';

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  }
});

root.render(
  <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
            <App />
            <Toaster />
        </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
);