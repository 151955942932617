import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  staff: null,
  duration: 0,
  totalAmount: 0,
  date: null,
  time: null,
  step:1
};

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setService: (state, action) => {
      const newService = action.payload;
      const exists = state.services?.some(
        (service) => service._id === newService._id
      );

      if (!exists) {
        state.services = [...(state.services || []), newService];
      }
    },

    setStaff: (state, action) => {
      state.staff = action.payload;
    },
    set_all_initial: (state, action) => {
      state.services = [];
      state.staff = null;
      state.duration = 0;
      state.totalAmount = 0;
      state.date = null;
      state.time = null;
      state.step = 1;
    },

    setDate: (state, action) => {
      state.date = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    setAmount: (state, action) => {
      state.totalAmount = action.payload;
    },
    setTime: (state, action) => {
      state.time = action.payload;
    },

    removeService: (state, action) => {
      const serviceToRemoveId = action.payload._id;
      const indexToRemove = state.services?.findIndex(
        (service) => service._id === serviceToRemoveId
      );

      if (indexToRemove !== -1) {
        state.services?.splice(indexToRemove, 1);
      }
    },
  },
});

export const {
  setService,
  removeService,
  setDuration,
  setAmount,
  setStaff,
  setDate,
  setStep,
  set_all_initial,
  setTime,
} = serviceSlice.actions;
export default serviceSlice.reducer;
