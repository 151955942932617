import { useMutation } from "@tanstack/react-query";
import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setUser, setEmail } from "../../redux/slices/userSlice";
import apis from "../../services";
import toast from "react-hot-toast";
// import { Spinner } from "react-bootstrap";
import {  PulseLoader } from "react-spinners";

const OtpForm = ({ setCurrent, current, setShow }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { email_for_verification } = useSelector((e) => e?.user);
  const { mutate, isLoading } = useMutation(apis.authVerify, {
    onError: function ({ message }) {
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 200) {
        if (status === 200) {
          dispatch(setEmail(null));
          dispatch(setUser(user?.user));
          toast.success(user?.message, { id: 1 });
          if (current === "otp-rest") {
            setCurrent("rest-password");
          } else {
            setShow(false);
          }
        }
      }
    },
  });

  const { mutate: resend_OTP, isLoading: resend } = useMutation(
    apis.authForget,
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data: user, status }) => {
        if (status === 200) {
          toast.success(user?.message, { id: 1 });
        }
      },
    }
  );

  const sendAgain = () => {
    resend_OTP({ email: email_for_verification, role: "user" });
  };

  const [otp, setOtp] = useState();

  useEffect(() => {
    if (otp?.length === 4) {
      mutate({ email: email_for_verification, otp: otp });
    }
  }, [otp]);

  return (
    <div className="Login-card">
      <h1 className="text-center">Verify OTP</h1>
      <p className="text-center m-0 mb-2">
        We have send you the OTP via Email. <br />
        Check Your Inbox{" "}
      </p>
      <div className="w-100 d-flex justify-content-center my-4">
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputStyle={"OPT_INPUT"}
          inputType={"number"}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="w-100 d-flex justify-content-center mb-4">
        {(isLoading || resend) && 
        // <Spinner animation="border" />
        <PulseLoader  color="#ea4c89" />
        }
      </div>
      <p className="text-center m-0 my-2 h_u" onClick={sendAgain}>
        Send OTP Again.
      </p>
    </div>
  );
};

export default OtpForm;
