import React from 'react'

const DateMobile = ({setDateMobile}) => {
    return (

        <div className="fresha-partner-react-portal-wrapper"><div>
            <div className="display-default-block display-laptop-none">
                <div className="efxtmb vQhmf5 rPV9JO"><div>
                    <div className="_0UuadL u0oPO3 rPV9JO" data-qa="overlay">
                        <div className="RBvj7V rPV9JO"><div>
                            <div className="efxtmb _9dsAbT KiIPz9 rPV9JO" role="dialog">
                                <div className="DGeIbr" data-scroll="true">
                                    <div className="wh2NbF">
                                        <div className="BZrTWG">
                                            <button onClick={() => setDateMobile(false)} id="button-nom" className="bXwNAZ _XdmmM Dd8ZaL" type="button" aria-pressed="false" aria-label="label-back-button" aria-labelledby="label-nom" aria-live="polite">
                                                <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                                                    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{width : '24px'}}>
                                                        <path fillRule="evenodd" d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                                    </svg>
                                                </span>
                                            </button>
                                            <p className="_-wKhBx font-default-header-l-semibold LiZoI4 CDQvdt">Date</p>
                                        </div>
                                        <div className="kAQAWG ALEl-x"><div>
                                            <span tabIndex={0} style={{ height: '0px', opacity: 0, pointerEvents: 'none', position: 'absolute', width: '0px', zIndex: -1 }} />
                                            <div className="d6Dpu5">
                                                <ul className="_5zCVQX gap-default-150x direction-default-horizontal display-default-inline-flex">
                                                    <li className="p_e4TX">
                                                        <div data-qa="undefined-wrapper" className="QHBvhd">
                                                            <button id="button-any-date" className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l WxGKSl" type="button" aria-pressed="false" aria-labelledby="label-any-date" aria-live="polite">
                                                                <p id="label-any-date" className="_-wKhBx font-default-body-s-medium _2ptCkl">Any date</p>
                                                            </button>
                                                        </div>
                                                    </li>
                                                    <li className="p_e4TX">
                                                        <div data-qa="undefined-wrapper" className="QHBvhd">
                                                            <button id="button-today" className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l" type="button" aria-pressed="false" aria-labelledby="label-today" aria-live="polite">
                                                                <p id="label-today" className="_-wKhBx font-default-body-s-medium _2ptCkl">Today</p>
                                                            </button>
                                                        </div>
                                                    </li>
                                                    <li className="p_e4TX">
                                                        <div data-qa="undefined-wrapper" className="QHBvhd">
                                                            <button id="button-tomorrow" className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l" type="button" aria-pressed="false" aria-labelledby="label-tomorrow" aria-live="polite">
                                                                <p id="label-tomorrow" className="_-wKhBx font-default-body-s-medium _2ptCkl">Tomorrow</p>
                                                            </button>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <div className>
                                                    <div lang="en">
                                                        <div className="eUzqDX" tabIndex={0}>
                                                            <div className="WThT8X">
                                                                <button id="button-react-aria-297" data-qa="react-aria-297" tabIndex={-1} className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU GM_kfX" type="button" aria-pressed="false" aria-labelledby="label-react-aria-297" aria-live="polite">
                                                                    <span className="G9I_8U va37oU">
                                                                        <span id="label-react-aria-297" className="_-wKhBx ZCvJqx font-default-body-m-semibold">Previous month
                                                                        </span>
                                                                    </span>
                                                                    <span className="_0f0EDU">
                                                                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                <path fillRule="evenodd" d="M14.968 6.782a.75.75 0 0 1 0 1.06L10.81 12l4.157 4.157a.75.75 0 1 1-1.06 1.06L9.22 12.53a.75.75 0 0 1 0-1.06l4.687-4.688a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                                                            </svg>
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                                <button id="button-react-aria-298" data-qa="react-aria-298" tabIndex={-1} className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU Cuct_X" type="button" aria-pressed="false" aria-labelledby="label-react-aria-298" aria-live="polite">
                                                                    <span className="G9I_8U va37oU">
                                                                        <span id="label-react-aria-298" className="_-wKhBx ZCvJqx font-default-body-m-semibold">Next month
                                                                        </span>
                                                                    </span>
                                                                    <span className="_0f0EDU">
                                                                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                                                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                <path fillRule="evenodd" d="M9.22 6.782a.75.75 0 0 1 1.06 0l4.688 4.688a.75.75 0 0 1 0 1.06l-4.688 4.688a.75.75 0 1 1-1.06-1.06L13.377 12 9.22 7.843a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                                                            </svg>
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="VwadfX">
                                                                <div className="nGnG_X" role="grid" data-current-month="true">
                                                                    <div className="bx3qpX" role="heading" aria-level={3}>January 2024
                                                                    </div>
                                                                    <div className="XjsOkX" role="rowgroup">
                                                                        <div className="dxcLdX" role="row">
                                                                            <div className="_6RoZ4X" role="columnheader">
                                                                                <abbr title="Monday">Mon</abbr>
                                                                            </div>
                                                                            <div className="_6RoZ4X" role="columnheader">
                                                                                <abbr title="Tuesday">Tue</abbr>
                                                                            </div>
                                                                            <div className="_6RoZ4X" role="columnheader">
                                                                                <abbr title="Wednesday">Wed</abbr>
                                                                            </div>
                                                                            <div className="_6RoZ4X" role="columnheader">
                                                                                <abbr title="Thursday">Thu</abbr>
                                                                            </div>
                                                                            <div className="_6RoZ4X" role="columnheader">
                                                                                <abbr title="Friday">Fri</abbr>
                                                                            </div>
                                                                            <div className="_6RoZ4X" role="columnheader">
                                                                                <abbr title="Saturday">Sat</abbr>
                                                                            </div>
                                                                            <div className="_6RoZ4X" role="columnheader">
                                                                                <abbr title="Sunday">Sun</abbr>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="Ev1gXX" role="rowgroup">
                                                                        <div className="_8FYVIX" role="row">
                                                                            <div className="_8FZSQX W4ZmHX" role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">1
                                                                                </span>
                                                                                <span className="_5GDLQX" />
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">2
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">3
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">4
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">5
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">6
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">7
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="_8FYVIX" role="row">
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">8
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">9
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">10
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">11
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">12
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">13
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">14
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="_8FYVIX" role="row">
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">15
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">16
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">17
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">18
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">19
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">20
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">21
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="_8FYVIX" role="row">
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">22
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">23
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">24
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">25
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">26
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">27
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">28
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="_8FYVIX" role="row">
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">29
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">30
                                                                                </span>
                                                                            </div>
                                                                            <div className="_8FZSQX " role="gridcell">
                                                                                <span aria-hidden="true" className="TCjguX">
                                                                                    <span className="ij3SCX" />
                                                                                    <span className="x1wR_X" />
                                                                                </span>
                                                                                <span className="S3evEX">31
                                                                                </span>
                                                                            </div>
                                                                            <div aria-disabled="true" className="_8FZSQX MH1zXX" />
                                                                            <div aria-disabled="true" className="_8FZSQX MH1zXX" />
                                                                            <div aria-disabled="true" className="_8FZSQX MH1zXX" />
                                                                            <div aria-disabled="true" className="_8FZSQX MH1zXX" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <span tabIndex={0} style={{ height: '0px', opacity: 0, pointerEvents: 'none', position: 'absolute', width: '0px', zIndex: -1 }} />
                                        </div>
                                        </div>
                                        <div className="display-default-flex display-laptop-none FGimzb">
                                            <button id="button-react-aria-275" data-qa="react-aria-275" className="bXwNAZ _XdmmM tPDowU _i2EgU _8oamEU WnQevU GGLUcU" type="button" aria-pressed="false" aria-labelledby="label-react-aria-275" aria-live="polite">
                                                <span className="G9I_8U">
                                                    <span id="label-react-aria-275" className="_-wKhBx ZCvJqx font-default-body-m-semibold">Cancel
                                                    </span>
                                                </span>
                                            </button>
                                            <button id="button-react-aria-276" data-qa="react-aria-276" className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU" type="button" aria-pressed="false" aria-labelledby="label-react-aria-276" aria-live="polite">
                                                <span className="G9I_8U">
                                                    <span id="label-react-aria-276" className="_-wKhBx ZCvJqx font-default-body-m-semibold">Done
                                                    </span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default DateMobile