import React from "react";
// import Swiper core and required modules
import { Navigation, Pagination,A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Section5() {
  return (
    <div className="lJh45t">
      <div className="cpj9Q+ _5-TPbG">
        <div className="p0zn2V _5-TPbG">
          <div className="_5HSwpe">
            <div className="_9-NqFo">
              <h2 className="_-wKgxq font-default-header-l-semibold mb-default-200x mb-laptop-300x">
                Reviews
              </h2>
            </div>
          </div>
          <div
            id="carousel-reviews"
            className="ygl-Ox NUsFZk"
            style={{ itemWidthLarge: "calc((100% - (24px * 3)) / 4)" }}
          >
            <button
              id="button-carousel-spotlight-venues-new-left"
              data-qa="carousel-spotlight-venues-new-left"
              type="button"
              className="arrow-right bXwNAZ _XdmmM tPDowU _i2EgU p0rQDU _8oamEU _2qQHCU JKAulU MUDIZe RzW1Ue"
              tabIndex={-1}
              aria-pressed="false"
              aria-labelledby="label-carousel-spotlight-venues-new-left"
              aria-live="polite"
            >
              <span className="G9I_8U va37oU">
                <span
                  id="label-carousel-spotlight-venues-new-left"
                  className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                >
                  Previous
                </span>
              </span>
              <span className="_0f0EDU">
                <span className="_-6pBDG _8oamQG" aria-hidden="true">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </span>
            </button>
            <div className="DNTEIx wwAZIZ">
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                breakpoints={{
                  400: {
                    slidesPerView: 1.5,
                  },
                  600: {
                    slidesPerView: 2,
                  },
                  900: {
                    slidesPerView: 2.5,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                  1280: {
                    slidesPerView: 4,
                  },
                  1600: {
                    slidesPerView: 4,
                  },
                }}
                spaceBetween={20}
                navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
                scrollbar={{ draggable: false }}
                pagination={false}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                <SwiperSlide>
                  <div className="Z2xMoI">
                    <ul className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex JlmNrs">
                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>
                    </ul>
                    <h3 className="_-wKgxq font-default-header-m-semibold mb-default-150x">
                      The best booking system
                    </h3>
                    <p className="_-wKgxq font-default-body-s-regular mb-default-300x KZncth KZncth">
                      Great experience, easy to book. Paying for treatments is
                      so convenient — no cash or cards needed!
                    </p>
                    <div className="vwrSwV">
                      {/* https://termacha.com/wp-content/uploads/2022/04/Pakistani-Girls-WhatsApp-Numbers-for-Chatting-and-Friendship.jpg */}

                      <picture className="rC5TQo _-rOGgo Qs8P0o LxNbvs">
                        <source
                          srcSet="https://termacha.com/wp-content/uploads/2022/04/Pakistani-Girls-WhatsApp-Numbers-for-Chatting-and-Friendship.jpg 2x,"
                          media="(min-width: 0px)"
                          //   type="image/webp"
                        />
                        <source
                          srcSet="https://termacha.com/wp-content/uploads/2022/04/Pakistani-Girls-WhatsApp-Numbers-for-Chatting-and-Friendship.jpg"
                          media="(min-width: 0px)"
                          //   type="image/webp"
                        />
                        <source
                          srcSet="https://termacha.com/wp-content/uploads/2022/04/Pakistani-Girls-WhatsApp-Numbers-for-Chatting-and-Friendship.jpg 2x,"
                          media="(min-width: 0px)"
                          //   type
                        />
                        <source
                          srcSet="https://termacha.com/wp-content/uploads/2022/04/Pakistani-Girls-WhatsApp-Numbers-for-Chatting-and-Friendship.jpg"
                          media="(min-width: 0px)"
                          //   type
                        />
                        <img
                          src="https://termacha.com/wp-content/uploads/2022/04/Pakistani-Girls-WhatsApp-Numbers-for-Chatting-and-Friendship.jpg"
                          alt="Dan"
                          className="N3bmGo _-rOGgo p0zJqo LxNbvs object-fit-cover"
                          loading="lazy"
                        />
                      </picture>
                      <p className="_-wKgxq font-default-body-m-regular dcV-Yv dcV-Yv">
                        Kawal
                      </p>
                      <p className="_-wKgxq font-default-body-s-regular QbIs-T QbIs-T">
                        Karachi
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Z2xMoI">
                    <ul className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex JlmNrs">
                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="#fff"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#fff"
                              d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                            />
                          </svg>
                        </span>
                      </li>
                    </ul>
                    <h3 className="_-wKgxq font-default-header-m-semibold mb-default-150x">
                      Easy to use &amp; explore
                    </h3>
                    <p className="_-wKgxq font-default-body-s-regular mb-default-300x KZncth KZncth">
                      Beautowns's reminders make life so much easier. I also
                      found a few good barbershops that I didn’t know existed.
                    </p>
                    <div className="vwrSwV">
                      <picture className="rC5TQo _-rOGgo Qs8P0o LxNbvs">
                        <source
                          srcSet="https://images.unsplash.com/photo-1599475735868-a8924c458792?q=80&w=1852&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 2x,"
                          media="(min-width: 0px)"
                          //   type="image/webp"
                        />
                        <source
                          srcSet="https://images.unsplash.com/photo-1599475735868-a8924c458792?q=80&w=1852&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          media="(min-width: 0px)"
                          //   type="image/webp"
                        />
                        <source
                          srcSet="https://images.unsplash.com/photo-1599475735868-a8924c458792?q=80&w=1852&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 2x,"
                          media="(min-width: 0px)"
                          type
                        />
                        <source
                          srcSet="https://images.unsplash.com/photo-1599475735868-a8924c458792?q=80&w=1852&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          media="(min-width: 0px)"
                          type
                        />
                        <img
                          src="https://images.unsplash.com/photo-1599475735868-a8924c458792?q=80&w=1852&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          alt="Noman"
                          className="N3bmGo _-rOGgo p0zJqo LxNbvs object-fit-cover"
                          loading="lazy"
                        />
                      </picture>
                      <p className="_-wKgxq font-default-body-m-regular dcV-Yv dcV-Yv">
                        Noman
                      </p>
                      <p className="_-wKgxq font-default-body-s-regular QbIs-T QbIs-T">
                        Karachi
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Z2xMoI">
                    <ul className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex JlmNrs">
                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>
                    </ul>
                    <h3 className="_-wKgxq font-default-header-m-semibold mb-default-150x">
                      Great for finding barbers
                    </h3>
                    <p className="_-wKgxq font-default-body-s-regular mb-default-300x KZncth KZncth">
                      I've been using Beautowns for two years and it's by far
                      the best booking platform I've used. Highly recommend it!
                    </p>
                    <div className="vwrSwV">
                      <picture className="rC5TQo _-rOGgo Qs8P0o LxNbvs">
                        <source
                          srcSet=" //www.fresha.com/assets/static/media/dale@2x-d5a9f0f5c21818e519754ee336da226a.webp 2x,"
                          media="(min-width: 0px)"
                          type="image/webp"
                        />
                        <source
                          srcSet="//www.fresha.com/assets/static/media/dale-cf23f03671945ec9d79032a88111ab79.webp"
                          media="(min-width: 0px)"
                          type="image/webp"
                        />
                        <source
                          srcSet=" //www.fresha.com/assets/static/media/dale@2x-c6bef3c7c8c03c7f87ed2b699cbed6b5.jpg 2x,"
                          media="(min-width: 0px)"
                          type
                        />
                        <source
                          srcSet="//www.fresha.com/assets/static/media/dale-39112b76309cec1529846b25390948a2.jpg"
                          media="(min-width: 0px)"
                          type
                        />
                        <img
                          src="//www.fresha.com/assets/static/media/dale-39112b76309cec1529846b25390948a2.jpg"
                          alt="Dale"
                          className="N3bmGo _-rOGgo p0zJqo LxNbvs"
                          loading="lazy"
                        />
                      </picture>
                      <p className="_-wKgxq font-default-body-m-regular dcV-Yv dcV-Yv">
                        Danish
                      </p>
                      <p className="_-wKgxq font-default-body-s-regular QbIs-T QbIs-T">
                        Karachi
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Z2xMoI">
                    <ul className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex JlmNrs">
                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>
                    </ul>
                    <h3 className="_-wKgxq font-default-header-m-semibold mb-default-150x">
                      My go-to for self-care
                    </h3>
                    <p className="_-wKgxq font-default-body-s-regular mb-default-300x KZncth KZncth">
                      Beautowns is my go-to app for massages and facials. I can
                      easily find and book places near me — I love it!
                    </p>
                    <div className="vwrSwV">
                      <picture className="rC5TQo _-rOGgo Qs8P0o LxNbvs">
                        <source
                          srcSet="https://3.bp.blogspot.com/-tHbucxWQOv4/VOoOFzQrndI/AAAAAAAAAtU/0sxIJpwBUWE/s1600/Arabic-%26-Pakistani-Hijab-Styles-2015%2Bclothingpk%2B1.jpg 2x,"
                          media="(min-width: 0px)"
                          //   type="image/webp"
                        />
                        <source
                          srcSet="https://3.bp.blogspot.com/-tHbucxWQOv4/VOoOFzQrndI/AAAAAAAAAtU/0sxIJpwBUWE/s1600/Arabic-%26-Pakistani-Hijab-Styles-2015%2Bclothingpk%2B1.jpg"
                          media="(min-width: 0px)"
                          //   type="image/webp"
                        />
                        <source
                          srcSet="https://3.bp.blogspot.com/-tHbucxWQOv4/VOoOFzQrndI/AAAAAAAAAtU/0sxIJpwBUWE/s1600/Arabic-%26-Pakistani-Hijab-Styles-2015%2Bclothingpk%2B1.jpg 2x,"
                          media="(min-width: 0px)"
                          //   type
                        />
                        <source
                          srcSet="https://3.bp.blogspot.com/-tHbucxWQOv4/VOoOFzQrndI/AAAAAAAAAtU/0sxIJpwBUWE/s1600/Arabic-%26-Pakistani-Hijab-Styles-2015%2Bclothingpk%2B1.jpg"
                          media="(min-width: 0px)"
                          //   type
                        />
                        <img
                          src=""
                          alt="Dale"
                          className="N3bmGo _-rOGgo p0zJqo LxNbvs"
                          loading="lazy"
                        />
                      </picture>
                      <p className="_-wKgxq font-default-body-m-regular dcV-Yv dcV-Yv">
                        Nayab
                      </p>
                      <p className="_-wKgxq font-default-body-s-regular QbIs-T QbIs-T">
                        Karachi
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Z2xMoI">
                    <ul className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex JlmNrs">
                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>
                    </ul>
                    <h3 className="_-wKgxq font-default-header-m-semibold mb-default-150x">
                      Great way to discover new salons
                    </h3>
                    <p className="_-wKgxq font-default-body-s-regular mb-default-300x KZncth KZncth">
                      Recently moved to a new city and didn't know any salons.
                      Beautowns gave me a whole new list to choose from!
                    </p>
                    <div className="vwrSwV">
                      <picture className="rC5TQo _-rOGgo Qs8P0o LxNbvs">
                        <source
                          srcSet="https://www.desicomments.com/dc3/01/205065/2050651.jpg 2x,"
                          media="(min-width: 0px)"
                          //   type="image/webp"
                        />
                        <source
                          srcSet="https://www.desicomments.com/dc3/01/205065/2050651.jpg"
                          media="(min-width: 0px)"
                          //   type="image/webp"
                        />
                        <source
                          srcSet="https://www.desicomments.com/dc3/01/205065/2050651.jpg 2x,"
                          media="(min-width: 0px)"
                          //   type
                        />
                        <source
                          srcSet="https://www.desicomments.com/dc3/01/205065/2050651.jpg"
                          media="(min-width: 0px)"
                          //   type
                        />
                        <img
                          src="https://www.desicomments.com/dc3/01/205065/2050651.jpg"
                          alt="Suleman"
                          className="N3bmGo _-rOGgo p0zJqo LxNbvs"
                          loading="lazy"
                        />
                      </picture>
                      <p className="_-wKgxq font-default-body-m-regular dcV-Yv dcV-Yv">
                        Suleman
                      </p>
                      <p className="_-wKgxq font-default-body-s-regular QbIs-T QbIs-T">
                        karachi
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="Z2xMoI">
                    <ul className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex JlmNrs">
                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>

                      <li className="p_e4TX">
                        <span className="_-6pBDG I-8MNG" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                          </svg>
                        </span>
                      </li>
                    </ul>
                    <h3 className="_-wKgxq font-default-header-m-semibold mb-default-150x">
                      Sleek app
                    </h3>
                    <p className="_-wKgxq font-default-body-s-regular mb-default-300x KZncth KZncth">
                      Such a sleek and powerful app. I highly recommend booking
                      your appointments on Beautowns.
                    </p>
                    <div className="vwrSwV">
                      <picture className="rC5TQo _-rOGgo Qs8P0o LxNbvs">
                        <source
                          srcSet="https://www.desicomments.com/dc3/16/415338/415338.jpg 2x,"
                          media="(min-width: 0px)"
                        />
                        <source srcSet="p" media="(min-width: 0px)" />
                        <source
                          srcSet="https://www.desicomments.com/dc3/16/415338/415338.jpg 2x,"
                          media="(min-width: 0px)"
                          type
                        />
                        <source
                          srcSet="https://www.desicomments.com/dc3/16/415338/415338.jpg"
                          media="(min-width: 0px)"
                          type
                        />
                        <img
                          src="https://www.desicomments.com/dc3/16/415338/415338.jpg"
                          alt="Umair"
                          className="N3bmGo _-rOGgo p0zJqo LxNbvs object-fit-cover "
                          loading="lazy"
                        />
                      </picture>
                      <p className="_-wKgxq font-default-body-m-regular dcV-Yv dcV-Yv">
                        Umair
                      </p>
                      <p className="_-wKgxq font-default-body-s-regular QbIs-T QbIs-T">
                        Karachi
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <button
              id="button-carousel-spotlight-venues-new-right"
              data-qa="carousel-spotlight-venues-new-right"
              type="button"
              className="arrow-left bXwNAZ _XdmmM tPDowU _i2EgU p0rQDU _8oamEU _2qQHCU JKAulU MUDIZe HLLgKe"
              tabIndex={-1}
              aria-pressed="false"
              aria-labelledby="label-carousel-spotlight-venues-new-right"
              aria-live="polite"
            >
              <span className="G9I_8U va37oU">
                <span
                  id="label-carousel-spotlight-venues-new-right"
                  className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                >
                  Next
                </span>
              </span>
              <span className="_0f0EDU">
                <span className="_-6pBDG _8oamQG" aria-hidden="true">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.97 4.72a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 0 1 0 1.06l-6.75 6.75a.75.75 0 1 1-1.06-1.06l5.47-5.47H3.75a.75.75 0 0 1 0-1.5h14.69l-5.47-5.47a.75.75 0 0 1 0-1.06Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section5;
