import React, { useEffect, useState } from "react";
import "./home.css";
import "./client.c7918688.css";
import "./src_widgets_StickyBar_index_ts.d212fab5.chunk.css";
import "./design-system.dd5c5e65.css";
import "./static-pages-chnk.5b17223c.chunk.css";
import "./vendor.bbe55722.css";
import logo from "../../src/assets/images/logo/new-logo.png";
import { IoCloseSharp } from "react-icons/io5";
// import { MdArrowForwardIos } from "react-icons/md";
// import { MdKeyboardArrowDown } from "react-icons/md";
import AuthModal from "../components/AuthModal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/slices/userSlice";

function Header({ showMenu, setShowMenu, menuRef }) {
  const [fixedHeader, setFixedHeader] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((e) => e?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      const triggerPosition = 10;
      const scrollPosition = window.scrollY;
      setFixedHeader(scrollPosition > triggerPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [show, setShow] = useState(false);
  return (
    <>
      <AuthModal show={show} setShow={setShow} />
      <div class={`yZk-Qk ZXUUjX   ${fixedHeader ? "mobqJG" : ""}`}>
        <nav className="EXyJyF position-relative">
          <Link className="CLnQGg _XdmmM" to="/">
            <img src={logo} alt="" width={"180px"} />
          </Link>
          <div className="display-default-flex display-tablet-none C5iPaz">
            <button
              onClick={() => {
                setShowMobileNav(true);
              }}
              data-qa="navigation-hamburger-mobile"
              className="bXwNAZ _XdmmM _9fRKN7"
              id="button-react-aria-5"
              type="button"
              aria-pressed="false"
              aria-label="Menu"
              aria-labelledby="label-react-aria-5"
              aria-live="polite"
            >
              <span className="_-6pBDG gQFV2G" aria-hidden="true">
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6Zm0 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 18Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div className="display-default-none display-tablet-flex C5iPaz">
            <a
              className="CLnQGg _XdmmM tPDowU _i2EgU _8oamEU _2qQHCU JKAulU"
              loader="[object Object]"
              id="for-business-btn"
              href="https://dashboard.beautowns.com/"
              target="_blank"
              data-qa="link-for-business"
              rel="noreferrer"
            >
              <span className="G9I_8U">
                <span
                  id="label-for-business-btn"
                  className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                >
                  For business
                </span>
              </span>
            </a>
            {user?.isVerified ? (
              <div className="" onClick={() => setShowMenu(!showMenu)}>
                <button
                  id="button-menu-button"
                  data-qa="avatar-button-desktop"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="bXwNAZ _XdmmM TnoHwV"
                  type="button"
                  aria-pressed="false"
                  aria-labelledby="label-menu-button"
                  aria-live="polite"
                >
                  <div id="avatar-button-desktop" className="WiWLh_ _-Yxlt_">
                    {user?.image ? (
                      <img src={user?.image} className="userNameImage" alt="" />
                    ) : (
                      <div className="userNameImage text-uppercase ">
                        {user?.name?.substring(0, 1)}
                      </div>
                    )}
                  </div>

                  <span className="_0f0EDU">
                    <span className="_-6pBDG _8oamQG" aria-hidden="true">
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6.782 9.22a.75.75 0 0 1 1.06 0L12 13.377l4.157-4.157a.75.75 0 1 1 1.06 1.06l-4.687 4.688a.75.75 0 0 1-1.06 0L6.782 10.28a.75.75 0 0 1 0-1.06Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </span>
                </button>
              </div>
            ) : (
              <div
                // onClick={() => setShowDropdown(!showDropdown)}
                onClick={() => setShow(true)}
                className="display-default-none display-laptop-flex"
              >
                <button
                  id="button-menu-button"
                  data-qa="navigation-hamburger-desktop"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="bXwNAZ _XdmmM tPDowU _i2EgU _8oamEU _2qQHCU JKAulU"
                  type="button"
                  aria-pressed="false"
                  aria-labelledby="label-menu-button"
                  aria-live="polite"
                >
                  <span className="G9I_8U">
                    <span
                      id="label-menu-button"
                      className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                    >
                      Login
                    </span>
                  </span>
                  <span className="_0f0EDU">
                    <span className="_-6pBDG _8oamQG" aria-hidden="true">
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6.782 9.22a.75.75 0 0 1 1.06 0L12 13.377l4.157-4.157a.75.75 0 1 1 1.06 1.06l-4.687 4.688a.75.75 0 0 1-1.06 0L6.782 10.28a.75.75 0 0 1 0-1.06Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </span>
                </button>
              </div>
            )}
            <div className="display-default-none display-tablet-flex display-laptop-none">
              <button
                data-qa="navigation-hamburger-mobile"
                className="bXwNAZ _XdmmM _9fRKN7"
                id="button-react-aria-8"
                type="button"
                aria-pressed="false"
                aria-label="Menu"
                aria-labelledby="label-react-aria-8"
                aria-live="polite"
              >
                <span className="_-6pBDG gQFV2G" aria-hidden="true">
                  <svg
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6Zm0 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 6a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 18Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>

          {showMenu && (
            <>
              {user?.isVerified ? (
                <div
                  className="XXCymX sXnfiX xa-QVX JjHJ9X"
                  style={{
                    minWidth: "200px",
                    position: "absolute",
                    zIndex: 100000,
                    right: "0",
                    top: "77px",
                    maxHeight: "400px",
                  }}
                >
                  <div
                    className="EYPJ2X"
                    style={{ right: "12px", top: "77px", maxHeight: "240px" }}
                  >
                    <ul
                      id="menu-parent"
                      // id="react-aria-1178"
                      aria-labelledby="react-aria-1177"
                      role="menu"
                      tabIndex={-1}
                      className="_8TerEn"
                    >
                      <li
                        data-qa="menu-items"
                        role="presentation"
                        className="SvLLjn"
                      >
                        <p
                          role="presentation"
                          className="_-wKhBx rfrUux font-default-body-m-semibold font-laptop-body-s-semibold DrmJ1n"
                        >
                          <span className="Xt5KTu text-capitalize ">
                            Hi, {user?.name}
                          </span>
                        </p>
                        <ul
                          aria-labelledby="react-aria-1495"
                          role="group"
                          className="rmG1Kn"
                        >
                          <li
                            onClick={() => {
                              setShowMenu(false);
                              navigate("/user/profile");
                            }}
                            data-qa="dropdown-btn-info-profile"
                            className="fK-93n OGO9an wtRMzb"
                          >
                            <div className="aHMJCn">
                              <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                Profile
                              </p>
                            </div>
                          </li>

                          <li
                            onClick={() => {
                              setShowMenu(false);
                              navigate("/salon/favorite");
                            }}
                            data-qa="menu-item-my-appointments"
                            className="fK-93n OGO9an wtRMzb"
                          >
                            <div className="aHMJCn">
                              <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                Favorite salons
                              </p>
                            </div>
                          </li>
                          <li
                            onClick={() => {
                              setShowMenu(false);
                              navigate("/salon/chat");
                            }}
                            data-qa="menu-item-my-appointments"
                            className="fK-93n OGO9an wtRMzb"
                          >
                            <div className="aHMJCn">
                              <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                Chat
                              </p>
                            </div>
                          </li>

                          <li
                            onClick={() => {
                              setShowMenu(false);
                              navigate("/salon/bookings");
                            }}
                            className="fK-93n OGO9an wtRMzb"
                          >
                            <div className="aHMJCn">
                              <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                Appointments
                              </p>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li role="presentation" className="SvLLjn">
                        <ul role="group" className="rmG1Kn">
                          <a
                            href="https://play.google.com/store/apps/details?id=com.beautowns"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <li className="fK-93n OGO9an wtRMzb">
                              <div className="aHMJCn">
                                <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                  Download the app
                                </p>
                              </div>
                            </li>
                          </a>

                          <li
                            onClick={() => {
                              setShowMenu(!showMenu);
                              navigate("/");
                              dispatch(logout());
                            }}
                            data-qa="menu-item-logout"
                            role="menuitem"
                            tabIndex={-1}
                            data-key="logout"
                            className="fK-93n OGO9an wtRMzb"
                          >
                            <div className="aHMJCn">
                              <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                Logout
                              </p>
                            </div>
                          </li>
                          <li className="mC_J8n" aria-hidden="true">
                            <hr
                              aria-orientation="horizontal"
                              className="KT-if1 CChMQ1 Sm3CD1 PiTGa1"
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div
                  className="XXCymX sXnfiX xa-QVX JjHJ9X"
                  style={{
                    minWidth: "200px",
                    position: "absolute",
                    zIndex: 100000,
                    right: "0px",
                    top: "77px",
                    maxHeight: "400px",
                  }}
                >
                  <div className="_7ixdAX" />
                  <div
                    className="EYPJ2X"
                    style={{ right: "12px", top: "77px", maxHeight: "240px" }}
                  >
                    <div className="w997NX" aria-hidden="true">
                      <span className="ToEGtX" />
                    </div>
                    <span data-focus-scope-start="true" hidden />
                    <ul
                      id="react-aria-3"
                      aria-labelledby="react-aria-2"
                      role="menu"
                      tabIndex={-1}
                      className="_8TerEn"
                    >
                      <li role="presentation" className="SvLLjn">
                        <ul role="group" className="rmG1Kn">
                          <li
                            data-qa="menu-item-log-in"
                            className="fK-93n OGO9an wtRMzb _XdmmM"
                          >
                            <div
                              className="CLnQGg eQ2tJn"
                              data-key="login"
                              // onClick={() => setLogin(true)}
                              role="menuitem"
                              data-qa="action-menu-item-log-in"
                              tabIndex={0}
                            />
                            <div className="aHMJCn">
                              <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                Log in
                              </p>
                            </div>
                          </li>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.beautowns"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <li className="fK-93n OGO9an wtRMzb">
                              <div
                                className="CLnQGg eQ2tJn"
                                data-key="download-the-app"
                                role="menuitem"
                                tabIndex={-1}
                              />
                              <div className="aHMJCn">
                                <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                  Download the app
                                </p>
                              </div>
                            </li>
                          </a>

                          <li className="mC_J8n" aria-hidden="true">
                            <hr
                              aria-orientation="horizontal"
                              className="KT-if1 CChMQ1 Sm3CD1 PiTGa1"
                            />
                          </li>
                        </ul>
                      </li>
                      <li role="presentation" className="SvLLjn">
                        <ul role="group" className="rmG1Kn">
                          <li className="fK-93n OGO9an wtRMzb">
                            <div
                              className="CLnQGg eQ2tJn"
                              data-key="$.1.0"
                              target="_self"
                              role="menuitem"
                              tabIndex={-1}
                            />
                            <div className="aHMJCn">
                              <p className="_-wKhBx rfrUux font-default-body-m-semibold font-laptop-body-s-semibold">
                                Beautowns for business
                              </p>
                            </div>
                          </li>
                          <li className="mC_J8n" aria-hidden="true">
                            <hr
                              aria-orientation="horizontal"
                              className="KT-if1 CChMQ1 Sm3CD1 PiTGa1"
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div
                      style={{
                        border: "0px",
                        clip: "rect(0px, 0px, 0px, 0px)",
                        clipPath: "inset(50%)",
                        height: "1px",
                        margin: "-1px",
                        overflow: "hidden",
                        padding: "0px",
                        position: "absolute",
                        width: "1px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <button
                        id="react-aria-264"
                        aria-label="Dismiss"
                        tabIndex={-1}
                      />
                    </div>
                    <span data-focus-scope-end="true" hidden />
                  </div>
                </div>
              )}
            </>
          )}
        </nav>
        <div className="xqEsXf" />
      </div>

      {showMobileNav && (
        <div className="Ys_t_C JjH87C">
          <div className="qiZWCe">
            <div
              role="dialog"
              aria-labelledby="react-aria-301"
              tabIndex={-1}
              className="Df5Lk6 LyHcIe JjHqYe"
            >
              <div className="E4hVuT JdS1yT xa-rvT TMboEG TuPVOG">
                <div className="_7nJ-pT JdS1yT">
                  <div className="wwqFAT JdS1yT">
                    <img src={logo} alt="" width={"180px"} />
                  </div>
                  <div className="p1pdhT JdS1yT">
                    <span
                      id="react-aria-301"
                      className="_-wKhBx rfrUux Bnx6rx dUG8gx ZCvJqx KfKZXx font-default-header-s-semibold font-tablet-header-s-semibold font-laptop-header-s-semibold font-desktop-header-s-semibold _zngzT QcpU5T"
                    >
                      Menu
                    </span>
                  </div>
                  <div className="wwqFAT ibymkT">
                    <button
                      id="button-close-modal-undefined"
                      data-qa="close-modal-undefined"
                      className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU"
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="label-close-modal-undefined"
                      aria-live="polite"
                      onClick={() => {
                        setShowMobileNav(false);
                      }}
                    >
                      <span className="G9I_8U va37oU">
                        <span
                          id="label-close-modal-undefined"
                          className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                        >
                          Close modal
                        </span>
                      </span>
                      <span className="_0f0EDU">
                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                          <IoCloseSharp style={{ fontSize: "30px" }} />
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <span data-focus-scope-start="true" hidden="" />
              <div className="YAjmY7 JdS4i7 edrVsC">
                <div className="MZ1PW7" />
                <div className="otg7W7 JdS4i7">
                  <div className="gbuHog JdSsAg">
                    <div className="Lbd03-">
                      {user?.isVerified ? (
                        <>
                          <div
                            data-qa="menu-item-download-app"
                            className="yhiMeE EuGs2E SQVduE _3HCC2B"
                          >
                            <div className="CLnQGg _XdmmM mhNisE" />
                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <p className="_-wKhBx font-default-body-m-semibold">
                                  Hi, {user?.name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setShowMobileNav(false);
                              navigate("/salon/chat");
                            }}
                            className="yhiMeE EuGs2E SQVduE _3HCC2B"
                          >
                            <div className="CLnQGg _XdmmM mhNisE" />

                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <p className="_-wKhBx font-default-body-m-semibold">
                                   Chat
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setShowMobileNav(false);
                              navigate("/salon/favorite");
                            }}
                            className="yhiMeE EuGs2E SQVduE _3HCC2B"
                          >
                            <div className="CLnQGg _XdmmM mhNisE" />

                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <p className="_-wKhBx font-default-body-m-semibold">
                                  Favorite salons
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setShowMobileNav(false);
                              navigate("/user/profile");
                            }}
                            className="yhiMeE EuGs2E SQVduE _3HCC2B"
                          >
                            <div className="CLnQGg _XdmmM mhNisE" />

                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <p className="_-wKhBx font-default-body-m-semibold">
                                  Profile
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setShowMobileNav(false);
                              navigate("/salon/bookings");
                            }}
                            className="yhiMeE EuGs2E SQVduE _3HCC2B"
                          >
                            <div className="CLnQGg _XdmmM mhNisE" />

                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <p className="_-wKhBx font-default-body-m-semibold">
                                  Appointments
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          data-qa="menu-item-log-in"
                          className="yhiMeE EuGs2E SQVduE _3HCC2B"
                        >
                          <div
                            className="_6lhOdE"
                            onClick={() => {
                              setShowMobileNav(false);
                              setShow(true);
                            }}
                          >
                            <div className="_002iGE">
                              <p className="_-wKhBx font-default-body-m-semibold">
                                Log in
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        data-qa="menu-item-download-app"
                        className="yhiMeE EuGs2E SQVduE _3HCC2B "
                      >
                        <div className="CLnQGg _XdmmM mhNisE" />
                        <a
                          className="CLnQGg _XdmmM mhNisE ps-4 mt-4"
                          href="https://play.google.com/store/apps/details?id=com.beautowns"
                          target="_black"
                        >
                          <div className="_6lhOdE">
                            <div className="_002iGE">
                              <p className="_-wKhBx font-default-body-m-semibold">
                                Download the app
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>

                      <div
                        data-qa="menu-item-list-your-salon"
                        className="yhiMeE EuGs2E SQVduE _3HCC2B mt-4"
                      >
                        <a
                          className="CLnQGg _XdmmM mhNisE ps-4 mt-4"
                          href="https://dashboard.beautowns.com/"
                          target="_black"
                        >
                          <div className="_6lhOdE">
                            <div className="_002iGE">
                              <p className="_-wKhBx font-default-body-m-semibold">
                                Beautowns for business
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>

                      {user?.isVerified && (
                        <div
                          data-qa="menu-item-download-app"
                          className="yhiMeE EuGs2E SQVduE _3HCC2B mt-4"
                          onClick={() => {
                            setShowMobileNav(false);
                            navigate("/");
                            dispatch(logout());
                          }}
                        >
                          <div className="CLnQGg _XdmmM mhNisE" />
                          <div className="_6lhOdE">
                            <div className="_002iGE">
                              <p className="_-wKhBx font-default-body-m-semibold">
                                Logout
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="MZ1PW7" />
              </div>
              <span data-focus-scope-end="true" hidden="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
