import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";

const BookingDetailModal = ({ show, setShow, data }) => {
  return (
    <Modal centered show={show} onHide={setShow}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Booking Details</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div class="receipt-container">
          <div class="text-center">
            <h2>{data?.store_Id?.name}</h2>
            <p>Date : {moment(data?.createdAt).format("DD MMMM, YYYY")}</p>
          </div>
          <div class="payroll-details">
            
            {data?.user_Id?.phone && (
              <p className="m-0 mt-1 d-flex justify-content-between ">
                <strong>Phone</strong> +{data?.user_Id?.phone}
              </p>
            )}
            <hr className="m-0 my-2" />
            {data?.salon_staff_Id &&
            <>
            <p className="m-0 d-flex justify-content-between ">
              <strong>Staff Name:</strong> {data?.salon_staff_Id?.name}
            </p>
            <p className="m-0 mt-1 d-flex justify-content-between ">
              <strong>Designation:</strong> {data?.salon_staff_Id?.title}
            </p>
            </>}
          </div>
          <div class="payroll-details">
            <h4 className="text-center m-0 ">Details</h4>
            <div class="salary-component">
              <span>Start Time</span>
              <span>{data?.time}</span>
            </div>
            <div class="salary-component">
              <span>End Time</span>
              <span>{data?.end}</span>
            </div>
            <div class="salary-component">
              <span>Duration</span>
              <span>{data?.duration} min</span>
            </div>
            {data?.service_Ids?.length > 0 &&
              data?.service_Ids?.map((service, i) => (
                <div class="salary-component">
                  <span>{service?.name} : </span>
                  <span>{service?.value} PKR</span>
                </div>
              ))}
            {data?.advance_Amount > 0 ? (
              <div class="salary-component">
                <span>Advance Amount:</span>
                <span>{data?.advance_Amount} PKR</span>
              </div>
            ) : (
              ""
            )}
            {data?.collected_Amount > 0 ? (
              <div class="salary-component">
                <span>Collected Amount:</span>
                <span>{data?.collected_Amount} PKR</span>
              </div>
            ) : (
              ""
            )}
            {data?.isCancel ? (
              <div class="salary-component text-danger ">
                <span>Cancelled by</span>
                <span className="text-capitalize">
                  {data?.cancelledBy?.role} User
                </span>
              </div>
            ) : (
              ""
            )}
          </div>

          <div class="total-amount">
            <p className="d-flex justify-content-between ">
              <strong>Total Amount:</strong> {data?.amount} PKR
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default BookingDetailModal;
