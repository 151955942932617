import moment from "moment";
import React, { useRef } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

const DateScroll = ({
  selectdSlots,
  setSelectedSlots,
  dispatch,
  setDate,
  date,
  setTime,
  time,
  // get_slot,
  storeSlots,
}) => {
  const dateListRef = useRef(null);
  const handleNext = () => {
    const scrollWidth = dateListRef.current.scrollWidth;
    const scrollLeft = dateListRef.current.scrollLeft;
    const clientWidth = dateListRef.current.clientWidth;

    if (scrollWidth - scrollLeft !== clientWidth) {
      dateListRef.current.scrollTo({
        left: scrollLeft + clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    const scrollLeft = dateListRef.current.scrollLeft;
    const clientWidth = dateListRef.current.clientWidth;

    if (scrollLeft !== 0) {
      dateListRef.current.scrollTo({
        left: scrollLeft - clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleDate = (slot) => {
    console.log(slot, "slot");
    setSelectedSlots(slot);
    dispatch(setDate(moment(slot?.date?.completeDate).format("YYYY-MM-DD")));
  };

  //   useEffect(() => {
  //     get_slot();
  //     // eslint-disable-next-line
  //   }, []);

  return (
    <>
      {storeSlots?.length > 0 && (
        <>
          <div className="date-time-wrapper w-100">
            <div className="px-2 pb-4">
              <div className="d-flex justify-content-end mb-3">
                <button
                  onClick={handlePrev}
                  className="dateSliderBtn me-3"
                  type="button"
                >
                  <FaAngleLeft className="h5 m-0" />
                </button>
                <button
                  onClick={handleNext}
                  className="dateSliderBtn"
                  type="button"
                >
                  <FaAngleRight className="h5 m-0" />
                </button>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <div className="date-list pb-2  px-2" ref={dateListRef}>
                  {storeSlots?.map((slot, index) => (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <button
                        key={index}
                        type="button"
                        // disabled={!slot?.isAvailable}
                        onClick={() => {
                          // if (slot?.isAvailable) {
                          handleDate(slot);
                          // }
                        }}
                        className={`available-date ${
                          selectdSlots?.date?.completeDate ===
                          slot?.date?.completeDate
                            ? "selected-date"
                            : ""
                        } mx-1 d-flex flex-column align-items-center postion-relative`}
                      >
                        {/* <div className="d-flex flex-column align-items-center"> */}
                        <span className={`fw-bold`}>{slot?.date?.date}</span>
                        {!slot?.isAvailable && (
                          <div
                            className={`crossLine ${
                              selectdSlots?.date?.completeDate ===
                              slot?.date?.completeDate
                                ? "bg-white"
                                : ""
                            }`}
                          ></div>
                        )}
                        {/* <span className="fw-bold">
                          {slot?.isAvailable ? "T" : "F"}
                        </span> */}
                        {/* <span className="text-uppercase"> */}
                        {/* {slot?.date?.month?.substring(0, 3)}
                      </span>
                    </div> */}
                        {/* <h5 className="m-0 mt-1">
                  </h5> */}
                      </button>
                      <h6 className="m-0 mt-1 ">
                        {slot?.date?.day?.substring(0, 3)}
                      </h6>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`d-flex flex-column ${
              !selectdSlots?.slots?.length > 0 && "justify-content-center"
            }`}
          >
            {selectdSlots?.slots.length === 1 ? (
              <div
                className={`${
                  time === selectdSlots?.slots?.[0]
                    ? "timeSlot-active w-auto my-3 mx-auto"
                    : "timeSlot-nonactive w-auto my-3 mx-auto"
                }`}
              >
                {selectdSlots?.slots?.[0]}
              </div>
            ) : selectdSlots?.slots?.length > 0 ? (
              selectdSlots?.slots?.map((slot, i) => {
                return (
                  <div
                    key={i}
                    className={` timeSlot m-2 text-center ${
                      time === slot ? "timeSlot-active" : "timeSlot-nonactive"
                    }`}
                    onClick={() => dispatch(setTime(slot))}
                  >
                    <div>{slot}</div>
                  </div>
                );
              })
            ) : (
              <h5 className="text-center my-5">
                No Slots Found against selected Date
              </h5>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DateScroll;
