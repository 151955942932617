import React from "react";
import { IoClose, IoArrowBackOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProcessHeader = ({ title }) => {
  const { store } = useSelector((e) => e?.store?.storeDetails);
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid p-0">
        <div className="process-header d-flex align-items-center">
          <div className="container d-flex justify-content-between align-items-center">
            <IoArrowBackOutline
              onClick={() => navigate(-1)}
              className="h4 m-0"
            />
            <h1>{title}</h1>
            <Link to={`/salon-details/${store?._id}`}>
              <IoClose className="h4 m-0" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessHeader;
