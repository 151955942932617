import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import defaultSalon1 from "../../assets/images/images/defaultSalon.avif";
import SocialMediaModal from "./SocialMediaModal";
import Section2 from "../home/Section2";
import Footer from "../Footer";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  removeService,
  setService,
  setStaff,
  set_all_initial,
} from "../../redux/slices/serviceSlice";
import reduxApis from "../../redux/api";
import logo from "../../assets/images/logo/new-logo.png";
import { MdOutlineFavorite, MdFavoriteBorder } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import GalleryModal from "./GalleryModal";
import toast from "react-hot-toast";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";
import { setUser } from "../../redux/slices/userSlice";
import { Spinner } from "react-bootstrap";
import AuthModal from "../../components/AuthModal";
import { setStoreDetails } from "../../redux/slices/storeSlice";
import SalonReviews from "./SalonReviews";

const Appointment = () => {
  const { slug } = useParams();
  const [state, setState] = useState(false);
  const [storeImageError, setStoreImageError] = useState(false);
  console.log(storeImageError,"storeImageError")
  const [currentSectionId, setCurrentSectionId] = useState("photos");
  const { storeDetails, store: allStores } = useSelector((e) => e?.store);
  const { user } = useSelector((e) => e?.user);
  const { services } = useSelector((e) => e?.service);
  const dispatch = useDispatch();
  let store = storeDetails?.store;

  const [isFavorite] =
    user?.favourite?.stores?.filter((e) => e._id === store?._id) || [];

  const navigate = useNavigate();
  useEffect(() => {
    if (!slug) {
      navigate(-1);
    }
    (async () => {
      if (slug) {
        setStoreImageError(false)
        dispatch(setStoreDetails({}));
        await dispatch(reduxApis.getOneStore(slug));
      }
    })();
    dispatch(set_all_initial());
  }, [slug]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const sectionId = entry.target.getAttribute("id");
        if (sectionId && entry.isIntersecting) {
          if (sectionId === "targetId") {
            setState(true);
          } else {
            setState(false);
            setCurrentSectionId(sectionId);
          }
        }
      });
    });
    document.querySelectorAll("section").forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, [state]);

  const resultArray = storeDetails?.services
    ? Object.values(storeDetails?.services).reduce((acc, array) => {
        return acc.concat(array);
      }, [])
    : [];

  const [selectedCards, setSelectedCards] = useState(services || []);

  const handleCardClick = (service) => {
    if (
      selectedCards?.some(
        (selectedService) => selectedService?._id === service._id
      )
    ) {
      setSelectedCards(
        selectedCards?.filter(
          (selectedService) => selectedService?._id !== service._id
        )
      );
      dispatch(removeService(service));
    } else {
      setSelectedCards([...selectedCards, service]);
      dispatch(setService(service));
    }
  };

  const [show, setShow] = useState(false);
  const [showAuth, setShowAuth] = useState(false);

  const [imageLoadStatus, setImageLoadStatus] = useState([]);
  const handleImageLoadError = (index) => {
    // Set the corresponding index in the imageLoadStatus array to true
    setImageLoadStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = true;
      return newStatus;
    });
  };

  const { mutate, isLoading } = useMutation(
    () =>
      apis?.addFavorite({
        user_Id: user?._id,
        type: "store",
        ...(isFavorite ? { action: "remove" } : { action: "add" }),
        store_Id: store?._id,
      }),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
        setShow(false);
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          setShow(false);
          dispatch(setUser(data?.user));
          toast.success(data?.message, { id: 1 });
        }
      },
    }
  );

  const [showSocial, setShowSocial] = useState(false);

  return (
    <div id="react" class="react--loading">
      <AuthModal show={showAuth} setShow={setShowAuth} />
      <GalleryModal show={show} setShow={setShow} data={storeDetails?.store} />
      <SocialMediaModal show={showSocial} setShow={setShowSocial} />
      <div class="GXRSVM notranslate">
        <div class="_32iRow">
          <section class="d-none d-lg-block fixed-top" id="end">
            <div class="yZk-Qk tkw1-j hDVB9B">
              <nav class="EXyJyF">
                <Link className="CLnQGg _XdmmM" to="/">
                  <img src={logo} alt="" width={"180px"} />
                </Link>
              </nav>
              <div class="xqEsXf"></div>
            </div>
          </section>
          <div class="v83Qwl">
            <section id="end">
              <div
                id="gallery-section"
                class="bYFBS7 jCR7Ea"
                data-qa="gallery-section"
              >
                <div class="_4S6KYh">
                  <div>
                    <div id="header-subsection">
                      <div class="tsZpO9 pt-0">
                        <div
                          class="ueEcEe mt-lg-5 pt-lg-5"
                          data-qa="location-header "
                        >
                          <h1 class="_-wKhBx d-none d-lg-block font-default-header-l-semibold font-tablet-header-2xl-bold mb-default-100 mb-tablet-0 ZXeVyz ">
                            {store?.name}
                          </h1>
                          <h1 class="_-wKhBx d-lg-none font-default-header-l-semibold ">
                            {store?.name}
                          </h1>

                          <div class="d-none d-lg-flex vur2oc">
                            <div class="_4hY8Se">
                              <div role="tooltip" class="qmADeK">
                                <div>
                                  <div class="gQF-0- qaixEs">
                                    <div class="aZptzi _8oaL3i">
                                      <div class="ADCqki _8oaL3i d-flex align-items-center">
                                        <span
                                          aria-hidden="true"
                                          class="_-wKhBx rfrUux _9CRQ7x font-default-body-m-semibold"
                                        >
                                          {parseFloat(
                                            store?.rating?.toFixed(1)
                                          ) || 0}
                                        </span>
                                        <div
                                          class="mKS7Nt _8oaHFt"
                                          aria-hidden="true"
                                        >
                                          <span
                                            class="_-6pwFd _-YxYKd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              ></path>
                                            </svg>
                                          </span>
                                          <span
                                            class="_-6pwFd _-YxYKd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              ></path>
                                            </svg>
                                          </span>
                                          <span
                                            class="_-6pwFd _-YxYKd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              ></path>
                                            </svg>
                                          </span>
                                          <span
                                            class="_-6pwFd _-YxYKd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005"
                                                style={{ fill: "#FDCC0D" }}
                                              />
                                            </svg>
                                          </span>
                                          <span
                                            class="_-6pwFd _-YxYKd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              ></path>
                                            </svg>
                                          </span>
                                        </div>
                                        <p class="_-wKhBx font-default-body-m-regular ms-3 text-secondary  mb-default-200x">
                                          {store?.location}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul class="_5zCVQX gap-default-200x direction-default-horizontal display-default-inline-flex +8FwOw _3ebNVx">
                              <li class="p_e4TX">
                                <div role="tooltip" class="qmADeK">
                                  <button
                                    onClick={() => setShowSocial(true)}
                                    id="button-venue-page-share-button-desktop"
                                    data-qa="venue-page-share-button-desktop"
                                    type="button"
                                    class="bXwNAZ _XdmmM tPDowU _i2EgU xR_P5U gGQvsU I-8qUU QLs1WU _2qQHCU JKAulU"
                                    aria-pressed="false"
                                    aria-label="Share"
                                    aria-labelledby="label-venue-page-share-button-desktop"
                                    aria-disabled="false"
                                    aria-live="polite"
                                  >
                                    <span class="G9I_8U va37oU">
                                      <span
                                        id="label-venue-page-share-button-desktop"
                                        class="_-wKhBx ZCvJqx font-default-body-m-semibold font-tablet-body-m-semibold"
                                      ></span>
                                    </span>
                                    <span class="_0f0EDU">
                                      <span
                                        class="_-6pwFd _8oa-nd QLspdd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M11.47.97a.75.75 0 0 1 1.06 0l3.938 3.937a.75.75 0 0 1-1.06 1.06L12.75 3.312V12a.75.75 0 0 1-1.5 0V3.31L8.593 5.969a.75.75 0 0 1-1.06-1.06L11.47.97ZM4.19 8.69a1.5 1.5 0 0 1 1.06-.44H7.5a.75.75 0 0 1 0 1.5H5.25v9.75h13.5V9.75H16.5a.75.75 0 0 1 0-1.5h2.25a1.5 1.5 0 0 1 1.5 1.5v9.75a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V9.75c0-.398.158-.78.44-1.06Z"
                                            clip-rule="evenodd"
                                          ></path>
                                        </svg>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                              </li>
                              <li class="p_e4TX">
                                <div role="tooltip" class="qmADeK">
                                  <button
                                    onClick={() => {
                                      if (user) {
                                        mutate();
                                      } else {
                                        setShowAuth(true);
                                      }
                                    }}
                                    id="button-venue-page-favourite-button-desktop"
                                    data-qa="venue-page-favourite-button-desktop"
                                    name={store?.name}
                                    slug={store?.slug}
                                    type="button"
                                    class="bXwNAZ _XdmmM tPDowU _i2EgU xR_P5U gGQvsU I-8qUU QLs1WU _2qQHCU JKAulU"
                                    aria-pressed="false"
                                    aria-label="Sign in to add to favourites"
                                    aria-labelledby="label-venue-page-favourite-button-desktop"
                                    aria-disabled="false"
                                    aria-live="polite"
                                  >
                                    {isLoading ? (
                                      <Spinner size="sm" />
                                    ) : isFavorite ? (
                                      <MdOutlineFavorite className="fs-2 text-danger" />
                                    ) : (
                                      <MdFavoriteBorder className="fs-2 text-secondary" />
                                    )}
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div class="display-default-inline-flex d-lg-none">
                            <ul class="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                              <li class="p_e4TX">
                                <div class="gQF-0- qaixEs">
                                  <div class="aZptzi _8oaL3i">
                                    <div class="ADCqki _8oaL3i">
                                      <span
                                        aria-hidden="true"
                                        class="_-wKhBx rfrUux _9CRQ7x font-default-body-m-semibold"
                                      >
                                      {parseFloat(
                                            store?.rating?.toFixed(1)
                                          ) || 0}
                                      </span>
                                      <div
                                        class="mKS7Nt _8oaHFt"
                                        aria-hidden="true"
                                      >
                                        <span
                                          class="_-6pwFd _-YxYKd"
                                          aria-hidden="true"
                                          style={{
                                            width: "14px",
                                            height: "14px",
                                            color: "#FDCC0D",
                                          }}
                                        >
                                          <FaStar />
                                        </span>
                                        <span
                                          class="_-6pwFd _-YxYKd"
                                          aria-hidden="true"
                                          style={{
                                            width: "14px",
                                            height: "14px",
                                            color: "#FDCC0D",
                                          }}
                                        >
                                          <FaStar />
                                        </span>
                                        <span
                                          class="_-6pwFd _-YxYKd"
                                          aria-hidden="true"
                                          style={{
                                            width: "14px",
                                            height: "14px",
                                            color: "#FDCC0D",
                                          }}
                                        >
                                          <FaStar />
                                        </span>
                                        <span
                                          class="_-6pwFd _-YxYKd"
                                          aria-hidden="true"
                                          style={{
                                            width: "14px",
                                            height: "14px",
                                            color: "#FDCC0D",
                                          }}
                                        >
                                          <FaStar />
                                        </span>
                                        <span
                                          class="_-6pwFd _-YxYKd"
                                          aria-hidden="true"
                                          style={{
                                            width: "14px",
                                            height: "14px",
                                            color: "#FDCC0D",
                                          }}
                                        >
                                          <FaStar />
                                        </span>
                                      </div>
                                      <button
                                        class="bXwNAZ _XdmmM tMlkWB"
                                        id="button-react-aria-265"
                                        type="button"
                                        aria-pressed="false"
                                        aria-labelledby="label-react-aria-265"
                                        aria-live="polite"
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="_-wKhBx _3-CNtx font-default-link-m-medium MRYHBB _8oaaVB"
                                        >
                                          {/* (1,688) */}
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="_2ZLsP3">
                    <div class="d-none d-lg-flex gapss">
                      <div className="position-relative salonProfileIimage object-fit-cover height500">
                      {storeDetails?.store?.gallery?.length > 0 && (
                        <button
                          onClick={() => setShow(true)}
                          id="button-venue-page-see-all-button"
                          class="bXwNAZ _XdmmM tPDowU _i2EgU I-8qUU WnQevU JKAulU +hBwIW hNpspq seeMoreImagesBtn"
                          type="button">
                          <span class="G9I_8U">
                            <span
                              id="label-venue-page-see-all-button"
                              class="_-wKhBx ZCvJqx font-default-body-m-semibold"
                            >
                              See all images
                            </span>
                          </span>
                        </button>
                      )}
                      <img
                        src={storeImageError ? defaultSalon1 : store?.image}
                        alt={store?.name}
                        class="salonProfileIimage object-fit-cover height500"
                        onError={() => {
                          // Image failed to load, set imageLoadStatus for this index to true
                          setStoreImageError(true);
                        }}
                        />
                        </div>
                      

                      <div className="salonProfileCard">
                        <div className="POY5Gv ev0Xbv _19JJ6v idNVqv _2MKFCT">
                          <ul className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex ln+1Hh HVC-YN">
                            <li className="p_e4TX ">
                              <p className="_-wKhBx font-default-header-xl-bold qG0hnK">
                                {store?.name}
                              </p>
                            </li>
                            <li className="p_e4TX">
                              <div role="tooltip" className="qmADeK">
                                <div>
                                  <div className="gQF-0-">
                                    <div className="aZptzi s_TCOi">
                                      <p className="qTs2Ai">5 rating</p>
                                      <div className="ADCqki s_TCOi">
                                        <span
                                          aria-hidden="true"
                                          className="_-wKhBx rfrUux _9CRQ7x font-default-body-l-semibold"
                                        >
                                         {parseFloat(
                                            store?.rating?.toFixed(1)
                                          ) || 0}
                                        </span>
                                        <div
                                          className="mKS7Nt s_T-2t"
                                          aria-hidden="true"
                                        >
                                          <span
                                            className="_-6pwFd _8oa-nd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              />
                                            </svg>
                                          </span>
                                          <span
                                            className="_-6pwFd _8oa-nd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              />
                                            </svg>
                                          </span>
                                          <span
                                            className="_-6pwFd _8oa-nd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              />
                                            </svg>
                                          </span>
                                          <span
                                            className="_-6pwFd _8oa-nd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              />
                                            </svg>
                                          </span>
                                          <span
                                            className="_-6pwFd _8oa-nd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                style={{ fill: "#FDCC0D" }}
                                                d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>

                          <button
                            className="CLnQGg _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU mt-3"
                            loader="[object Object]"
                            id="location-book-now-button"
                            data-qa="book-now"
                            disabled={!resultArray?.length > 0}
                            onClick={() => navigate("/salon/select-service")}
                          >
                            <span className="G9I_8U">
                              <span
                                id="label-location-book-now-button"
                                className="_-wKhBx ZCvJqx font-default-body-m-semibold text-white "
                              >
                                Book now
                              </span>
                            </span>
                          </button>
                        </div>
                        <div className="POY5Gv ev0Xbv _19JJ6v idNVqv">
                          <ul className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex">
                            <li className="p_e4TX">
                              <ul className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex uMoxbS">
                                <li className="p_e4TX">
                                  <span
                                    className="_-6pwFd _8oa-nd +5vn+Q"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 6a.75.75 0 0 1 .75.75v4.5h4.5a.75.75 0 0 1 0 1.5H12a.75.75 0 0 1-.75-.75V6.75A.75.75 0 0 1 12 6Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </li>
                                <li className="p_e4TX">
                                  <p
                                    data-qa="opening-times"
                                    className="_-wKhBx font-default-body-m-regular mb-default-250x mb-tablet-0"
                                  >
                                    <span className="_-wKhBx _3yApkx font-default-body-m-regular">
                                      Open
                                    </span>
                                    {/* <span className="_-wKhBx rfrUux font-default-body-m-regular">
                                opens on Wednesday at 9:00am
                              </span> */}
                                  </p>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <div className="POY5Gv XXoKXv _19JJ6v idNVqv">
                          <div className="Y5-Woa">
                            <ul className="_5zCVQX gap-default-300x direction-default-vertical display-default-inline-flex qTTBg-">
                              <li className="p_e4TX">
                                <div className="yhiMeE EuGs2E">
                                  <div className="_6lhOdE">
                                    <div className="_002iGE">
                                      <div className="_nTKkE">
                                        <div className="_2rJeYE">
                                          <p className="_-wKhBx fs-3 fw-bold mb-3">
                                            About
                                          </p>
                                          <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                                            {store?.details}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="display-default-block d-lg-none">
                      <div class="EwrleP">
                        <div class="_-IrVNP">
                          <div class="f41OVP O--dXP mw-100">
                            {/* <button
                              id="button-carousel-link-to-gallery-0"
                              class="bXwNAZ _XdmmM M8cIQy"
                              type="button"
                              aria-pressed="false"
                              aria-label="Open gallery"
                              aria-labelledby="label-carousel-link-to-gallery-0"
                              aria-live="polite"
                            > */}
                            <img
                              class="salonMainImagePhone"
                              loading="lazy"
                              src={
                                storeImageError ? defaultSalon1 : store?.image
                              }
                              alt={store?.name}
                              onError={() => {
                                // Image failed to load, set imageLoadStatus for this index to true
                                setStoreImageError(true);
                              }}
                            />
                            {/* </button> */}
                          </div>
                          {storeDetails?.store?.gallery?.length > 0 &&
                            storeDetails?.store?.gallery?.map((item, index) => (
                              <div class="f41OVP O--dXP mw-100" key={index}>
                                {/* <div class="ieHaaP"></div>
                                <button
                                  id="button-carousel-link-to-gallery-8"
                                  class="bXwNAZ _XdmmM M8cIQy"
                                  type="button"
                                  aria-pressed="false"
                                  aria-label="Open gallery"
                                  aria-labelledby="label-carousel-link-to-gallery-8"
                                  aria-live="polite"
                                > */}
                                <img
                                  src={item}
                                  alt=""
                                  class="salonMainImagePhone"
                                  loading="lazy"
                                />
                                {/* </button> */}
                              </div>
                            ))}
                        </div>

                        <button
                          id="button-image-carousel-left-arrow"
                          data-qa="image-carousel-left-arrow"
                          class="bXwNAZ _XdmmM tPDowU _i2EgU ntgPCU _-YxuFU _2qQHCU JKAulU HX8A_P _7tmhKP cE_SdP"
                          type="button"
                          aria-pressed="false"
                          aria-labelledby="label-image-carousel-left-arrow"
                          aria-live="polite"
                        >
                          <span class="G9I_8U va37oU">
                            <span
                              id="label-image-carousel-left-arrow"
                              class="_-wKhBx ZCvJqx font-default-body-s-semibold"
                            >
                              Back to previous image
                            </span>
                          </span>
                          <span class="_0f0EDU">
                            <span class="_-6pwFd I-8RVd" aria-hidden="true">
                              <svg
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M14.968 6.782a.75.75 0 0 1 0 1.06L10.81 12l4.157 4.157a.75.75 0 1 1-1.06 1.06L9.22 12.53a.75.75 0 0 1 0-1.06l4.687-4.688a.75.75 0 0 1 1.06 0Z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </span>
                        </button>
                        <button
                          id="button-image-carousel-right-arrow"
                          data-qa="image-carousel-right-arrow"
                          class="bXwNAZ _XdmmM tPDowU _i2EgU ntgPCU _-YxuFU _2qQHCU JKAulU HX8A_P _7tmhKP _-vIVbP"
                          type="button"
                          aria-pressed="false"
                          aria-labelledby="label-image-carousel-right-arrow"
                          aria-live="polite"
                        >
                          <span class="G9I_8U va37oU">
                            <span
                              id="label-image-carousel-right-arrow"
                              class="_-wKhBx ZCvJqx font-default-body-s-semibold"
                            >
                              Next image
                            </span>
                          </span>
                          <span class="_0f0EDU">
                            <span class="_-6pwFd I-8RVd" aria-hidden="true">
                              <svg
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M9.22 6.782a.75.75 0 0 1 1.06 0l4.688 4.688a.75.75 0 0 1 0 1.06l-4.688 4.688a.75.75 0 1 1-1.06-1.06L13.377 12 9.22 7.843a.75.75 0 0 1 0-1.06Z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>

                    <Link to="/">
                      <button
                        id="button-venue-page-back-button"
                        data-qa="venue-page-back-button"
                        class="bXwNAZ _XdmmM tPDowU _i2EgU xR_P5U I-8qUU _2qQHCU JKAulU +hBwIW zBddCO d-block d-lg-none"
                        type="button"
                        aria-pressed="false"
                        aria-label="Go back"
                        aria-labelledby="label-venue-page-back-button"
                        aria-live="polite"
                      >
                        <span class="G9I_8U va37oU">
                          <span
                            id="label-venue-page-back-button"
                            class="_-wKhBx ZCvJqx font-default-body-m-semibold"
                          ></span>
                        </span>
                        <span class="_0f0EDU">
                          <span class="_-6pwFd _8oa-nd" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </Link>
                    <div
                      role="tooltip"
                      class="qmADeK +hBwIW FTtkd7 d-block d-lg-none"
                    >
                      <button
                        onClick={() => setShowSocial(true)}
                        id="button-venue-page-share-button-mobile"
                        data-qa="venue-page-share-button-mobile"
                        type="button"
                        class="bXwNAZ _XdmmM tPDowU _i2EgU xR_P5U I-8qUU _2qQHCU JKAulU"
                        aria-pressed="false"
                        aria-label="Share"
                        aria-labelledby="label-venue-page-share-button-mobile"
                        aria-disabled="false"
                        aria-live="polite"
                      >
                        <span class="G9I_8U va37oU">
                          <span
                            id="label-venue-page-share-button-mobile"
                            class="_-wKhBx ZCvJqx font-default-body-m-semibold"
                          ></span>
                        </span>
                        <span class="_0f0EDU">
                          <span class="_-6pwFd _8oa-nd" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M11.47.97a.75.75 0 0 1 1.06 0l3.938 3.937a.75.75 0 0 1-1.06 1.06L12.75 3.312V12a.75.75 0 0 1-1.5 0V3.31L8.593 5.969a.75.75 0 0 1-1.06-1.06L11.47.97ZM4.19 8.69a1.5 1.5 0 0 1 1.06-.44H7.5a.75.75 0 0 1 0 1.5H5.25v9.75h13.5V9.75H16.5a.75.75 0 0 1 0-1.5h2.25a1.5 1.5 0 0 1 1.5 1.5v9.75a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V9.75c0-.398.158-.78.44-1.06Z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                    <div
                      role="tooltip"
                      class="qmADeK +hBwIW rGrEzI d-block d-lg-none"
                    >
                      <button
                        onClick={() => {
                          if (user) {
                            mutate();
                          } else {
                            setShowAuth(true);
                          }
                        }}
                        id="button-venue-page-favourite-button-mobile"
                        data-qa="venue-page-favourite-button-mobile"
                        type="button"
                        class="bXwNAZ _XdmmM tPDowU _i2EgU xR_P5U I-8qUU _2qQHCU JKAulU"
                        aria-pressed="false"
                        aria-label="Sign in to add to favourites"
                        aria-labelledby="label-venue-page-favourite-button-mobile"
                        aria-disabled="false"
                        aria-live="polite"
                      >
                        <span class="G9I_8U va37oU">
                          <span
                            id="label-venue-page-favourite-button-mobile"
                            class="_-wKhBx ZCvJqx font-default-body-m-semibold"
                          ></span>
                        </span>

                        {isLoading ? (
                          <Spinner size="sm" />
                        ) : isFavorite ? (
                          <MdOutlineFavorite className="fs-2 text-danger" />
                        ) : (
                          <MdFavoriteBorder className="fs-2 text-secondary" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section class="VYbqoc" id="VYbqoc">
              <div class="GWaY+8">
                <div>
                  <div
                    id="services-section"
                    class="bYFBS7 PC+g94"
                    data-qa="services-section"
                  >
                    <h2 class="_-wKhBx font-default-header-m-semibold font-tablet-header-xl-bold mb-default-200x mb-tablet-300x K2XruG">
                      Services
                    </h2>

                    <section
                      id="react-aria-269-tabpanel-1423845"
                      aria-labelledby="react-aria-269-tab-1423845"
                      role="tabpanel"
                    >
                      <section class="_19JWNA" id="targetId">
                        {resultArray?.length > 0 &&
                          resultArray?.slice(0, 4)?.map((service, i) => (
                            <div
                              onClick={() => {
                                handleCardClick(service);
                                navigate("/salon/select-service");
                              }}
                              key={i}
                              aria-labelledby="label-react-aria-270"
                              role="group"
                              id="react-aria-271"
                              class=" mb-2"
                            >
                              <div class="KfGRUx rIZLlx _-YxP9x ZWUVlx"></div>
                              <div class="_5zCVQX gap-default-0 gap-laptop-150x direction-default-vertical display-default-flex REnoxV">
                                <div
                                  id="wrapper-s:7111465"
                                  class={`yhiMeE vYRUqE SQVduE ${
                                    services?.some(
                                      (selectedService) =>
                                        selectedService?._id === service?._id
                                    )
                                      ? " yhiuUl vYRLul Hm56kl"
                                      : "grayBorder"
                                  } `}
                                >
                                  <div class="_6lhOdE">
                                    <div class="_002iGE">
                                      <div class="_nTKkE">
                                        <div class="_2rJeYE">
                                          <h3
                                            id="label-s:7111465"
                                            class="_-wKhBx font-default-body-m-medium"
                                          >
                                            {service?.name}
                                          </h3>
                                          <p
                                            id="description-s:7111465"
                                            class="_-wKhBx u4x-xx font-default-body-s-regular mb-default-150x"
                                          >
                                            {service?.duration} min
                                          </p>
                                          <span class="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex">
                                            <p class="_-wKhBx font-default-body-s-medium">
                                              {service?.value} PKR
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="kTCnEE">
                                      <div class="t4IcZE">
                                        <button
                                          id="button-primitive-action-s:7111465"
                                          data-qa="primitive-action-undefined"
                                          class="bXwNAZ _XdmmM yvJ5q2"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-primitive-action-s:7111465"
                                          aria-live="polite"
                                        >
                                          <span class="tk-4n5">
                                            Add Colour Correction to basket
                                          </span>
                                          <span
                                            class="_-6pwFd _-YxYKd"
                                            aria-hidden="true"
                                          >
                                            {services?.some(
                                              (selectedService) =>
                                                selectedService?._id ===
                                                service?._id
                                            ) ? (
                                              <svg
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M21.295 7.545a1.125 1.125 0 1 0-1.59-1.59L10 15.659l-4.455-4.455a1.125 1.125 0 0 0-1.59 1.591l5.25 5.25c.439.44 1.151.44 1.59 0l10.5-10.5Z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            ) : (
                                              <svg
                                                fill="currentColor"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M13.125 3.75a1.125 1.125 0 0 0-2.25 0v7.125H3.75a1.125 1.125 0 0 0 0 2.25h7.125v7.125a1.125 1.125 0 0 0 2.25 0v-7.125h7.125a1.125 1.125 0 0 0 0-2.25h-7.125V3.75Z"></path>
                                              </svg>
                                            )}
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                    <input
                                      aria-labelledby="label-s:7111465"
                                      id="s:7111465"
                                      type="checkbox"
                                      aria-describedby="description-s:7111465"
                                      aria-live="polite"
                                      class="_7AUnpW"
                                      value="s:7111465"
                                    />
                                  </div>
                                  <hr
                                    aria-orientation="horizontal"
                                    class="KT-if1 CChMQ1 Sm3CD1 pz1vF1 display-laptop-none vpFoTE vYRUqE"
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </section>
                    </section>
                  </div>
                </div>
                <div>
                  <div
                    id="employees-section"
                    class="bYFBS7"
                    data-qa="employees-section"
                  >
                    <div class="GtwuVU">
                      <h2 class="_-wKhBx font-default-header-m-semibold font-tablet-header-xl-bold mb-default-200x mb-tablet-300x Gs8QQM">
                        Team
                      </h2>
                      <div class="xgH3Qi DICz4G">
                        {storeDetails?.staff?.length > 0 &&
                          storeDetails?.staff?.map((e, i) => (
                            <>
                              <div
                                onClick={() => {
                                  dispatch(setStaff(e));
                                  navigate("/salon/select-service");
                                }}
                                key={i}
                                class="CLnQGg _XdmmM Q-7_if SQVitf t8rb7f O-4bqf XbReu6 _0DF-d_"
                                id="830245"
                                data-qa="employee-card-830245"
                                aria-labelledby="name-830245"
                                aria-describedby="description-830245"
                              >
                                <div class="zx4_3f t8rb7f O-4bqf">
                                  <div
                                    id="avatar-830245"
                                    class="WiWLh_ t8rGn_ O-4pU_"
                                    data-qa="avatar-830245"
                                  >
                                    <div class="_-d_QU_">
                                      <picture
                                        data-qa="avatar-830245-image"
                                        class="rC5TQo _-rOGgo oN-sZo"
                                      >
                                        <img
                                          src={e?.image}
                                          alt="The avatar of Arch"
                                          class="N3bmGo _-rOGgo aQLF7o RwsSFo p0zJqo"
                                          loading="lazy"
                                          style={{
                                            display: imageLoadStatus[i]
                                              ? "none"
                                              : "block",
                                          }}
                                          onError={() => {
                                            // Image failed to load, set imageLoadStatus for this index to true
                                            handleImageLoadError(i);
                                          }}
                                        />
                                        {imageLoadStatus[i] && (
                                          <div className="custom-fallback-div">
                                            {e?.name?.charAt(0).toUpperCase()}
                                          </div>
                                        )}
                                      </picture>
                                    </div>
                                  </div>
                                  <div
                                    id="rating-830245"
                                    data-qa="rating-830245"
                                    class="pC6Klq dCte9f"
                                  >
                                    <div class="aZptzi I-8Vzi ZWUjqi">
                                      <p id="rating-830245-aria" class="qTs2Ai">
                                        4.9 rating
                                      </p>
                                      <div class="ADCqki I-8Vzi ZWUjqi">
                                        <span
                                          aria-hidden="true"
                                          class="_-wKhBx rfrUux _9CRQ7x font-default-body-s-semibold font-tablet-body-s-semibold"
                                        >
                                          5.0
                                        </span>
                                        <div
                                          class="mKS7Nt I-8Lrt ZWUeot"
                                          aria-hidden="true"
                                        >
                                          <span
                                            class="_-6pwFd _-YxYKd FiE0ed"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              style={{ fill: "#FDCC0D" }}
                                              fill="#FDCC0D"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="_5zCVQX gap-default-25x direction-default-vertical display-default-flex">
                                  <p
                                    id="name-830245"
                                    data-qa="name-830245"
                                    class="_-wKhBx ZCvJqx font-default-body-m-medium font-tablet-body-m-medium"
                                  >
                                    {e?.name}
                                  </p>
                                  <p
                                    id="description-830245"
                                    data-qa="description-830245"
                                    class="_-wKhBx u4x-xx ZCvJqx font-default-body-xs-regular font-tablet-body-s-regular"
                                  >
                                    {e?.title}
                                  </p>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="A4-8Vp">
                  <div>
                    {/* <SalonReviews /> */}
                    {/* <div id="reviews-section" class="bYFBS7" data-qa="reviews-section">
                                            <div class="wgcGWd"><h2 class="_-wKhBx font-default-header-m-semibold font-tablet-header-xl-bold mb-default-300x">Reviews</h2>
                                                <div>
                                                    <div role="tooltip" class="qmADeK">
                                                        <div>
                                                            <div class="gQF-0- mb-default-300x mb-tablet-400x zrxigI">
                                                                <div class="aZptzi dS0QNi">
                                                                    <p class="qTs2Ai">5 rating with 1688 votes</p>
                                                                    <div class="ADCqki dS0QNi">
                                                                        <span aria-hidden="true" class="_-wKhBx rfrUux _9CRQ7x font-default-body-m-semibold">5.0
                                                                        </span>
                                                                        <div class="mKS7Nt dS0dQt" aria-hidden="true">
                                                                            <span class="_-6pwFd gQFycd" aria-hidden="true">
                                                                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <span class="_-6pwFd gQFycd" aria-hidden="true">
                                                                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <span class="_-6pwFd gQFycd" aria-hidden="true">
                                                                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <span class="_-6pwFd gQFycd" aria-hidden="true">
                                                                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <span class="_-6pwFd gQFycd" aria-hidden="true">
                                                                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                    <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                        <button class="bXwNAZ _XdmmM tMlkWB" id="button-react-aria-276" type="button" aria-pressed="false" aria-labelledby="label-react-aria-276" aria-live="polite">
                                                                            <span aria-hidden="true" class="_-wKhBx _3-CNtx font-default-link-m-medium MRYHBB dS0FNB">(1,688)
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="bXE7uZ">
                                                        <div>
                                                            <div class="_1vRiL0" data-qa="review-item-9033186">
                                                                <div class="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex cFCaWL">
                                                                    <div id="react-aria-277" class="WiWLh_ gQFkA_">
                                                                        <div class="_-d_QU_">
                                                                            <span class="uAb4k_ gQFkA_">H
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p data-qa="review-item-name" class="_-wKhBx font-default-body-m-medium">Heidi L.</p>
                                                                        <p data-qa="review-item-date" class="_-wKhBx u4x-xx font-default-body-xs-regular">22 Dec 2023 12:05pm</p>
                                                                    </div>
                                                                </div>
                                                                <div role="tooltip" class="qmADeK rw70Ld">
                                                                    <div>
                                                                        <div class="gQF-0- mb-default-100">
                                                                            <div class="aZptzi I-8Vzi">
                                                                                <p class="qTs2Ai">5 rating</p>
                                                                                <div class="mKS7Nt I-8Lrt" aria-hidden="true">
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p data-qa="review-item-text" class="_-wKhBx font-default-body-m-regular mq-1X2">The best !! </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="_1vRiL0" data-qa="review-item-9010220">
                                                                <div class="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex cFCaWL">
                                                                    <div id="react-aria-279" class="WiWLh_ gQFkA_">
                                                                        <div class="_-d_QU_">
                                                                            <span class="uAb4k_ gQFkA_">T
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p data-qa="review-item-name" class="_-wKhBx font-default-body-m-medium">Tony A.</p>
                                                                        <p data-qa="review-item-date" class="_-wKhBx u4x-xx font-default-body-xs-regular">21 Dec 2023 1:36pm</p>
                                                                    </div>
                                                                </div>
                                                                <div role="tooltip" class="qmADeK rw70Ld">
                                                                    <div>
                                                                        <div class="gQF-0- mb-default-100">
                                                                            <div class="aZptzi I-8Vzi">
                                                                                <p class="qTs2Ai">5 rating</p>
                                                                                <div class="mKS7Nt I-8Lrt" aria-hidden="true">
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p data-qa="review-item-text" class="_-wKhBx font-default-body-m-regular mq-1X2">My cut &amp; colour by superstar Archie 100/100. </p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="_1vRiL0" data-qa="review-item-9005927">
                                                                <div class="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex cFCaWL">
                                                                    <div id="react-aria-281" class="WiWLh_ gQFkA_">
                                                                        <div class="_-d_QU_">
                                                                            <span class="uAb4k_ gQFkA_">A
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p data-qa="review-item-name" class="_-wKhBx font-default-body-m-medium">Aderonke</p>
                                                                        <p data-qa="review-item-date" class="_-wKhBx u4x-xx font-default-body-xs-regular">21 Dec 2023 9:13am</p>
                                                                    </div>
                                                                </div>
                                                                <div role="tooltip" class="qmADeK rw70Ld">
                                                                    <div>
                                                                        <div class="gQF-0- mb-default-100">
                                                                            <div class="aZptzi I-8Vzi">
                                                                                <p class="qTs2Ai">5 rating</p>
                                                                                <div class="mKS7Nt I-8Lrt" aria-hidden="true">
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p data-qa="review-item-text" class="_-wKhBx font-default-body-m-regular mq-1X2">I loved every bit of my experience!
                                                                    I was greeted with warmth and kindness by Simon, and I felt imme...
                                                                    <button class="Wfcj0B" type="button">
                                                                        <span class="_-wKhBx _3-CNtx font-default-body-m-regular">Read more
                                                                        </span>
                                                                    </button></p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="_1vRiL0" data-qa="review-item-8990011">
                                                                <div class="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex cFCaWL">
                                                                    <div id="react-aria-283" class="WiWLh_ gQFkA_">
                                                                        <div class="_-d_QU_">
                                                                            <span class="uAb4k_ gQFkA_">T
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p data-qa="review-item-name" class="_-wKhBx font-default-body-m-medium">Tami S.</p>
                                                                        <p data-qa="review-item-date" class="_-wKhBx u4x-xx font-default-body-xs-regular">20 Dec 2023 4:25pm</p>
                                                                    </div>
                                                                </div>
                                                                <div role="tooltip" class="qmADeK rw70Ld">
                                                                    <div>
                                                                        <div class="gQF-0- mb-default-100">
                                                                            <div class="aZptzi I-8Vzi">
                                                                                <p class="qTs2Ai">5 rating</p>
                                                                                <div class="mKS7Nt I-8Lrt" aria-hidden="true">
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p data-qa="review-item-text" class="_-wKhBx font-default-body-m-regular mq-1X2">Decadence Salon is the ultimate beauty haven! Beyond being a top-notch hair salon, they effortlessly...
                                                                    <button class="Wfcj0B" type="button">
                                                                        <span class="_-wKhBx _3-CNtx font-default-body-m-regular">Read more
                                                                        </span>
                                                                    </button></p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="_1vRiL0" data-qa="review-item-8977845">
                                                                <div class="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex cFCaWL">
                                                                    <div id="react-aria-285" class="WiWLh_ gQFkA_">
                                                                        <div class="_-d_QU_">
                                                                            <span class="uAb4k_ gQFkA_">B
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p data-qa="review-item-name" class="_-wKhBx font-default-body-m-medium">Birute P.</p>
                                                                        <p data-qa="review-item-date" class="_-wKhBx u4x-xx font-default-body-xs-regular">20 Dec 2023 1:06am</p>
                                                                    </div>
                                                                </div>
                                                                <div role="tooltip" class="qmADeK rw70Ld">
                                                                    <div>
                                                                        <div class="gQF-0- mb-default-100">
                                                                            <div class="aZptzi I-8Vzi">
                                                                                <p class="qTs2Ai">5 rating</p>
                                                                                <div class="mKS7Nt I-8Lrt" aria-hidden="true">
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p data-qa="review-item-text" class="_-wKhBx font-default-body-m-regular mq-1X2">Very nice owners and employees, professional masters. Thank you very much.</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="_1vRiL0" data-qa="review-item-8956273">
                                                                <div class="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex cFCaWL">
                                                                    <div id="react-aria-287" class="WiWLh_ gQFkA_">
                                                                        <div class="_-d_QU_">
                                                                            <picture class="rC5TQo _-rOGgo oN-sZo">
                                                                                <img src="https://cdn-gatekeeper-uploads.fresha.com/avatars/32282375/medium/unnamed.png?v=63870059271&amp;class=thumb" alt="The avatar of Shannoncita *." class="N3bmGo _-rOGgo aQLF7o RwsSFo p0zJqo" loading="lazy" />

                                                                            </picture>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <p data-qa="review-item-name" class="_-wKhBx font-default-body-m-medium">Shannoncita *.</p>
                                                                        <p data-qa="review-item-date" class="_-wKhBx u4x-xx font-default-body-xs-regular">18 Dec 2023 10:24pm</p>
                                                                    </div>
                                                                </div>
                                                                <div role="tooltip" class="qmADeK rw70Ld">
                                                                    <div>
                                                                        <div class="gQF-0- mb-default-100">
                                                                            <div class="aZptzi I-8Vzi">
                                                                                <p class="qTs2Ai">5 rating</p>
                                                                                <div class="mKS7Nt I-8Lrt" aria-hidden="true">
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                    <span class="_-6pwFd _-YxYKd" aria-hidden="true">
                                                                                        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z"></path>
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p data-qa="review-item-text" class="_-wKhBx font-default-body-m-regular mq-1X2">Vera is  absolutely lovely and my hair look so much healthier and bouncy.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="ApVFjF">
                                                        <button id="button-see-all-reviews" data-qa="see-all-reviews" class="bXwNAZ _XdmmM tPDowU _i2EgU _8oamEU WnQevU JKAulU GRd9Jz" type="button" aria-pressed="false" aria-labelledby="label-see-all-reviews" aria-live="polite">
                                                            <span class="G9I_8U">
                                                                <span id="label-see-all-reviews" class="_-wKhBx ZCvJqx font-default-body-m-semibold">See all
                                                                </span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
                <div class="A4-8Vp">
                  <div class="display-default-block display-lg-deprecated-none">
                    <div>
                      <div
                        id="buy-section"
                        class="bYFBS7"
                        data-qa="buy-section"
                      >
                        <div>
                          <div class="Y5-Woa">
                            <ul class="_5zCVQX gap-default-300x direction-default-vertical display-default-inline-flex qTTBg-">
                              <li class="p_e4TX">
                                <div class="yhiMeE EuGs2E">
                                  <div class="_6lhOdE">
                                    <div class="_002iGE">
                                      <div class="_nTKkE">
                                        <div class="_2rJeYE">
                                          <p class="_-wKhBx fs-3 fw-bold mb-3 ">
                                            About
                                          </p>
                                          <p class="_-wKhBx u4x-xx font-default-body-s-regular">
                                            {store?.details}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="A4-8Vp">
                  <div>
                    <div
                      id="about-section"
                      class="bYFBS7"
                      data-qa="about-section"
                    >
                      <div class="hC2OWc">
                        <div class="nSfIK0 display-lg-none">
                          <div class="RcF9s8">
                            <div className="mt-3">
                              <h3 class="_-wKhBx font-default-header-s-semibold font-tablet-header-m-semibold mb-default-150x">
                                Opening times
                              </h3>
                              <ul class="_5zCVQX gap-default-100 direction-default-vertical display-default-flex">
                                {store?.store_timings?.length > 0 &&
                                  store?.store_timings?.map((time) => (
                                    <li class="p_e4TX">
                                      <div
                                        data-qa="opening-hours-row"
                                        class="_nTKkE"
                                      >
                                        <div class="_2rJeYE beZrJE">
                                          <p
                                            data-qa="opening-hours-day"
                                            class="_-wKhBx font-default-body-m-semibold"
                                          >
                                            {time?.day}
                                          </p>
                                        </div>
                                        <div class="AzPtSE beZrJE">
                                          <ul class="_5zCVQX gap-default-50x direction-default-vertical display-default-inline-flex">
                                            <li class="p_e4TX">
                                              <p
                                                data-qa="opening-hours-range"
                                                class="_-wKhBx font-default-body-m-semibold P2VFZK"
                                              >
                                                {time?.isAvailable === true
                                                  ? time?.from +
                                                    " - " +
                                                    time?.to
                                                  : "Closed"}
                                              </p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div>
                              <h3 class="_-wKhBx font-default-header-s-semibold font-tablet-header-m-semibold mb-default-150x">
                                Additional information
                              </h3>
                              <ul class="_5zCVQX gap-default-100 direction-default-vertical display-default-inline-flex">
                                <li class="p_e4TX">
                                  <div class="eFz1sP">
                                    <span
                                      class="_-6pwFd rfr1wd I-8RVd"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M21.03 6.22a.75.75 0 0 1 0 1.06l-10.5 10.5a.75.75 0 0 1-1.06 0l-5.25-5.25a.75.75 0 1 1 1.06-1.06L10 16.19l9.97-9.97a.75.75 0 0 1 1.06 0Z"
                                          clip-rule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                    <p class="_-wKhBx font-default-body-m-regular">
                                      Instant Confirmation
                                    </p>
                                  </div>
                                </li>
                                {/* <li class="p_e4TX">
                                  <div class="eFz1sP">
                                    <span
                                      class="_-6pwFd rfr1wd I-8RVd"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M21 4.5H3A1.5 1.5 0 0 0 1.5 6v12A1.5 1.5 0 0 0 3 19.5h18a1.5 1.5 0 0 0 1.5-1.5V6A1.5 1.5 0 0 0 21 4.5ZM21 6v2.25H3V6h18Zm0 12H3V9.75h18V18Zm-1.5-2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 1 1 0-1.5h3a.75.75 0 0 1 .75.75Zm-6 0a.75.75 0 0 1-.75.75h-1.5a.75.75 0 1 1 0-1.5h1.5a.75.75 0 0 1 .75.75Z"></path>
                                      </svg>
                                    </span>
                                    <p class="_-wKhBx font-default-body-m-regular">
                                      Pay by app
                                    </p>
                                  </div>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                          <div>
                            {/* <a class="CLnQGg _XdmmM HSgcqx" href="https://maps.google.com/?daddr=36%20Store%20St%2C%20London%2C%20WC1E%207BS%2C%20England" target="_blank" aria-label="Get directions">
                                                            <picture class="rC5TQo _-rOGgo oN-sZo display-default-none display-tablet-block">
                                                                <img src="https://api.mapbox.com/styles/v1/mattydyer/clin8zo52001s01qu1e9s8zvc/static/pin-l+0D1619(-0.13098929822444916,51.52001190185547)/-0.13098929822444916,51.52001190185547,13,0/884x472@2x?access_token=pk.eyJ1IjoibWF0dHlkeWVyIiwiYSI6ImNsZGoxNGU2eTA0M24zb2t3ZXZvYWRod24ifQ.GYW7sqkNhyIqGKmaqDE5sQ" alt="36 Store St, London, England" class="N3bmGo _-rOGgo p0zJqo display-default-none display-tablet-block" loading="lazy" />

                                                            </picture>
                                                            <picture class="rC5TQo _-rOGgo X4fzio oN-sZo display-default-block display-tablet-none">
                                                                <img src="https://api.mapbox.com/styles/v1/mattydyer/clin8zo52001s01qu1e9s8zvc/static/pin-l+0D1619(-0.13098929822444916,51.52001190185547)/-0.13098929822444916,51.52001190185547,13,0/350x197@2x?access_token=pk.eyJ1IjoibWF0dHlkeWVyIiwiYSI6ImNsZGoxNGU2eTA0M24zb2t3ZXZvYWRod24ifQ.GYW7sqkNhyIqGKmaqDE5sQ" alt="36 Store St, London, England" class="N3bmGo _-rOGgo X4fzio p0zJqo display-default-block display-tablet-none" loading="lazy" />

                                                            </picture>
                                                        </a> */}
                            {/* <p class="_-wKhBx font-default-body-m-regular mt-2 text-secondary  mb-default-200x">
                              {store?.location}
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-none display-lg-deprecated-block QGHfQh">
                <div
                  id="location-profile-side-panel"
                  data-qa="location-sidepanel"
                  className=" Y5Bsdv whlC2v BF32IA"
                >
                  <div className="POY5Gv ev0Xbv _19JJ6v idNVqv _2MKFCT">
                    <ul className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex ln+1Hh HVC-YN">
                      <li className="p_e4TX">
                        <p className="_-wKhBx font-default-header-xl-bold qG0hnK">
                          {/* {store?.name} */}
                          STORE TIMING
                        </p>
                      </li>
                      <li className="p_e4TX">
                        {/* <div role="tooltip" className="qmADeK">
                          <div>
                            <div className="gQF-0-">
                              <div className="aZptzi s_TCOi">
                                <p className="qTs2Ai">5 rating</p>
                                <div className="ADCqki s_TCOi">
                                  <span
                                    aria-hidden="true"
                                    className="_-wKhBx rfrUux _9CRQ7x font-default-body-l-semibold"
                                  >
                                    {store?.rating}
                                  </span>
                                  <div
                                    className="mKS7Nt s_T-2t"
                                    aria-hidden="true"
                                  >
                                    <span
                                      className="_-6pwFd _8oa-nd"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                      </svg>
                                    </span>
                                    <span
                                      className="_-6pwFd _8oa-nd"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                      </svg>
                                    </span>
                                    <span
                                      className="_-6pwFd _8oa-nd"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                      </svg>
                                    </span>
                                    <span
                                      className="_-6pwFd _8oa-nd"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                      </svg>
                                    </span>
                                    <span
                                      className="_-6pwFd _8oa-nd"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <ul class="_5zCVQX gap-default-100 direction-default-vertical display-default-flex">
                          {store?.store_timings?.length > 0 &&
                            store?.store_timings?.map((time) => (
                              <li class="p_e4TX">
                                <div data-qa="opening-hours-row" class="_nTKkE">
                                  <div class="_2rJeYE beZrJE">
                                    <p
                                      data-qa="opening-hours-day"
                                      class="_-wKhBx font-default-body-m-semibold"
                                    >
                                      {time?.day}
                                    </p>
                                  </div>
                                  <div class="AzPtSE beZrJE">
                                    <ul class="_5zCVQX gap-default-50x direction-default-vertical display-default-inline-flex">
                                      <li class="p_e4TX">
                                        <p
                                          data-qa="opening-hours-range"
                                          class="_-wKhBx font-default-body-m-semibold P2VFZK"
                                        >
                                          {time?.isAvailable === true
                                            ? time?.from + " - " + time?.to
                                            : "Closed"}
                                        </p>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>

            <div
              data-qa="bottom-bar"
              className="gMnlBP bjDb5P xa-C1P DETZNP zFOHKP fixed-bottom  display-default-block display-laptop-none"
            >
              <div className="K9p22P d-flex justify-content-between">
                <ul
                  className="_5zCVQX gap-default-0 direction-default-horizontal display-default-flex"
                  style={{ justifyContent: "space-between", flexGrow: 1 }}
                >
                  <li className="p_e4TX">
                    <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                      <li className="p_e4TX">
                        <p className="_-wKhBx font-default-body-s-semibold">
                          Total 0 PKR
                        </p>
                      </li>
                      <li className="p_e4TX">
                        <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                          {services?.length} services
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className="p_e4TX">
                    {resultArray?.length > 0 && (
                      <Link to="/salon/select-service">
                        <button
                          id="button-react-aria-106"
                          data-qa="continue-button-mobile"
                          className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU JKAulU"
                          type="button"
                          // aria-disabled={isBookingCreating || isConfirming}
                          // disabled={isBookingCreating || isConfirming}
                          aria-labelledby="label-react-aria-106"
                          aria-live="polite"
                        >
                          <span className="G9I_8U">
                            <span
                              id="label-react-aria-106"
                              className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                            >
                              Book Now
                            </span>
                          </span>
                        </button>
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Section2 heading={"Near by"} stores={allStores} />

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Appointment;
