import React from "react";

const SalonProgressBar = ({ title }) => {
  const steps = [
    {
      stepno: 1,
      title: "service",
    },
    {
      stepno: 2,
      title: "staff",
    },
    {
      stepno: 3,
      title: "appointment",
    },
    {
      stepno: 4,
      title: "payment",
    },
  ];

  const activeStep = steps.find(
    (step) => step.title.toLowerCase() === title.toLowerCase()
  );

  return (
    <>
      {/* <div class="container mt-4">
        <ul class="progressbar">
          <li class="active">
            <span className="text-black">Service</span>
          </li>
          <li class="active">
            <span className="text-black">Staff</span>
          </li>
          <li>
            <span className="text-black">Schedule</span>
          </li>
          <li>
            <span className="text-black">Payment</span>
          </li>
        </ul>
      </div> */}

      <div className="container mt-4">
        <ul className="progressbar">
          {steps.map((step, index) => (
            <li
              key={index}
              className={step.stepno <= activeStep?.stepno ? "active" : ""}
            >
              <span className="text-black">{step.title}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SalonProgressBar;
