import React, { useState } from "react";
import { Link } from "react-router-dom";
import { login_validation } from "../Validations/login_validation";
import { errorValidate } from "../Validations/errorHandle";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineMail,
  AiOutlineUnlock,
  AiFillApple,
} from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import Logo from "../components/assests/logos.png";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  let [data, setData] = useState({ email: "", password: "" });
  const onChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({ ...prevData, [id]: value }));
  };
  const signIn = (e) => {
    // let data = {email :  data?.email , password :  data?.password}

    let response = errorValidate(login_validation(data));
    console.log(data, "data");
    if (response === true) {
      console.log(data);
    } else {
      console.log(response);
    }
  };
  return (
    <div className="container-fluid d-flex align-items-center height_10vh">
  
      <div className="container my-5">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-6 login_border m-auto p-3 p-lg-4 shadow zoom-up">
            <h2 className="fw-bold text-animation-fadeOut">Sign In</h2>

            <div className="my-3 mt-4 position-relative text-animation-fadeOut">
              <input
                type="email"
                className="form-control login_input shadow-sm"
                id="email"
                name="email"
                onChange={onChange}
                placeholder="name@example.com"
              />
              <AiOutlineMail className="msg_icon" />
            </div>

            <div className="mb-3 position-relative text-animation-fadeOut">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control login_input shadow-sm"
                id="password"
                name="password"
                onChange={onChange}
                placeholder="**********"
              />
              <AiOutlineUnlock className="msg_icon" />
              <span
                onClick={() => setShowPassword(!showPassword)}
                className="eye_icon"
              >
                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </span>
            </div>

            <div className="d-flex p-0 justify-content-between text-animation-fadeOut mt-2">
              <span>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="rememberMe"
                />
                <label className="form-check-label ms-2" for="rememberMe">
                  Remember Me
                </label>
              </span>
              <Link to="/forgot_password">
                <p className="hover_underline">Forgot Password ?</p>
              </Link>
            </div>

            <button
              className="btn btn-primary w-100 pink_btn mt-1 text-animation-fadeOut shadow-sm "
              onClick={() => signIn}
              type="button"
            >
              Login
            </button>
            <Link to="/signup">
              <button className="btn btn-light w-100 mt-3 mb-4 shadow text-animation-fadeOut">
                Create New Account
              </button>
            </Link>

            <div className="hr_line position-relative text-animation-fadeOut">
              <hr />
              <div className="position-absolute top-50 start-50 translate-middle">
                <p className="mt-3 text_hr_p">or continue with</p>
              </div>
            </div>

            <div className="continue_logos d-flex justify-content-center mt-5 text-animation-fadeOut">
              <FcGoogle className="mx-3 shadow rounded-circle" />
              <AiFillApple className="mx-3 shadow rounded-circle" />
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <img src={Logo} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
