import React, { useEffect, useState } from "react";
import "../home.css";
import "../client.c7918688.css";
import "../src_widgets_StickyBar_index_ts.d212fab5.chunk.css";
import "../design-system.dd5c5e65.css";
import "../static-pages-chnk.5b17223c.chunk.css";
import "../vendor.bbe55722.css";
import "../design-system.e4a4ab19.css";
import "../client.417b6a9f.css";
import "../vendor.9daa5ce5.css";
import { Helmet } from "react-helmet";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import BusinessPartner from "./BusinessPartner";
import Header from "../Header";
import Footer from "../Footer";
import TreatmentMobile from "../../shared/components/TreatmentMobile";
import SearchLocationMobile from "../../shared/components/SearchLocationMobile";
import DateMobile from "../../shared/components/DateMobile";
import TimeMobile from "../../shared/components/TimeMobile";
import { useDispatch, useSelector } from "react-redux";
import reduxApis from "../../redux/api";
import AuthModal from "../../components/AuthModal";
import moment from "moment";

const Home = ({ showMenu, setShowMenu }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [treatmentMobile, setTreatmentMobile] = useState(false);
  const [locationMobile, setLocationMobile] = useState(false);
  const [dateMobile, setDateMobile] = useState(false);
  const [timeMobile, setTimeMobile] = useState(false);
  const { store } = useSelector((store) => store?.store);
  const [showCalander, setShowCalander] = useState(false);
  const [show, setShow] = useState(false);
  const { user } = useSelector((e) => e?.user);

  const [data, setData] = useState({
    search: "",
    category: "",
    location: "",
    longitude: "",
    latidute: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      await dispatch(reduxApis.getAllStore(user?._id));
    })();
    const urlParams = new URLSearchParams(window.location.search);
    const yourQueryParam = urlParams.get("referralCode");
    if (yourQueryParam && !user) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    const handleGlobalMouseDown = (event) => {
      const menuParent = document.getElementById("calanderParent");

      if (
        showCalander &&
        event.target !== event.currentTarget &&
        (!menuParent || !menuParent.contains(event.target))
      ) {
        setShowCalander(!showCalander);
      }
    };

    document.addEventListener("mousedown", handleGlobalMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleGlobalMouseDown);
    };
  }, [showCalander]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Beautowns Home</title>
      </Helmet>
      <div id="react" className="react--loading">
        <AuthModal user={user} show={show} setShow={setShow} />
        <div className="GXRSVM notranslate">
          <svg
            className="G2o70Q"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <linearGradient
              id="linearGradientAccent"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset={0} className="PkcZGQ" />
              <stop offset={1} className="WtaDyQ" />
            </linearGradient>
          </svg>
          <div style={{ height: "0px", width: "0px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 102 30.1">
              <radialGradient
                id="freshaLogoExtGradient"
                cx="-8424.306"
                cy="-8111.145"
                r="13.573"
                fx="-8424.306"
                fy="-8097.771"
                gradientTransform="matrix(1 -.00367 .00363 .99 8468.848 8014.157)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".45" stopColor="#7b69ff" />
                <stop offset=".49" stopColor="#7b69ff" stopOpacity=".98" />
                <stop offset=".55" stopColor="#7b69ff" stopOpacity=".91" />
                <stop offset=".61" stopColor="#7b69ff" stopOpacity=".8" />
                <stop offset=".68" stopColor="#7b69ff" stopOpacity=".65" />
                <stop offset=".75" stopColor="#7b69ff" stopOpacity=".46" />
                <stop offset=".82" stopColor="#7b69ff" stopOpacity=".25" />
                <stop offset=".82" stopColor="#7b69ff" stopOpacity=".24" />
                <stop offset=".87" stopColor="#7b69ff" stopOpacity=".13" />
                <stop
                  offset=".91"
                  stopColor="#7b69ff"
                  stopOpacity="6.000000e-02"
                />
                <stop
                  offset=".95"
                  stopColor="#7b69ff"
                  stopOpacity="1.000000e-02"
                />
                <stop offset={1} stopColor="#7b69ff" stopOpacity={0} />
              </radialGradient>
              <circle
                cx={15}
                cy={15}
                r={15}
                fill="url(#frenoiLogoWithSpotlight)"
              />
            </svg>
          </div>
          <div className="_32iRow">
            <Header
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
            />

            <div className="L5Ntp6">
              <div className="_4jKGSa">
                <div className="SWiJzX -cUJrF">
                  <div className="SWiJzX MQH7Px">
                    <span className="_2JTEU2 nkQSL5" />
                    <span className="_2JTEU2 rK-SRb" />
                  </div>
                </div>
              </div>
              <div className="lJh45t +RppSt">
                <Section1
                  data={data}
                  setData={setData}
                  showCalander={showCalander}
                  setShowCalander={setShowCalander}
                  treatmentMobile={treatmentMobile}
                  setTreatmentMobile={setTreatmentMobile}
                  locationMobile={locationMobile}
                  setLocationMobile={setLocationMobile}
                  dateMobile={dateMobile}
                  setDateMobile={setDateMobile}
                  timeMobile={timeMobile}
                  setTimeMobile={setTimeMobile}
                />
                <Section2 heading={"New to beautowns"} No={1} stores={store} />
                <Section2 heading={"Recommended"} No={2} stores={store} />
              </div>
              <Section4 />
              <BusinessPartner />
              <Section5 />
              {/* <Section6 /> */}
              {/* <Section7 /> */}
              <Footer />
            </div>
          </div>
        </div>
      </div>

      {treatmentMobile && (
        <TreatmentMobile
          data={data}
          setData={setData}
          setTreatmentMobile={setTreatmentMobile}
        />
      )}
      {locationMobile && (
        <SearchLocationMobile
          data={data}
          setData={setData}
          setLocationMobile={setLocationMobile}
        />
      )}
      {dateMobile && <DateMobile setDateMobile={setDateMobile} />}
      {timeMobile && <TimeMobile setTimeMobile={setTimeMobile} />}
    </>
  );
};

export default Home;
