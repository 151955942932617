import React from "react";
import SalonProgressBar from "../components/SalonProgressBar";
import ProcessHeader from "../components/ProcessHeader";
import PaymentCard from "../components/PaymentCard";

const Payment = () => {
  return (
    <>
      <ProcessHeader title={"Payment"} />
      <SalonProgressBar title={"payment"} />
      <PaymentCard />
    </>
  );
};

export default Payment;
