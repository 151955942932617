import React from "react";

function TreatmentMobile({ setTreatmentMobile, data, setData }) {
  const onChange = (e) => {
    let { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  return (
    <div className="fresha-partner-react-portal-wrapper">
      <div>
        <div className="display-default-block display-laptop-none">
          <div className="efxtmb vQhmf5 rPV9JO">
            <div>
              <div className="_0UuadL u0oPO3 rPV9JO" data-qa="overlay">
                <div className="RBvj7V rPV9JO">
                  <div>
                    <div className="efxtmb _9dsAbT KiIPz9 rPV9JO" role="dialog">
                      <div className="DGeIbr" data-scroll="true">
                        <div className="wh2NbF">
                          <div className="BZrTWG">
                            <button
                              onClick={() => {
                                setTreatmentMobile(false);
                              }}
                              id="button-nom"
                              className="bXwNAZ _XdmmM Dd8ZaL"
                              type="button"
                              aria-pressed="false"
                              aria-label="label-back-button"
                              aria-labelledby="label-nom"
                              aria-live="polite"
                            >
                              <span
                                className="_-6pwFd _8oa-nd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  style={{ width: "24px" }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </button>
                            <p className="_-wKhBx font-default-header-l-semibold LiZoI4">
                              Treatment or venue
                            </p>
                          </div>
                          <div
                            className="KRX-Tm UbcpRt _6j6RNv avz66h display-default-flex display-laptop-none ICZmSz"
                            data-qa="venue-treatment-search"
                          >
                            <p className="_-wKhBx font-default-body-xs-semibold -iA7Wy">
                              Treatment or venue
                            </p>
                            <div className="tyeEeg">
                              <span
                                className="_-6pwFd I-8RVd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10.875 3.75a7.125 7.125 0 1 0 0 14.25 7.125 7.125 0 0 0 0-14.25ZM2.25 10.875a8.625 8.625 0 1 1 15.231 5.546l4.05 4.049a.75.75 0 1 1-1.061 1.06l-4.05-4.049a8.625 8.625 0 0 1-14.17-6.606Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </div>
                            <input
                              placeholder="Any treatment"
                              data-qa="venue-treatment-search-input"
                              name="search"
                              onChange={onChange}
                              value={
                                data?.search ? data?.search : data?.category
                              }
                            />
                            <button
                              aria-label="Clear"
                              className="tyeEeg pQGfZy"
                              type="button"
                              data-qa="venue-treatment-search-clear"
                            >
                              <span
                                className="_-6pwFd I-8RVd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm6.22-3.53a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 0-1.06Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div className="kAQAWG ALEl-x">
                            <div>
                              <span
                                tabIndex={0}
                                style={{
                                  height: "0px",
                                  opacity: 0,
                                  pointerEvents: "none",
                                  position: "absolute",
                                  width: "0px",
                                  zIndex: -1,
                                }}
                              />
                              <div
                                className="n5MyOK JRGYmK _7xLjxK _-PJ_bK CzY2UK _0LgBpK EKsrIK"
                                style={{
                                  columnMin: "0px",
                                  columnMax: "1fr",
                                  rowMin: "0px",
                                  rowMax: "auto",
                                }}
                              >
                                <ul className="_5zCVQX gap-default-150x direction-default-vertical display-default-inline-flex">
                                  <li className="p_e4TX" />
                                  <li className="SvLLjn">
                                    <p className="_-wKhBx rfrUux font-default-body-m-semibold font-laptop-body-s-semibold DrmJ1n">
                                      Top categories
                                    </p>
                                    <ul className="rmG1Kn">
                                      <li
                                        id="react-aria-261"
                                        data-qa="menu-item"
                                        aria-label="Hair & styling"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Hair & styling",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-261"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-261"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path d="M15 36a3 3 0 0 0 3-3v-9.634l20.726-3.16a3.012 3.012 0 0 1 1.996.393l3.764 2.258A1 1 0 0 0 46.001 22V4a1 1 0 0 0-1.515-.857l-3.764 2.258a3 3 0 0 1-1.996.393L14.7 2.131A11.08 11.08 0 0 0 13 2a10.922 10.922 0 0 0-7.832 3.276 10.926 10.926 0 0 0-3.167 7.88 10.941 10.941 0 0 0 3.164 7.548A11.083 11.083 0 0 0 8 22.794V33a3 3 0 0 0 3 3 1 1 0 0 1 1 1v4a5 5 0 0 0 5 5h8a5 5 0 0 0 5-5v-8a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v13h2V33a5 5 0 0 0-5-5h-8a5 5 0 0 0-5 5v8a3 3 0 0 1-3 3h-8a3 3 0 0 1-3-3v-4a1 1 0 0 1 1-1ZM43.013 6.331 44 5.766v14.468l-.987-.565A4 4 0 0 1 41 16.198V9.804c0-1.434.768-2.761 2.013-3.473ZM6.591 19.303a8.952 8.952 0 0 1-2.59-6.174 8.937 8.937 0 0 1 2.591-6.448A8.935 8.935 0 0 1 13 4c.452 0 .906.034 1.349.1l21.254 3.241A4 4 0 0 1 39 11.295v3.409a4 4 0 0 1-3.397 3.954l-21.25 3.24a9.099 9.099 0 0 1-1.635.097 8.944 8.944 0 0 1-6.128-2.693l.001.001ZM11 34a1 1 0 0 1-1-1v-8.022c0-.695.623-1.215 1.31-1.108a10.98 10.98 0 0 0 3.389-.001 1.13 1.13 0 0 1 1.3 1.117V33a1 1 0 0 1-1 1H11Z" />
                                              <path d="M13 19c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6Zm0-10c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4Z" />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Hair &amp; styling
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-262"
                                        data-qa="menu-item"
                                        aria-label="Nails"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Nails",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-262"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-262"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path d="M41 8h-1V7c0-2.757-2.243-5-5-5H13c-2.757 0-5 2.243-5 5v1H7c-2.757 0-5 2.243-5 5v33h2V13c0-1.654 1.346-3 3-3h1v14c0 8.822 7.178 16 16 16s16-7.178 16-16V10h1c1.654 0 3 1.346 3 3v33h2V13c0-2.757-2.243-5-5-5ZM16.045 35.514a1.003 1.003 0 0 1-.079-1.593A12.455 12.455 0 0 1 23.999 31c2.972 0 5.8 1.045 8.033 2.921a1.003 1.003 0 0 1-.079 1.593A13.919 13.919 0 0 1 23.998 38c-2.953 0-5.692-.919-7.953-2.486ZM38 24c0 2.771-.81 5.357-2.204 7.533a1.997 1.997 0 0 1-2.904.51 14.455 14.455 0 0 0-8.891-3.044c-3.26 0-6.372 1.084-8.891 3.044a1.998 1.998 0 0 1-2.904-.51A13.914 13.914 0 0 1 10.002 24V7a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3L38 24Z" />
                                              <path d="M21 8h-2v2a2 2 0 0 1-2 2h-2v2h2a2 2 0 0 1 2 2v2h2v-2a2 2 0 0 1 2-2h2v-2h-2a2 2 0 0 1-2-2V8ZM31 18h-2v1a1 1 0 0 1-1 1h-1v2h1a1 1 0 0 1 1 1v1h2v-1a1 1 0 0 1 1-1h1v-2h-1a1 1 0 0 1-1-1v-1Z" />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Nails
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-263"
                                        data-qa="menu-item"
                                        aria-label="Eyebrows & eyelashes"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Eyebrows & eyelashes",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-263"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-263"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path d="M24 24c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4Zm.001 5.999A2.002 2.002 0 0 1 22 28l.003-.051a2.509 2.509 0 0 0 1.947-1.947l.051-.003c1.103 0 2 .897 2 2s-.897 2-2 2Z" />
                                              <path d="M45.789 27.386c-.057-.073-1.099-1.397-3.14-3.032a.373.373 0 0 1 .001-.585c1.548-1.196 3.349-3.681 3.349-8.769h-2c0 3.813-1.117 5.8-2.157 6.823a2.002 2.002 0 0 1-2.436.288 14.484 14.484 0 0 0-.67-.381.37.37 0 0 1-.067-.598c1.923-1.753 2.33-4.304 2.33-6.132h-2c0 2.595-.914 3.993-1.767 4.732a1.988 1.988 0 0 1-2.027.34c-.298-.116-.603-.23-.916-.34a.373.373 0 0 1-.123-.632c1.202-1.045 1.832-2.445 1.832-4.1h-2c0 1.171-.488 1.986-1.101 2.548-.902.827-2.153 1.149-3.358.935A31.614 31.614 0 0 0 23.998 18c-7.475 0-12.985 2.533-16.291 4.659-3.602 2.316-5.423 4.63-5.499 4.727a1 1 0 0 0 0 1.228c.076.097 1.896 2.411 5.499 4.727C11.013 35.466 16.523 38 23.998 38s12.985-2.533 16.291-4.659c3.602-2.316 5.423-4.63 5.499-4.727a1 1 0 0 0 .001-1.228Zm-36.926 4.32a24.907 24.907 0 0 1-3.816-2.982 1.003 1.003 0 0 1 0-1.448 24.94 24.94 0 0 1 3.815-2.982 27.232 27.232 0 0 1 8.179-3.47A9.972 9.972 0 0 0 13.998 28a9.972 9.972 0 0 0 3.043 7.176 27.224 27.224 0 0 1-8.178-3.47ZM23.999 36c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8Zm18.952-7.276a24.94 24.94 0 0 1-3.815 2.982 27.232 27.232 0 0 1-8.179 3.47A9.972 9.972 0 0 0 34 28a9.972 9.972 0 0 0-3.043-7.176 27.232 27.232 0 0 1 8.179 3.47 24.907 24.907 0 0 1 3.816 2.982 1.003 1.003 0 0 1-.001 1.448ZM4.588 18.809l-1.176-1.617c.117-.085 2.941-2.112 8.891-4.138C17.735 11.205 26.949 9.001 40 9.001h6v2h-6c-24.393 0-35.304 7.73-35.412 7.808Z" />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Eyebrows &amp; eyelashes
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-264"
                                        data-qa="menu-item"
                                        aria-label="Massage"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Massage",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-264"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-264"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M35 2h4v2a2 2 0 0 1 2 2v7h3v2H24v-2h9V6a2 2 0 0 1 2-2V2Zm4 4v7h-4V6h4ZM24 26v-3H6v3H5a3 3 0 0 0-3 3v5h4v9h4v3h2v-3h12v-9h6.172L37 40.828V46h2V35a1 1 0 0 1 1-1h6v-5a3 3 0 0 0-3-3H24ZM4 32h2v-4H5a1 1 0 0 0-1 1v3Zm18-5a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v14h14V27Zm2 1v4h20v-3a1 1 0 0 0-1-1H24Zm13 10-4-4h3a1 1 0 0 1 1 1v3Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Massage
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-265"
                                        data-qa="menu-item"
                                        aria-label="Barbering"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Barbering",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-265"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-265"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path d="M39.581 31 46 2h-5.3a4.973 4.973 0 0 0-4.878 3.903L33.551 16H24a5 5 0 0 0-5 5v1h-1.982a5.02 5.02 0 0 0-4.805 3.618L8.885 36.846A3.017 3.017 0 0 1 6.007 39H2v2h4.007a5.027 5.027 0 0 0 4.796-3.587l1.052-3.549a4 4 0 0 1 3.835-2.863H21a2 2 0 0 1 2 2v10a1 1 0 0 1-1 1h-4v2h12v-2h-4a1 1 0 0 1-1-1v-10a2 2 0 0 1 2-2L39.581 31ZM21 21a3 3 0 0 1 3-3h7.852a1 1 0 0 1 .976 1.219l-.274 1.219a2 2 0 0 1-1.951 1.561h-9.602L21 21Zm-6.867 5.177A3.013 3.013 0 0 1 17.018 24h16.784l3.971-17.658A2.984 2.984 0 0 1 40.7 4h2.809l-5.187 23.432A2 2 0 0 1 36.369 29H13.327l.806-2.823Z" />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Barbering
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-266"
                                        data-qa="menu-item"
                                        aria-label="Hair removal"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Hair removal",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-266"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-266"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M24 4a2 2 0 0 1 2-2h11v1a1 1 0 0 0 1 1h8v2h-8a1 1 0 0 0-1 1v1h-6v2a3.5 3.5 0 1 1-7 0V4Zm11 0h-9v6a1.5 1.5 0 0 0 3 0V7.833C29 6.821 29.82 6 30.833 6H34a1 1 0 0 0 1-1V4ZM24 19.5a3.5 3.5 0 1 1 7 0v1a3.5 3.5 0 1 1-7 0v-1Zm3.5-1.5a1.5 1.5 0 0 0-1.5 1.5v1a1.5 1.5 0 0 0 3 0v-1a1.5 1.5 0 0 0-1.5-1.5ZM6.281 23.127l12.086 10.296c-2.38 1.665-2.056 5.283.581 6.5l12.493 5.767a4 4 0 0 0 5.254-1.843l.647-1.294-3.678-1.839-21.887-29.976a4 4 0 0 0-3.48-1.634l-6.235.39v2.004l6.36-.397a2 2 0 0 1 1.74.816l2.86 3.918a.774.774 0 0 1-.2 1.104 3.097 3.097 0 0 0-.781 4.449l4.848 6.464a3.303 3.303 0 0 0 4.482.762.826.826 0 0 1 1.127.199l9.838 13.473 2.322 1.161a1.429 1.429 0 0 1-1.876.658L20.159 38.28c-1.401-.647-1.573-2.57-.309-3.455l1.783-1.248-13.43-11.44a2 2 0 0 1-.703-1.522V19.5h-2v.338a2 2 0 0 1-1.368 1.897l-1.816.605v2.109l3.965-1.322Zm7.91-2.206 3.902 5.202a2 2 0 0 0 2.713.461l.044-.029-6.167-8.445a1.957 1.957 0 0 0-.493 2.81Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Hair removal
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-267"
                                        data-qa="menu-item"
                                        aria-label="Facials & skincare"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Facials & skincare",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-267"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-267"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path d="M24 46a21.853 21.853 0 0 1-15.556-6.444A21.857 21.857 0 0 1 2 24 21.853 21.853 0 0 1 8.444 8.444 21.853 21.853 0 0 1 24 2a21.853 21.853 0 0 1 15.556 6.444A21.857 21.857 0 0 1 46 24a21.853 21.853 0 0 1-6.444 15.556A21.857 21.857 0 0 1 24 46Zm0-42C12.972 4 4 12.972 4 24s8.972 20 20 20 20-8.972 20-20S35.028 4 24 4Z" />
                                              <path d="M24 35.5c-6.617 0-12-5.159-12-11.5h2c0 5.238 4.486 9.5 10 9.5s10-4.262 10-9.5h2c0 6.341-5.383 11.5-12 11.5ZM17 19a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM31 19a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Facials &amp; skincare
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-268"
                                        data-qa="menu-item"
                                        aria-label="Injectables & fillers"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Injectables & fillers",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-268"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-268"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path d="M36.315 17.636C34.13 15.766 32.066 14 29 14c-1.542 0-2.84.643-3.817 1.374a1.98 1.98 0 0 1-2.366 0C21.84 14.643 20.541 14 19 14c-3.066 0-5.13 1.766-7.315 3.636C9.178 19.781 6.586 22 2 22v2c1.204 0 3.098 1.25 5.29 2.697C11.319 29.357 16.838 33 24 33c7.162 0 12.681-3.643 16.71-6.303C42.902 25.25 44.796 24 46 24v-2c-4.585 0-7.178-2.218-9.685-4.364Zm-23.331 1.52C14.963 17.463 16.672 16 18.999 16c2.327 0 4.152 2.532 4.168 2.555l.828 1.238.834-1.235c.017-.026 1.754-2.557 4.17-2.557s4.036 1.462 6.015 3.156c1.061.908 2.179 1.864 3.478 2.685-3.378.156-5.692.703-7.821 1.208-2.154.511-4.014.952-6.672.952-2.658 0-4.519-.441-6.672-.952-2.129-.505-4.443-1.052-7.821-1.208 1.299-.821 2.417-1.779 3.478-2.686Zm26.623 5.872C35.577 27.689 30.561 31 23.999 31c-6.562 0-11.578-3.311-15.608-5.972a95.516 95.516 0 0 0-1.876-1.218c4.89-.109 7.763.571 10.35 1.184 2.181.517 4.241 1.005 7.134 1.005 2.893 0 4.953-.488 7.134-1.005 2.587-.613 5.459-1.293 10.35-1.184-.593.371-1.217.783-1.876 1.218ZM39.707 7.293l-5-5a.999.999 0 0 0-1.414 0l-5 5 1.414 1.414 1.586-1.586c.63-.63 1.707-.184 1.707.707V12h2V7.828c0-.891 1.077-1.337 1.707-.707l1.586 1.586 1.414-1.414ZM19.707 7.293l-5-5a.999.999 0 0 0-1.414 0l-5 5 1.414 1.414 1.586-1.586c.63-.63 1.707-.184 1.707.707V12h2V7.828c0-.891 1.077-1.337 1.707-.707l1.586 1.586 1.414-1.414ZM18.293 40.707l5 5a.999.999 0 0 0 1.414 0l5-5-1.414-1.414-1.586 1.586c-.63.63-1.707.184-1.707-.707V36h-2v4.172c0 .891-1.077 1.337-1.707.707l-1.586-1.586-1.414 1.414Z" />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Injectables &amp; fillers
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-269"
                                        data-qa="menu-item"
                                        aria-label="Body"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Body",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-269"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-269"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M4 19v-4.577l18-10.42V2h4v2.002l18 10.421V19a3 3 0 0 1-3 3h-8c-.768 0-1.47-.289-2-.764A2.989 2.989 0 0 1 29 22h-4v12h17a4 4 0 0 1 4 4v4h-7v1a1 1 0 0 0 1 1h2v2h-8v-2h2a1 1 0 0 0 1-1v-1H16a1 1 0 0 0 0 2h2v2h-8v-2h2a1 1 0 0 0 1-1v-1.172a1 1 0 0 0-.293-.707L2.256 30.67l2.836-2.836a4 4 0 0 1 5.656 0L16.914 34H23V22h-4c-.768 0-1.47-.289-2-.764A2.989 2.989 0 0 1 15 22H7a3 3 0 0 1-3-3Zm25 1a1 1 0 0 0 1-1v-3H18v3a1 1 0 0 0 1 1h10Zm-13-4v3a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3h10Zm-7.277-2h7.728l4.508-7.084L8.723 14ZM24 5.863 18.822 14h10.356L24 5.863ZM32 16v3a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-3H32Zm7.277-2h-7.728L27.04 6.916 39.277 14ZM44 40H14.414l-9.33-9.33 1.422-1.422a2 2 0 0 1 2.828 0L16.086 36H42a2 2 0 0 1 2 2v2Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Body
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-270"
                                        data-qa="menu-item"
                                        aria-label="Tattoo & piercing"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Tattoo & piercing",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-270"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-270"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path d="m44.989 31.858-1.979.284.321 2.245c.126.88-.874 1.474-1.587.944l-2.8-2.084a2 2 0 0 1-.351-2.874c1.158-1.408 1.959-2.563 2.443-3.336 1.968-3.14 2.965-6.165 2.965-8.99 0-3.047-.99-5.804-2.788-7.762C39.24 8.136 36.4 7 33.001 7c-4.17 0-6.768 2.081-8 3.405-1.232-1.324-3.83-3.405-8-3.405-3.227 0-5.651.973-7.391 2.478-.708.612-1.753.633-2.504.074L2.597 6.198 1.403 7.802l4.705 3.501a2.01 2.01 0 0 1 .673 2.321A12.693 12.693 0 0 0 6 18.046c0 2.825.998 5.85 2.965 8.99 1.537 2.453 3.672 4.99 6.344 7.541 4.52 4.313 8.968 7.148 9.155 7.267l.535.339.535-.339c3.752-2.459 6.759-4.958 9.111-7.224a1.996 1.996 0 0 1 2.578-.161l3.285 2.444c.716.533.428 1.666-.456 1.792l-2.195.314.283 1.98 7.99-1.141-1.141-7.99ZM25 39.802C22.056 37.817 8 27.823 8 18.047c0-1.233.171-2.342.486-3.327a1 1 0 0 1 1.549-.494l7.368 5.483 1.194-1.604-7.524-5.599a1 1 0 0 1-.031-1.581C12.625 9.648 14.707 9.001 17 9.001c4.845 0 7.062 3.39 7.152 3.53l.842 1.339.852-1.335c.092-.144 2.309-3.534 7.154-3.534 2.824 0 5.154.912 6.738 2.637C41.196 13.226 42 15.503 42 18.048c0 9.776-14.056 19.769-17 21.754Z" />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Tattoo &amp; piercing
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-271"
                                        data-qa="menu-item"
                                        aria-label="Makeup"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Makeup",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-271"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-271"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M10 16h10v2a2 2 0 0 0 2 2h.566l2.431 4.052a6 6 0 0 1 .823 3.715L23.9 46H6.1L4.18 27.767a6 6 0 0 1 .823-3.715L7.433 20H8a2 2 0 0 0 2-2v-2Zm2 4h6v-2h-6v2Zm-3.434 2-.891 1.485A1 1 0 0 0 8.532 25h12.936a1 1 0 0 0 .857-1.515L21.434 22H8.566Zm15.09 7.21A2 2 0 0 0 21.669 27H8.332a2 2 0 0 0-1.989 2.21l1.369 13A2 2 0 0 0 9.7 44h10.598a2 2 0 0 0 1.99-1.79l1.368-13ZM36 26a2 2 0 0 0-2-2h-3v-5.414l.828-.829A4 4 0 0 0 33 14.93V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v10.929a4 4 0 0 0 1.172 2.828l.828.829V24h-3a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2v10h-6V36a2 2 0 0 0 2-2v-8Zm-3-6.586V21a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-1.586l-.243-.242A6 6 0 0 1 39 14.929V4h-4v10.929a6 6 0 0 1-1.757 4.243l-.243.242ZM38 38v6h-2v-6h2Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Makeup
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-272"
                                        data-qa="menu-item"
                                        aria-label="Medical & dental"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Medical & dental",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-272"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-272"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path d="M29 46H19a2 2 0 0 1-2-2V33a2 2 0 0 0-2-2H4a2 2 0 0 1-2-2V19a2 2 0 0 1 2-2h11a2 2 0 0 0 2-2V4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H33a2 2 0 0 0-2 2v11a2 2 0 0 1-2 2ZM4 19v10h15v15h10V29h15V19H29V4H19v15H4Z" />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Medical &amp; dental
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        id="react-aria-273"
                                        data-qa="menu-item"
                                        aria-label="Counselling & holistic"
                                        className="fK-93n OGO9an"
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Counselling & holistic",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                      >
                                        <button
                                          id="button-react-aria-273"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-273"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="m24.5 4.56 3.144 3.26a8.189 8.189 0 0 1 2.244 4.852 9.285 9.285 0 0 1 3.183-.56H37.5v2.777c0 4.445-3.484 8.111-7.857 8.111H19.357c-4.373 0-7.857-3.666-7.857-8.111V12.11h4.429c1.115 0 2.187.198 3.184.56a8.189 8.189 0 0 1 2.243-4.851L24.5 4.56Zm-3.432 9.096a9.823 9.823 0 0 1 3.432 3.94 9.822 9.822 0 0 1 3.432-3.94 6.231 6.231 0 0 0-1.727-4.448L24.5 7.441l-1.704 1.767a6.232 6.232 0 0 0-1.728 4.448ZM23.44 21c-.31-2.55-1.787-4.713-3.841-5.902a7.296 7.296 0 0 0-3.67-.987H13.5v.778c0 3.41 2.656 6.111 5.857 6.111h4.083Zm2.12 0h4.083c3.2 0 5.857-2.701 5.857-6.111v-.778h-2.428a7.296 7.296 0 0 0-3.67.987C27.346 16.287 25.87 18.45 25.56 21Zm17.233 5.707a2.889 2.889 0 0 0-4.086 0L32.414 33H27.5v.5h-11v-2h9v-3a.5.5 0 0 0-.5-.5H11.914l-7 7L12 42.086l1.207-1.207A3 3 0 0 1 15.328 40h14.758l13-13-.293-.293ZM27.5 31h4.086l4-4h-2.258a1 1 0 0 0-.707.293L30.414 29.5H27.5V31Zm-.208-3.5A2.5 2.5 0 0 0 25 26H11.086l-9 9L12 44.914l2.621-2.621a1 1 0 0 1 .707-.293h15.586l15-15-1.707-1.707A4.89 4.89 0 0 0 37.613 25h-4.285a3 3 0 0 0-2.12.879L29.585 27.5h-2.294Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Counselling &amp; holistic
                                          </p>
                                        </div>
                                      </li>
                                      <li
                                        onClick={() => {
                                          setData((data) => ({
                                            ...data,
                                            category: "Fitness",
                                            search:""
                                          }));
                                          setTreatmentMobile(false);
                                        }}
                                        id="react-aria-274"
                                        data-qa="menu-item"
                                        aria-label="Fitness"
                                        className="fK-93n OGO9an"
                                      >
                                        <button
                                          id="button-react-aria-274"
                                          className="bXwNAZ _XdmmM eQ2tJn"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-react-aria-274"
                                          aria-live="polite"
                                        />
                                        <div className="E6mHfn">
                                          <div className="b6xJNc r4bkec _1WMFBc _0alsKn">
                                            <svg
                                              id="img"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 48 48"
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M11 41v-2h12.699c.975 0 1.373-1.254.576-1.817L2.953 22.133l3.68-3.681 1.057-2.956a12 12 0 0 1 5.347-6.383l4.385-2.506 4.247 8.496 5.868 1.035c-.086-1.014.173-1.97.476-2.706a8.845 8.845 0 0 1 1.084-1.9c.036-.048.106-.136.133-.17L30 12l.769.64a5.774 5.774 0 0 0-.301.418 6.83 6.83 0 0 0-.606 1.135c-.317.771-.46 1.58-.222 2.316l.503.089 6.68 12.023a5.999 5.999 0 0 0 2.561 2.453l4.405 2.202A4 4 0 0 1 46 36.854V41H11ZM6.886 21.028l.725-.725L32.683 38H44v1H32.222a6 6 0 0 1-3.46-1.098L7.016 22.552a1 1 0 0 1-.13-1.524ZM36.764 32l6.894 3.447c.21.105.342.319.342.553h-8.778a6 6 0 0 1-3.46-1.099L17.123 24.566a1 1 0 0 1 .098-1.694l1.49-.814-.957-1.755-3.311 1.806a.827.827 0 0 1-.798-1.45l1.958-1.087-.971-1.748-3.402 1.89a1 1 0 0 1-1.062-.057l-1.47-1.036.875-2.453a10 10 0 0 1 4.456-5.319l.714-.407a2 2 0 0 1 2.78.842l2.61 5.219L30.465 32h6.299Zm-2.43-3.738L35.3 30h-1.624a4 4 0 0 1-3.328-1.781L23.129 17.39l5.728 1.011.704 1.266-1.757.351.392 1.962 1.907-.382a.667.667 0 0 1 .261 1.308l-.56.112.392 1.962 1.607-.322a.667.667 0 0 1 .262 1.308l-.261.052.392 1.962 1.069-.214a1 1 0 0 1 1.07.495Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                        <div className="aHMJCn">
                                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                                            Fitness
                                          </p>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                  <li className="mC_J8n" aria-hidden="true">
                                    <hr
                                      aria-orientation="horizontal"
                                      className="KT-if1 CChMQ1 Sm3CD1 PiTGa1"
                                    />
                                  </li>
                                </ul>
                              </div>
                              <span
                                tabIndex={0}
                                style={{
                                  height: "0px",
                                  opacity: 0,
                                  pointerEvents: "none",
                                  position: "absolute",
                                  width: "0px",
                                  zIndex: -1,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TreatmentMobile;
