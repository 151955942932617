import React, { useEffect, useState } from "react";
import Section1 from "./Section1";
import Header from "../Header";
import Footer from "../Footer";
import TreatmentMobile from "../../shared/components/TreatmentMobile";
import SearchLocationMobile from "../../shared/components/SearchLocationMobile";
import DateMobile from "../../shared/components/DateMobile";
import TimeMobile from "../../shared/components/TimeMobile";
import { useSelector } from "react-redux";
import AuthModal from "../../components/AuthModal";
import StoreCards from "./StoreCards";
import moment from "moment";

const SearchResult = ({ showMenu, setShowMenu }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [treatmentMobile, setTreatmentMobile] = useState(false);
  const [locationMobile, setLocationMobile] = useState(false);
  const { store } = useSelector((store) => store?.store);
  const [dateMobile, setDateMobile] = useState(false);
  const [timeMobile, setTimeMobile] = useState(false);
  const [showCalander, setShowCalander] = useState(false);
  const [show, setShow] = useState(false);

  const [data, setData] = useState({
    search: "",
    category: "",
    time: "",
    date: moment(),
    location: "Karachi",
  });

  useEffect(() => {
    const handleGlobalMouseDown = (event) => {
      const menuParent = document.getElementById("calanderParent");
      if (
        showCalander &&
        event.target !== event.currentTarget &&
        (!menuParent || !menuParent.contains(event.target))
      ) {
        setShowCalander(!showCalander);
      }
    };

    document.addEventListener("mousedown", handleGlobalMouseDown);

    return () => {
      document.removeEventListener("mousedown", handleGlobalMouseDown);
    };
  }, [showCalander]);

  return (
    <>
      <div id="react" className="react--loading">
        <AuthModal show={show} setShow={setShow} />
        <div className="GXRSVM notranslate">
          <svg
            className="G2o70Q"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <linearGradient
              id="linearGradientAccent"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset={0} className="PkcZGQ" />
              <stop offset={1} className="WtaDyQ" />
            </linearGradient>
          </svg>

          <div className="_32iRow">
            <Header
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
            />

            <div className="L5Ntp6">
              {/* <div className="_4jKGSa">
                <div className="SWiJzX -cUJrF">
                  <div className="SWiJzX MQH7Px">
                    <span className="_2JTEU2 nkQSL5" />
                    <span className="_2JTEU2 rK-SRb" />
                  </div>
                </div>
              </div> */}
              <div className="lJh45t +RppSt">
                <Section1
                  data={data}
                  setData={setData}
                  showCalander={showCalander}
                  setShowCalander={setShowCalander}
                  treatmentMobile={treatmentMobile}
                  setTreatmentMobile={setTreatmentMobile}
                  locationMobile={locationMobile}
                  setLocationMobile={setLocationMobile}
                  dateMobile={dateMobile}
                  setDateMobile={setDateMobile}
                  timeMobile={timeMobile}
                  setTimeMobile={setTimeMobile}
                />
              </div>
              <div className="container searchHeight">
                <div className="row">
                  {store?.length > 0 ? <StoreCards store={store} /> : 
                  
                  <h3 className="fw-bold my-5 text-center">No results found related keyword</h3>
                  }
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>

      {treatmentMobile && (
        <TreatmentMobile
          data={data}
          setData={setData}
          setTreatmentMobile={setTreatmentMobile}
        />
      )}

      {locationMobile && (
        <SearchLocationMobile
          data={data}
          setData={setData}
          setLocationMobile={setLocationMobile}
        />
      )}

      {dateMobile && (
        <DateMobile
          data={data}
          setData={setData}
          setDateMobile={setDateMobile}
        />
      )}
      {timeMobile && (
        <TimeMobile
          data={data}
          setData={setData}
          setTimeMobile={setTimeMobile}
        />
      )}
    </>
  );
};

export default SearchResult;
