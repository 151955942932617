import axios from "axios";

const createBackendServer = (baseURL) => {
  const api = axios.create({
    baseURL: `${baseURL}api/`,
    withCredentials: true,
    headers: {
      Accept: "application/json",
    },
    timeout: 60 * 1000,
  });

  //Interceptor
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error?.response?.data?.message;
      error.message = message ?? error.message;
      if (error?.response?.data?.errors)
        error.errors = error?.response?.data?.errors;
      return Promise.reject(error);
    }
  );

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  /*==========    GET REQUESTS    ==========*/

  const getProfile = async (id) => await api.get(`/api/user/${id}`);
  /*==========    POST REQUESTS    ==========*/
  const authLogin = async (body) => api.post("auth", body);
  const authVerify = async (body) => api.post("auth/verify", body);
  const authRegister = (body) => api.post("auth/register", body);
  const authRegisterReferral = ({ body, code }) =>
    api.post(`auth/register?referralCode=${code}`, body);
  const authForget = async (body) => api.post("auth/forget", body);
  const updateUser = ({ id, body }) => api.put(`user/update/${id}`, body);
  const create_booking = async (body) => api.post("booking", body);
  const confirmBooking = async (body) => api.post(`booking/confirmed`, body);
  const giveRating = async (body) => api.post(`rating`, body);

  const authUpdatePassword = async (body) =>
    api.put("auth/update-password", body);

  const getStores = async (id) =>
    api.get(`/store?user_Id=${id}`);

  const getStoreDetails = async (id) => api.get(`store/slug/${id}?type=store`);
  const getUserBookings = async (id) => api.get(`booking/user/${id}`);
  const cancelBooking = async (body) => api.post(`booking/cancelled`, body);

  const getStoreSlot = ({ id, duration, staff_Id }) =>
    api.get(`store/slots/${id}?duration=${duration}&staff_Id=${staff_Id}`);

  const getStoreSlotwithoutStaff = ({ id, duration }) =>
    api.get(`store/slots/${id}?duration=${duration}
    `);
  const addFavorite = (body) => api.post(`user/favourite-add`, body);
  const searchSalon = (body) => api.post(`store/search`, body);
  const generateUserReferral = (id) => api.post(`user/referral/${id}`);
  const getUserReferral = (id) => api.get(`user/referral/${id}`);
  //Returning all the API
  return {
    getStoreSlot,
    getStoreDetails,
    getStores,
    authLogin,
    authVerify,
    authRegister,
    authRegisterReferral,
    authForget,
    authUpdatePassword,
    getProfile,
    create_booking,
    confirmBooking,
    getStoreSlotwithoutStaff,
    getUserBookings,
    cancelBooking,
    addFavorite,
    getUserReferral,
    updateUser,
    searchSalon,
    generateUserReferral,
    giveRating
  };
};

const apis = createBackendServer(process.env.REACT_APP_SERVER_URL);

export default apis;
