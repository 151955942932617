import React from "react";
import { Modal } from "react-bootstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";

const GalleryModal = ({ show, setShow }) => {
  return (
    <Modal size="lg" centered show={show} onHide={setShow}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Share on social media</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-evenly py-5">
          <FacebookShareButton
            url={"https://www.facebook.com/profile.php?id=61555036463248"}
          >
            <FaFacebook size={35} />
            <br /> Share on Facebook
          </FacebookShareButton>

          <TwitterShareButton url={"https://twitter.com/Beautowns1403"}>
            <FaTwitter size={35} />
            <br /> Tweet on Twitter
          </TwitterShareButton>
          <WhatsappShareButton url={"https://beautowns.com/"}>
            <FaWhatsapp size={35} />
            <br /> Share on Whatsapp
          </WhatsappShareButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default GalleryModal;
