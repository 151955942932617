import React, { useState, useRef, useEffect } from "react";
import { SiChatbot } from "react-icons/si";
import { IoIosSend } from "react-icons/io";
import { useLocation } from "react-router-dom";
import axios from "axios";
const ChatBot = ({ isChatOpen, setIsChatOpen }) => {
  const { pathname } = useLocation();
  const [chatResponse, setChatResponse] = useState([]);
  const [message, setMessage] = useState("");
  const chatHistoryRef = useRef(null);
  const sendMsg = async (e) => {
    e.preventDefault();
    try {
      setChatResponse((prevChatResponse) => [
        ...prevChatResponse,
        { message: message },
      ]);
      setMessage("");
      const response = await axios.post("http://127.0.0.1:5000/predict", {
        message: message,
      });
      setChatResponse((prevChatResponse) => [
        ...prevChatResponse,
        { answer: response?.data.answer },
      ]);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatResponse]);

  return (
    <div className={`${pathname === "/" ? "fixed-bottom" : "d-none"} `}>
      <div className="container fixedContainer">
        <div className="chatBotBtn " onClick={() => setIsChatOpen(!isChatOpen)}>
          <SiChatbot />
        </div>
        {isChatOpen && (
          <div className="chat-popup">
            <div className="chatHeader">Chat with Beautowns</div>
            <div
              className="msgsBox d-flex flex-column mb-1"
              ref={chatHistoryRef}
            >
              {chatResponse?.map((msg, index) => (
                <>
                  {msg?.message && (
                    <>
                      <div className="w-100">
                        <div
                          className="inputMessage text-wrap  my-0"
                          key={index}
                        >
                          {msg?.message}
                        </div>
                      </div>
                      <br />
                    </>
                  )}
                  {msg?.answer && (
                    <>
                      <div className="w-100">
                        <div className="responseMessage my-0 " key={index}>
                          {msg?.answer}
                        </div>
                      </div>
                      <br />
                    </>
                  )}
                </>
              ))}
            </div>
            <form onSubmit={sendMsg}>
              <div className="InputBox">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type a message"
                />
                <button type="submit">
                  <IoIosSend />
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatBot;
