import React from "react";
import { Navigation, Pagination, A11y } from "swiper";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import defaultSalonImage from "../../assets/images/images/defaultSalon.webp"
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Section3({ stores,heading }) {
  const navigate = useNavigate();
  return (
    <div className="cpj9Q+ _5-TPbG">
      <div className="p0zn2V _5-TPbG">
        <div className="_5HSwpe">
          <div className="_9-NqFo">
            <h2 className="_-wKgxq font-default-header-l-semibold mb-default-200x mb-laptop-300x">
              {heading}
            </h2>
          </div>
        </div>
        <div
          // id="carousel-spotlight-venues-recommended"
          className="ygl-Ox O+0La2"
          // style={{ itemWidthLarge: "calc((100% - (24px * 3)) / 4)" }}
        >
          <button
            id="button-carousel-spotlight-venues-new-left"
            data-qa="carousel-spotlight-venues-new-left"
            type="button"
            className="arrow-right0 bXwNAZ _XdmmM tPDowU _i2EgU p0rQDU _8oamEU _2qQHCU JKAulU MUDIZe RzW1Ue"
            tabIndex={-1}
            aria-pressed="false"
            aria-labelledby="label-carousel-spotlight-venues-new-left"
            aria-live="polite"
          >
            <span className="G9I_8U va37oU">
              <span
                id="label-carousel-spotlight-venues-new-left"
                className="_-wKgxq ZCvNLq font-default-body-m-semibold"
              >
                Previous
              </span>
            </span>
            <span className="_0f0EDU">
              <span className="_-6pBDG _8oamQG" aria-hidden="true">
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </span>
          </button>
          <div className="DNTEIx JE6Mgg">
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              breakpoints={{
                400: {
                  slidesPerView: 1,
                },
                600: {
                  slidesPerView: 2,
                },
                650: {
                  slidesPerView: 3,
                },
                900: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
                1280: {
                  slidesPerView: 4,
                },
                1600: {
                  slidesPerView: 4,
                },
              }}
              spaceBetween={20}
              navigation={{ nextEl: ".arrow-left0", prevEl: ".arrow-right0" }}
              scrollbar={{ draggable: true }}
              loop={true}
              pagination={false}
              // onSwiper={(swiper) => console.log(swiper)}
              // onSlideChange={() => }
            >
              {stores?.length > 0 &&
                stores?.map((salon) => (
                  <SwiperSlide>
                    <div
                      className="BbJlYx"
                      onClick={() => {
                        navigate(`/salon/${salon?.slug}`)
                      }}
                    >
                      <div
                        className="CLnQGg _XdmmM pzS8Sv OGOgmv M1UcMv whlC2v _6DdyDa IL3Tia _52Hhta"
                        id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii"
                      >

                        <div className="outerWrapper">
                          <img
                            src={salon?.image}
                            alt="SliderCuts"
                            className="img-fluid salonMainImage"
                            loading="lazy"
                          />
                        </div>
                        <div className="POY5Gv XXoKXv TIeclv hC0kLv">
                          <h3 className="_-wKgxq rfrYcq ZCvNLq font-default-header-xs-semibold mb-default-50x PlLb3a PlLb3a">
                            {salon?.name}
                          </h3>
                          <div
                            className="gQF-0- mb-default-50x"
                            id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii-rating-block"
                          >
                            <div className="aZpowB _-YxhLB">
                              <p
                                id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii-rating-block-aria"
                                className="qTsy4B"
                              >
                                4.9 rating with 2446 votes
                              </p>
                              <div className="ADCqxB _-YxhLB">
                                <span
                                  aria-hidden="true"
                                  className="_-wKgxq rfrYcq _9CRVlq font-default-body-xs-semibold"
                                >
                                  5
                                </span>
                                <div
                                  className="mKSgmD _-Yx7ND"
                                  aria-hidden="true"
                                >
                                  <span
                                    className="_-6pBDG Ua7BMG"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      fill="#fff"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                    </svg>
                                  </span>
                                </div>
                                <span
                                  aria-hidden="true"
                                  className="_-wKgxq rfrYcq font-default-body-xs-regular MRYiy_ _-YxE4_ MRYiy_ _-YxE4_"
                                >
                                  (0)
                                </span>
                              </div>
                            </div>
                          </div>
                          <p className="_-wKgxq u4xwZq ZCvNLq font-default-body-xs-regular mb-default-100">
                            {salon?.location}
                          </p>
                          <ul className="_5zCVQX gap-default-50x direction-default-horizontal display-default-inline-flex">
                            <li className="p_e4TX">
                              <div
                                aria-label="Barbershop"
                                className="Hs97ZQ _Sb9eQ OGOU7Q"
                              >
                                <span className="_-wKgxq rfrYcq ZCvNLq KfKHkq font-default-body-xs-medium">
                                  {salon?.city}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <button
            id="button-carousel-spotlight-venues-new-right"
            data-qa="carousel-spotlight-venues-new-right"
            type="button"
            className="arrow-left0 bXwNAZ _XdmmM tPDowU _i2EgU p0rQDU _8oamEU _2qQHCU JKAulU MUDIZe HLLgKe"
            tabIndex={-1}
            aria-pressed="false"
            aria-labelledby="label-carousel-spotlight-venues-new-right"
            aria-live="polite"
          >
            <span className="G9I_8U va37oU">
              <span
                id="label-carousel-spotlight-venues-new-right"
                className="_-wKgxq ZCvNLq font-default-body-m-semibold"
              >
                Next
              </span>
            </span>
            <span className="_0f0EDU">
              <span className="_-6pBDG _8oamQG" aria-hidden="true">
                <svg
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.97 4.72a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 0 1 0 1.06l-6.75 6.75a.75.75 0 1 1-1.06-1.06l5.47-5.47H3.75a.75.75 0 0 1 0-1.5h14.69l-5.47-5.47a.75.75 0 0 1 0-1.06Z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Section3;

// import React from "react";
// // import { Navigation, Pagination, A11y } from "swiper";
// import { useNavigate } from "react-router-dom";
// // import { Swiper, SwiperSlide } from "swiper/react";
// // import "swiper/css";
// // import "swiper/css/navigation";
// // import "swiper/css/pagination";
// // import "swiper/css/scrollbar";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

// function Section2({ stores }) {
//   const navigate = useNavigate();

//   const responsive = {
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 4,
//       slidesToSlide: 4, // optional, default to 1.
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2.5,
//       slidesToSlide: 2.5, // optional, default to 1.
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1.5,
//       slidesToSlide: 1.5, // optional, default to 1.
//     },
//   };
//   return (
//     <div className="cpj9Q+ _5-TPbG">
//       <div className="p0zn2V _5-TPbG">
//         <div className="_5HSwpe">
//           <div className="_9-NqFo">
//             <h2 className="_-wKgxq font-default-header-l-semibold mb-default-200x mb-laptop-300x">
//               Recommended
//             </h2>
//           </div>
//         </div>
//         <div
//           id="carousel-spotlight-venues-recommended"
//           className="ygl-Ox O+0La2"
//           style={{ itemWidthLarge: "calc((100% - (24px * 3)) / 4)" }}
//         >
//           {/* <button
//             id="button-carousel-spotlight-venues-new-left"
//             data-qa="carousel-spotlight-venues-new-left"
//             type="button"
//             className="arrow-right bXwNAZ _XdmmM tPDowU _i2EgU p0rQDU _8oamEU _2qQHCU JKAulU MUDIZe RzW1Ue"
//             tabIndex={-1}
//             aria-pressed="false"
//             aria-labelledby="label-carousel-spotlight-venues-new-left"
//             aria-live="polite"
//           >
//             <span className="G9I_8U va37oU">
//               <span
//                 id="label-carousel-spotlight-venues-new-left"
//                 className="_-wKgxq ZCvNLq font-default-body-m-semibold"
//               >
//                 Previous
//               </span>
//             </span>
//             <span className="_0f0EDU">
//               <span className="_-6pBDG _8oamQG" aria-hidden="true">
//                 <svg
//                   fill="currentColor"
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fillRule="evenodd"
//                     d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
//                     clipRule="evenodd"
//                   />
//                 </svg>
//               </span>
//             </span>
//           </button> */}
//           {/* <div className="DNTEIx JE6Mgg"> */}
//             <Carousel
//               swipeable={true}
//               draggable={false}
//               showDots={false}
//               responsive={responsive}
//               ssr={true}
//               infinite={true}
//               // autoPlay={this.props.deviceType !== "mobile" ? true : false}
//               autoPlay={false}
//               autoPlaySpeed={1000}
//               keyBoardControl={true}
//               customTransition="all .9"
//               transitionDuration={1000}
//               containerClass="carousel-container"
//               removeArrowOnDeviceType={["tablet", "mobile"]}
//             deviceType={
//                 window.innerWidth < 576? "mobile" : window.innerWidth > 1200? "desktop" : "tablet"
//               }
//               dotListClass="custom-dot-list-style"
//               itemClass="carousel-item-padding-40-px"
//             >
//               {stores?.length > 0 &&
//                 stores?.map((salon) => (
//                   <div
//                   className="BbJlYx"
//                   onClick={() => {
//                     navigate(`/salon`, { state: { salon: salon } });
//                   }}
//                 >
//                   <div
//                     className="CLnQGg _XdmmM pzS8Sv OGOgmv M1UcMv whlC2v _6DdyDa IL3Tia _52Hhta"
//                     id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii"
//                   >
//                     <div className="outerWrapper">
//                       <img
//                         src={salon?.image}
//                         alt="SliderCuts"
//                         className="img-fluid salonMainImage"
//                         loading="lazy"
//                       />
//                     </div>
//                     <div className="POY5Gv XXoKXv TIeclv hC0kLv">
//                       <h3 className="_-wKgxq rfrYcq ZCvNLq font-default-header-xs-semibold mb-default-50x PlLb3a PlLb3a">
//                         {salon?.name}
//                       </h3>
//                       <div
//                         className="gQF-0- mb-default-50x"
//                         id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii-rating-block"
//                       >
//                         <div className="aZpowB _-YxhLB">
//                           <p
//                             id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii-rating-block-aria"
//                             className="qTsy4B"
//                           >
//                             4.9 rating with 2446 votes
//                           </p>
//                           <div className="ADCqxB _-YxhLB">
//                             <span
//                               aria-hidden="true"
//                               className="_-wKgxq rfrYcq _9CRVlq font-default-body-xs-semibold"
//                             >
//                               4.9
//                             </span>
//                             <div
//                               className="mKSgmD _-Yx7ND"
//                               aria-hidden="true"
//                             >
//                               <span
//                                 className="_-6pBDG Ua7BMG"
//                                 aria-hidden="true"
//                               >
//                                 <svg
//                                   fill="currentColor"
//                                   xmlns="http://www.w3.org/2000/svg"
//                                   viewBox="0 0 24 24"
//                                 >
//                                   <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
//                                 </svg>
//                               </span>
//                             </div>
//                             <span
//                               aria-hidden="true"
//                               className="_-wKgxq rfrYcq font-default-body-xs-regular MRYiy_ _-YxE4_ MRYiy_ _-YxE4_"
//                             >
//                               (2,446)
//                             </span>
//                           </div>
//                         </div>
//                       </div>
//                       <p className="_-wKgxq u4xwZq ZCvNLq font-default-body-xs-regular mb-default-100">
//                         {salon?.location}
//                       </p>
//                       <ul className="_5zCVQX gap-default-50x direction-default-horizontal display-default-inline-flex">
//                         <li className="p_e4TX">
//                           <div
//                             aria-label="Barbershop"
//                             className="Hs97ZQ _Sb9eQ OGOU7Q"
//                           >
//                             <span className="_-wKgxq rfrYcq ZCvNLq KfKHkq font-default-body-xs-medium">
//                               {salon?.city}
//                             </span>
//                           </div>
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//                 ))}
//             </Carousel>
            
//           {/* </div> */}
//           {/* <button
//             id="button-carousel-spotlight-venues-new-right"
//             data-qa="carousel-spotlight-venues-new-right"
//             type="button"
//             className="arrow-left bXwNAZ _XdmmM tPDowU _i2EgU p0rQDU _8oamEU _2qQHCU JKAulU MUDIZe HLLgKe"
//             tabIndex={-1}
//             aria-pressed="false"
//             aria-labelledby="label-carousel-spotlight-venues-new-right"
//             aria-live="polite"
//           >
//             <span className="G9I_8U va37oU">
//               <span
//                 id="label-carousel-spotlight-venues-new-right"
//                 className="_-wKgxq ZCvNLq font-default-body-m-semibold"
//               >
//                 Next
//               </span>
//             </span>
//             <span className="_0f0EDU">
//               <span className="_-6pBDG _8oamQG" aria-hidden="true">
//                 <svg
//                   fill="currentColor"
//                   xmlns="http://www.w3.org/2000/svg"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     fillRule="evenodd"
//                     d="M12.97 4.72a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 0 1 0 1.06l-6.75 6.75a.75.75 0 1 1-1.06-1.06l5.47-5.47H3.75a.75.75 0 0 1 0-1.5h14.69l-5.47-5.47a.75.75 0 0 1 0-1.06Z"
//                     clipRule="evenodd"
//                   />
//                 </svg>
//               </span>
//             </span>
//           </button> */}
//         </div>
//       </div>

     
//     </div>
//   );
// }

// export default Section2;
