import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apis from "../../services";
import toast from "react-hot-toast";
import AuthModal from "../../components/AuthModal";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { setStep } from "../../redux/slices/serviceSlice";
import { StepBar } from "../StepBar";
import { BeatLoader } from "react-spinners";

const AppointmentStepSix = () => {
  const { services, staff, time, date } = useSelector((e) => e?.service);
  const { user } = useSelector((e) => e);
  const { store } = useSelector((e) => e?.store?.storeDetails);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [staffImageError, setStaffImageError] = useState(false);
  useEffect(() => {
    dispatch(setStep(4));
    if (!services?.length > 0 || !time || !date) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const totalAmount = services?.reduce((acc, obj) => {
    const valueToAdd = obj?.value;
    if (typeof valueToAdd === "number") {
      return acc + valueToAdd;
    } else {
      return acc;
    }
  }, 0);


  

  const serviceIds = services?.map((e) => e?._id) || [];
  const navigate = useNavigate();

  const { mutate: payment, isLoading: isConfirming } = useMutation(
    apis.confirmBooking,
    {
      onError: function ({ message }) {
        console.log(message, "message");
        toast.error(message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          // console.log(user1, "done payment");
          toast.success("Booking Created Successfully", { id: 1 });
          // toast.success(user1?.message, { id: 1 });
          navigate("/");
        }
      },
    }
  );

  const { mutate, isLoading: isBookingCreating } = useMutation(
    () =>
      apis.create_booking({
        booking_type: "auto",
        user_Id: user?.user?._id,
        store_Id: store?._id,
        service_Ids: serviceIds,
        time: time,
        date: date,
        staff_Id: staff?._id,
        // ...data,
      }),
    {
      onError: function ({ message }) {
        toast.error(message, { id: 1 });
      },
      onSuccess: async ({ data: bookings, status }) => {
        if (status === 201) {
          console.log(bookings, "bookings");
          await payment({
            booking_Id: bookings?.booking?._id,
            user_Id: user?.user?._id,
          });
        }
      },
    }
  );

  return (
    <div id="react" className="react--loading">
      <AuthModal show={show} setShow={setShow} />

      <div className="GXRSVM notranslate">
    
        <div className="mre0Hq w+g1+Q" featureflags="[object Object]">
          <div className="_32iRow">
            <div className="Df5Lk6">
              <div className="E4hVuT JdS1yT xa-rvT">
                <div className="_7nJ-pT JdS1yT">
                  <div className="wwqFAT JdS1yT">
                    <button
                      onClick={() => navigate(`/salon/select-time`)}
                      id="button-header-flow-back"
                      data-qa="header-flow-back"
                      className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU DetaJT"
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="label-header-flow-back"
                      aria-live="polite"
                    >
                      <span className="G9I_8U va37oU">
                        <span
                          id="label-header-flow-back"
                          className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                        >
                          Go back
                        </span>
                      </span>
                      <span className="_0f0EDU">
                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className="p1pdhT JdS1yT">
                    <span className="_-wKhBx rfrUux Bnx6rx dUG8gx ZCvJqx KfKZXx font-default-header-s-semibold font-tablet-header-s-semibold font-laptop-header-s-semibold font-desktop-header-s-semibold _zngzT">
                      Review and confirm
                    </span>
                  </div>
                  <div className="wwqFAT ibymkT">
                    <button
                      onClick={() => navigate(`/salon/${store?.slug}`)}
                      id="button-close-button"
                      data-qa="close-button"
                      className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU"
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="label-close-button"
                      aria-live="polite"
                    >
                      <span className="G9I_8U va37oU">
                        <span
                          id="label-close-button"
                          className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                        >
                          Close
                        </span>
                      </span>
                      <span className="_0f0EDU">
                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="YAjmY7 NekGj7">
                <div className="MZ1PW7" />
                <div className="otg7W7 NekGj7 tUf8c7">
                  <div className="iWARt7">
                    <div className="display-default-none display-laptop-flex">
                      <div data-qa="header-title" className="dYmf3l w-100 ">
                      <div className="container my-5">
                          <div className="col-11 mx-auto">
                            <StepBar />
                          </div>
                        </div>
                        <div className="fWGqQa fwrHUa _4zQoha Aocp_a gap-default-200x fWGqQa">
                          <h1
                            className="_-wKhBx rfrUux font-default-header-l-semibold font-tablet-header-xl-bold font-laptop-header-xl-bold font-desktop-header-xl-bold"
                            style={{ flexGrow: 1 }}
                          >
                            Review and confirm
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="display-default-flex display-laptop-none">
                      <div className="dYmf3l OZAYBl w-100">
                      <div className="container mt-3 mb-5">
                          <div className="col-11 mx-auto">
                            <StepBar />
                          </div>
                        </div>
                        <div className="fWGqQa fwrHUa _4zQoha Aocp_a gap-default-200x fWGqQa">
                          <h1
                            className="_-wKhBx rfrUux font-default-header-l-semibold font-tablet-header-xl-bold font-laptop-header-xl-bold font-desktop-header-xl-bold"
                            style={{ flexGrow: 1 }}
                          >
                            Review and confirm
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gbuHog Nekhlg aBzANg XHPkvf">
                    <div className="display-default-flex display-laptop-none _8pSV4W">
                      <div
                        data-qa="cart-mobile"
                        className="_5zCVQX gap-default-400x direction-default-vertical display-default-inline-flex"
                      >
                        <div className="hB0YAs">
                          <div className="CLnQGg _XdmmM">
                            <div className="b6xJNc J0Qdgc GmK7bc W-cbgF">
                              <picture className="rC5TQo _-rOGgo oN-sZo gsRUJF GmKi4F">
                                <img
                                  src={store?.image}
                                  alt={store?.name}
                                  className="N3bmGo _-rOGgo p0zJqo gsRUJF GmKi4F object-fit-cover "
                                  loading="lazy"
                                />
                              </picture>
                            </div>
                          </div>
                          <div
                            className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa"
                            style={{ minWidth: "0px" }}
                          >
                            <p className="_-wKhBx font-default-body-s-semibold">
                              {store?.name}
                            </p>
                            <p className="_-wKhBx u4x-xx ZCvJqx KfKZXx font-default-body-s-regular">
                              {store?.location}
                            </p>
                          </div>
                        </div>
                        <ul className="_5zCVQX gap-default-250x direction-default-vertical display-default-flex">
                          <div className="yhiMeE EuGs2E">
                            <div className="_6lhOdE">
                              <div className="H3QpUE">
                                <div className="_2GGq-E">
                                  <span
                                    className="_-6pwFd s_TIMd"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.5 1.5a.75.75 0 0 1 .75.75V3h7.5v-.75a.75.75 0 0 1 1.5 0V3h2.25A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3h2.25v-.75a.75.75 0 0 1 .75-.75Zm-.75 3H4.5v3h15v-3h-2.25v.75a.75.75 0 0 1-1.5 0V4.5h-7.5v.75a.75.75 0 0 1-1.5 0V4.5ZM19.5 9h-15v10.5h15V9Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </div>
                              <div className="_002iGE">
                                <div className="_nTKkE">
                                  <div className="_2rJeYE">
                                    <p className="_-wKhBx font-default-body-m-medium mb-default-25x">
                                      {moment(date).format("dddd D MMMM")}
                                    </p>
                                    <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                                      {time}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 pz1vF1"
                          />
                          {!staff ? (
                            <div className="yhiMeE EuGs2E">
                              <div className="_6lhOdE">
                                <div className="H3QpUE">
                                  <div className="_2GGq-E">
                                    <span
                                      className="_-6pwFd s_TIMd"
                                      aria-hidden="true"
                                    >
                                      <svg
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M12 3.75a5.25 5.25 0 0 0-.078 10.5h.156A5.25 5.25 0 0 0 12 3.75Zm3.463 11.045a6.75 6.75 0 1 0-6.925 0 11.25 11.25 0 0 0-6.281 5.08.75.75 0 1 0 1.299.75 9.75 9.75 0 0 1 16.888 0 .75.75 0 1 0 1.3-.75 11.25 11.25 0 0 0-6.281-5.08Z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                </div>
                                <div className="_002iGE">
                                  <div className="_nTKkE">
                                    <div className="_2rJeYE">
                                      <p className="_-wKhBx font-default-body-m-medium mb-default-25x">
                                        Any professional
                                      </p>
                                      <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                                        Assigned on the day
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              data-qa="cart-employee-info"
                              className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex lx2Dn1 mt-2"
                            >
                              <div
                                className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="b6xJNc _SbjAc _1WMFBc _0alsKn">
                                {staffImageError ? (
                                    <div className="noStaff">{staff?.name?.charAt(0).toUpperCase()}</div>
                                  ) : (
                                    <img
                                      src={staff?.image}
                                      className="img-fluid object-fit-cover "
                                      style={{ height: "100%" }}
                                      alt=""
                                      onError={() => {
                                        setStaffImageError(true);
                                      }}
                                    />
                                  )}
                                </div>
                                <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-first-name"
                                      className="_-wKhBx font-default-body-s-medium"
                                    >
                                      {staff?.name}
                                    </p>
                                  </li>
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-title"
                                      className="_-wKhBx u4x-xx font-default-body-s-regular"
                                    >
                                      {staff?.title}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 pz1vF1"
                          />
                        </ul>
                        <div>
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Overview
                          </p>
                          <ul className="_5zCVQX gap-default-100 direction-default-vertical display-default-flex">
                            {services?.length > 0 &&
                              services?.map((e, i) => (
                                <li className="p_e4TX" key={i}>
                                  <div
                                    data-qa="booking-info-service-colour-consultation"
                                    className="yhiMeE EuGs2E"
                                  >
                                    <div className="_6lhOdE">
                                      <div className="_002iGE">
                                        <div className="_nTKkE">
                                          <div className="_2rJeYE">
                                            <p
                                              data-qa="list-item-name"
                                              className="_-wKhBx font-default-body-s-medium"
                                            >
                                              {e?.name}
                                            </p>
                                            <p
                                              data-qa="list-item-description"
                                              className="_-wKhBx u4x-xx font-default-body-s-regular"
                                            >
                                              {e?.duration} min
                                            </p>
                                          </div>
                                          <div className="AzPtSE beZrJE">
                                            <div className="Yer71q">
                                              <p
                                                data-qa="list-item-retail-price"
                                                className="_-wKhBx rfrUux font-default-body-s-medium"
                                              >
                                                {e?.value} PKR
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 xaSMJ1"
                          />
                          <div className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa">
                            <div className="yhiMeE EuGs2E">
                              <div className="_6lhOdE">
                                <div className="_002iGE">
                                  <div className="_nTKkE">
                                    <div className="_2rJeYE">
                                      <p className="_-wKhBx font-default-body-m-semibold">
                                        Total
                                      </p>
                                    </div>
                                    <div className="AzPtSE beZrJE">
                                      <p
                                        data-qa="total-price"
                                        className="_-wKhBx font-default-body-m-semibold"
                                      >
                                        {totalAmount} PKR
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 PiTGa1 _5sbkH9"
                          />
                          <div className="yhiMeE EuGs2E">
                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <div className="_nTKkE">
                                  <div className="_2rJeYE">
                                    <p
                                      data-qa="pay-now"
                                      className="_-wKhBx hHHBix font-default-body-m-semibold"
                                    >
                                      Pay now
                                    </p>
                                  </div>
                                  <div className="AzPtSE beZrJE">
                                    <p
                                      data-qa="pay-now-value"
                                      className="_-wKhBx hHHBix font-default-body-m-semibold"
                                    >
                                      0 PKR
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="yhiMeE EuGs2E">
                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <div className="_nTKkE">
                                  <div className="_2rJeYE">
                                    <p
                                      data-qa="pay-at-venue"
                                      className="_-wKhBx u4x-xx font-default-body-s-regular"
                                    >
                                      Pay at salon
                                    </p>
                                  </div>
                                  <div className="AzPtSE beZrJE">
                                    <p
                                      data-qa="pay-at-venue-value"
                                      className="_-wKhBx u4x-xx font-default-body-s-regular"
                                    >
                                      {totalAmount} PKR
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fWGqQa R-waSa Aocp_a EKpM7a w2KJka UNoRIa cAWGFa gap-default-400x fWGqQa"
                      style={{
                        paddingTopLaptop: 0,
                        paddingRightLaptop: 0,
                        paddingBottomLaptop: "32px",
                        paddingLeftLaptop: 0,
                      }}
                    >
                      {/* <div>
                        <p className="_-wKhBx font-default-header-s-semibold mb-default-50x">
                          Payment method
                        </p>
                        <p className="_-wKhBx font-default-body-s-regular mb-default-250x">
                          You won't be charged now, payment will be collected in
                          store after your appointment
                        </p>
                        <form
                          action="#"
                          className="F+XEMp"
                          noValidate
                          data-qa="form-create-bank-card"
                          name="createBankCard"
                        >
                          <div className="lX+p9c mICLfx">
                            <div className="okbIaU">
                              <div>
                                <div className="fWGqQa R-waSa Aocp_a gap-default-400x fWGqQa">
                                  <div className="e1Ad_2">
                                    <div className="_8S-Vy2">
                                      <div
                                        data-qa="card-holder-name"
                                        className="upDE_A"
                                      >
                                        <label
                                          data-qa="card-holder-name-label"
                                          className="Do86vA"
                                          htmlFor="input-react-aria-101"
                                        >
                                          <span className="_-wKhBx rfrUux font-default-body-s-semibold">
                                            Name on card
                                          </span>
                                        </label>
                                        <div
                                          className="fWGqQa lfxdea Aocp_a gap-default-100 fWGqQa OlOvza"
                                          style={{ growDefault: 1 }}
                                        >
                                          <div
                                            data-qa="input-structure-card-holder-name"
                                            id="react-aria-101"
                                            className="_XdmmM _6OnrLD xa-H1D OGOJOD"
                                          >
                                            <input
                                              name="cardHolderName"
                                              placeholder="Add card holder full name"
                                              autoComplete="cc-name"
                                              id="input-react-aria-101"
                                              className="Wa7fyD"
                                              type="text"
                                              data-qa="input-input-structure-card-holder-name"
                                              defaultValue
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="upDE_A">
                                        <label
                                          className="Do86vA"
                                          htmlFor="input-react-aria-102"
                                        >
                                          <span className="_-wKhBx rfrUux font-default-body-s-semibold">
                                            Card number
                                          </span>
                                        </label>
                                        <div
                                          id="react-aria-102"
                                          className="_XdmmM _6OnrLD xa-H1D OGOJOD"
                                        >
                                          <span
                                            name="encryptedCardNumber"
                                            autoComplete="cc-number"
                                            value
                                            data-cse="encryptedCardNumber"
                                            id="input-react-aria-102"
                                            className="Wa7fyD"
                                            type="text"
                                          >
                                            <iframe
                                              src="https://checkoutshopper-live.adyen.com/checkoutshopper/securedfields/live_H2KWQT4UPZCB3NMFW3YXA534BIYCNRII/4.5.1/securedFields.html?type=card&d=aHR0cHM6Ly93d3cuZnJlc2hhLmNvbQ=="
                                              className="js-iframe"
                                              title="Iframe for card number"
                                              allowTransparency="true"
                                              style={{
                                                border: "none",
                                                height: "100%",
                                                width: "100%",
                                                overflow: "hidden",
                                              }}
                                              referrerPolicy="origin"
                                            >
                                              &lt;p&gt;Your browser does not
                                              support iframes.&lt;/p&gt;
                                            </iframe>
                                          </span>
                                          <div className="_1h-qMD xa-H1D OGOJOD">
                                            <picture className="rC5TQo aDoOGo Qs8P0o Fr8r8D">
                                              <img
                                                src="//www.fresha.com/assets/static/media/illustration-card-empty-light-3550f0eae166ebace213ed4096bdc58e.svg"
                                                alt="Blank card image"
                                                className="N3bmGo aDoOGo Fr8r8D"
                                                loading="eager"
                                              />
                                            </picture>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="fWGqQa _75L7da _4zQoha Aocp_a gap-default-200x fWGqQa">
                                        <div className="upDE_A">
                                          <label
                                            className="Do86vA"
                                            htmlFor="input-react-aria-103"
                                          >
                                            <span className="_-wKhBx rfrUux font-default-body-s-semibold">
                                              Expiry date
                                            </span>
                                          </label>
                                          <div
                                            id="react-aria-103"
                                            className="_XdmmM _6OnrLD xa-H1D OGOJOD"
                                          >
                                            <span
                                              name="encryptedExpiryDate"
                                              autoComplete="cc-exp"
                                              value="[object Object]"
                                              data-cse="encryptedExpiryDate"
                                              id="input-react-aria-103"
                                              className="Wa7fyD"
                                              type="text"
                                            >
                                              <iframe
                                                src="https://checkoutshopper-live.adyen.com/checkoutshopper/securedfields/live_H2KWQT4UPZCB3NMFW3YXA534BIYCNRII/4.5.1/securedFields.html?type=card&d=aHR0cHM6Ly93d3cuZnJlc2hhLmNvbQ=="
                                                className="js-iframe"
                                                title="Iframe for expiry date"
                                                allowTransparency="true"
                                                style={{
                                                  border: "none",
                                                  height: "100%",
                                                  width: "100%",
                                                  overflow: "hidden",
                                                }}
                                                referrerPolicy="origin"
                                              >
                                                &lt;p&gt;Your browser does not
                                                support iframes.&lt;/p&gt;
                                              </iframe>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="upDE_A">
                                          <label
                                            className="Do86vA"
                                            htmlFor="input-react-aria-104"
                                          >
                                            <span className="_-wKhBx rfrUux font-default-body-s-semibold">
                                              Security code
                                            </span>
                                          </label>
                                          <div
                                            id="react-aria-104"
                                            className="_XdmmM _6OnrLD xa-H1D OGOJOD"
                                          >
                                            <span
                                              name="encryptedSecurityCode"
                                              autoComplete="cc-csc"
                                              value
                                              data-cse="encryptedSecurityCode"
                                              id="input-react-aria-104"
                                              className="Wa7fyD"
                                              type="text"
                                            >
                                              <iframe
                                                src="https://checkoutshopper-live.adyen.com/checkoutshopper/securedfields/live_H2KWQT4UPZCB3NMFW3YXA534BIYCNRII/4.5.1/securedFields.html?type=card&d=aHR0cHM6Ly93d3cuZnJlc2hhLmNvbQ=="
                                                className="js-iframe"
                                                title="Iframe for security code"
                                                allowTransparency="true"
                                                style={{
                                                  border: "none",
                                                  height: "100%",
                                                  width: "100%",
                                                  overflow: "hidden",
                                                }}
                                                referrerPolicy="origin"
                                              >
                                                &lt;p&gt;Your browser does not
                                                support iframes.&lt;/p&gt;
                                              </iframe>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    data-qa="payment-providers-qa"
                                    className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex qQC2eI"
                                  >
                                    <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                                      Pay securely with
                                    </p>
                                    <div className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex">
                                      <picture className="rC5TQo aDoOGo Qs8P0o _9cltII">
                                        <img
                                          src="//www.fresha.com/assets/static/media/illustration-visa-light-2aaeb6d1f67f4c29240908333183465b.svg"
                                          alt="Visa payment brand"
                                          className="N3bmGo aDoOGo _9cltII"
                                          loading="eager"
                                        />
                                      </picture>
                                      <picture className="rC5TQo aDoOGo Qs8P0o _9cltII">
                                        <img
                                          src="//www.fresha.com/assets/static/media/illustration-mastercard-light-c222da8700dfd22eb40cd55515a9dcb1.svg"
                                          alt="Mastercard payment brand"
                                          className="N3bmGo aDoOGo _9cltII"
                                          loading="eager"
                                        />
                                      </picture>
                                      <picture className="rC5TQo aDoOGo Qs8P0o _9cltII">
                                        <img
                                          src="//www.fresha.com/assets/static/media/illustration-amex-light-ac4e78cc2f6b83d9ee9c0e400944f463.svg"
                                          alt="Amex payment brand"
                                          className="N3bmGo aDoOGo _9cltII"
                                          loading="eager"
                                        />
                                      </picture>
                                      <picture className="rC5TQo aDoOGo Qs8P0o _9cltII">
                                        <img
                                          src="//www.fresha.com/assets/static/media/illustration-discover-light-427ab12b7600f3b8fe6af85ed5d09e97.svg"
                                          alt="Discover payment brand"
                                          className="N3bmGo aDoOGo _9cltII"
                                          loading="eager"
                                        />
                                      </picture>
                                      <picture className="rC5TQo aDoOGo Qs8P0o _9cltII">
                                        <img
                                          src="//www.fresha.com/assets/static/media/illustration-dinners-club-light-60f8600e40874db255936b6549b81981.svg"
                                          alt="Dinners payment brand"
                                          className="N3bmGo aDoOGo _9cltII"
                                          loading="eager"
                                        />
                                      </picture>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="L3D2fr"
                              data-qa="loader-container"
                            />
                          </div>
                        </form>
                        <form
                          action="#"
                          className="F+XEMp"
                          noValidate
                          data-qa="form-create-bank-card"
                          name="createBankCard"
                        />
                      </div> */}
                      <div>
                        <p className="_-wKhBx fs-3 fw-bold mb-4">
                          Pay cash at salon
                        </p>
                        <p className="_-wKhBx font-default-header-s-semibold mb-default-25x">
                          Cancellation policy
                        </p>
                        <p className="_-wKhBx font-default-body-s-regular">
                          <span>
                            Cancel for free up to <strong>48 hours</strong>{" "}
                            ahead, otherwise you will be charged{" "}
                            <strong>50%</strong> of the service price for late
                            cancellation or <strong>100%</strong> for not
                            showing up.
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className="_-wKhBx font-default-header-s-semibold mb-default-25x">
                          Important info
                        </p>
                        <p className="_-wKhBx font-default-body-s-regular">
                          <span>
                            For all enquiries, call: +{store?.phone}. Please
                            arrive at least 30 minutes before the start time of
                            your appointment. This helps to run our services to
                            time and reduces impact on the next client. <br />
                            We will always do our best to accommodate late
                            arrivals but arriving later than 15 minutes may
                            result in a significantly reduced service or a 100%
                            no-show fee.
                            <br />
                            Please take note of our cancellation policy you
                            agree to at the time of booking. You may reschedule
                            or cancel your appointment for free, up to 48 hours
                            before your appointment. Within 48 hours of your
                            appointment, there is a 50% fee to reschedule or
                            cancel. Not showing up for an appointment incurs a
                            100% fee. These charges ensure your
                            stylist/therapist is compensated for their loss.{" "}
                            <br />
                            Your card details are stored securely by a 3rd Party
                            Provider and may be used to take payment for
                            services. If you wish to use an alternative payment
                            method, please notify the Reception Manager on
                            arrival.
                          </span>
                        </p>
                      </div>
                      <div className="display-default-none display-laptop-block">
                        <p className="_-wKhBx font-default-header-s-semibold mb-default-200x">
                          Booking notes
                        </p>
                        <div data-qa="booking-notes-input" className="upDE_A">
                          <label
                            data-qa="booking-notes-input-label"
                            className="Do86vA X2Xg2A"
                            htmlFor="input-react-aria-105"
                          >
                            <span className="_-wKhBx rfrUux font-default-body-s-semibold">
                              Booking notes
                            </span>
                          </label>
                          <div
                            data-qa="input-structure-booking-notes-input"
                            aria-hidden="true"
                            id="react-aria-105"
                            className="_XdmmM _6OnrLD xa-H1D OGOJOD _kginD"
                          >
                            <textarea
                              placeholder="Include comments or requests about your booking"
                              id="input-react-aria-105"
                              className="Wa7fyD"
                              type="text"
                              data-qa="input-input-structure-booking-notes-input"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="QrHNxj Aqljbj UuHXUj NekZnj aBz4Xj">
                    <div
                      id="booking-flow-cart"
                      data-qa="cart"
                      className="pzS8Sv OGOgmv Y5Bsdv whlC2v _10Cwnv Mnqemv PqhSRN"
                    >
                      <div className="h0MWJS">
                        <div className="POY5Gv XXoKXv BSVEBv dTkYBv WfxCyR">
                          <div className="hB0YAs">
                            <div className="CLnQGg _XdmmM">
                              <div className="b6xJNc J0Qdgc GmK7bc W-cbgF">
                                <picture className="rC5TQo _-rOGgo oN-sZo gsRUJF GmKi4F">
                                  <img
                                    src={store?.image}
                                    alt={store?.name}
                                    className="N3bmGo _-rOGgo p0zJqo gsRUJF GmKi4F object-fit-cover "
                                    loading="lazy"
                                  />
                                </picture>
                              </div>
                            </div>
                            <div
                              className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa"
                              style={{ minWidth: "0px" }}
                            >
                              <p className="_-wKhBx font-default-body-s-semibold">
                                {store?.name}
                              </p>
                              <div
                                className="gQF-0-"
                                id="rating"
                                data-qa="rating"
                              >
                                <div className="aZptzi I-8Vzi">
                                  <p id="rating-aria" className="qTs2Ai">
                                    5 rating
                                  </p>
                                  <div className="ADCqki I-8Vzi">
                                    <span
                                      aria-hidden="true"
                                      className="_-wKhBx rfrUux _9CRQ7x font-default-body-s-semibold"
                                    >
                                      5.0
                                    </span>
                                    <div
                                      className="mKS7Nt I-8Lrt"
                                      aria-hidden="true"
                                    >
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                    </div>
                                    {/* <span
                                      aria-hidden="true"
                                      className="_-wKhBx rfrUux font-default-body-s-regular MRYHBB I-8UmB"
                                    >
                                      (1,688)
                                    </span> */}
                                  </div>
                                </div>
                              </div>
                              <p className="_-wKhBx u4x-xx ZCvJqx KfKZXx font-default-body-s-regular">
                                {store?.location}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv ZRg0kv _8WTxMv zsU6-w">
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Overview
                          </p>
                          <ul className="_5zCVQX gap-default-100 direction-default-vertical display-default-flex">
                            {services?.length > 0 &&
                              services?.map((e, i) => (
                                <li className="p_e4TX" key={i}>
                                  <div
                                    data-qa="booking-info-service-colour-consultation"
                                    className="yhiMeE EuGs2E"
                                  >
                                    <div className="_6lhOdE">
                                      <div className="_002iGE">
                                        <div className="_nTKkE">
                                          <div className="_2rJeYE">
                                            <p
                                              data-qa="list-item-name"
                                              className="_-wKhBx font-default-body-s-medium"
                                            >
                                              {e?.name}
                                            </p>
                                            <p
                                              data-qa="list-item-description"
                                              className="_-wKhBx u4x-xx font-default-body-s-regular"
                                            >
                                              {e?.duration} min
                                            </p>
                                          </div>
                                          <div className="AzPtSE beZrJE">
                                            <div className="Yer71q">
                                              <p
                                                data-qa="list-item-retail-price"
                                                className="_-wKhBx rfrUux font-default-body-s-medium"
                                              >
                                                {e?.value} PKR
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </ul>
                        </div>
                        <div className="POY5Gv XXoKXv c0imxv _8WTxMv oEA38h">
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 pz1vF1 oM6NSq"
                          />
                          <div className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa">
                            <div className="yhiMeE EuGs2E">
                              <div className="_6lhOdE">
                                <div className="_002iGE">
                                  <div className="_nTKkE">
                                    <div className="_2rJeYE">
                                      <p className="_-wKhBx font-default-body-m-semibold">
                                        Total
                                      </p>
                                    </div>
                                    <div className="AzPtSE beZrJE">
                                      <p
                                        data-qa="total-price"
                                        className="_-wKhBx font-default-body-m-semibold"
                                      >
                                        {totalAmount} PKR
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv c0imxv _8WTxMv zoUAks">
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 pz1vF1 _5sbkH9"
                          />
                          <div className="yhiMeE EuGs2E">
                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <div className="_nTKkE">
                                  <div className="_2rJeYE">
                                    <p
                                      data-qa="pay-now"
                                      className="_-wKhBx hHHBix font-default-body-m-semibold"
                                    >
                                      Pay now
                                    </p>
                                  </div>
                                  <div className="AzPtSE beZrJE">
                                    <p
                                      data-qa="pay-now-value"
                                      className="_-wKhBx hHHBix font-default-body-m-semibold"
                                    >
                                      0 PKR
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="yhiMeE EuGs2E">
                            <div className="_6lhOdE">
                              <div className="_002iGE">
                                <div className="_nTKkE">
                                  <div className="_2rJeYE">
                                    <p
                                      data-qa="pay-at-venue"
                                      className="_-wKhBx font-default-body-s-regular"
                                    >
                                      Pay at salon
                                    </p>
                                  </div>
                                  <div className="AzPtSE beZrJE">
                                    <p
                                      data-qa="pay-at-venue-value"
                                      className="_-wKhBx font-default-body-s-regular"
                                    >
                                      {totalAmount} PKR
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv _5l5B-v _8WTxMv vM7X54">
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Appointment
                          </p>
                          <div
                            data-qa="cart-employee-info"
                            className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex lx2Dn1"
                          >
                            {/* <div
                              className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex"
                              style={{ alignItems: "center" }}
                            >
                              <div className="b6xJNc _SbjAc _1WMFBc _0alsKn">
                                <span
                                  className="_-6pwFd I-8RVd"
                                  aria-hidden="true"
                                >
                                  <svg
                                    fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M12 3.75a5.25 5.25 0 0 0-.078 10.5h.156A5.25 5.25 0 0 0 12 3.75Zm3.463 11.045a6.75 6.75 0 1 0-6.925 0 11.25 11.25 0 0 0-6.281 5.08.75.75 0 1 0 1.299.75 9.75 9.75 0 0 1 16.888 0 .75.75 0 1 0 1.3-.75 11.25 11.25 0 0 0-6.281-5.08Z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              </div>
                              <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                                <li className="p_e4TX">
                                  <p
                                    data-qa="cart-employee-first-name"
                                    className="_-wKhBx font-default-body-s-medium"
                                  >
                                    Any professional
                                  </p>
                                </li>
                                <li className="p_e4TX">
                                  <p
                                    data-qa="cart-employee-title"
                                    className="_-wKhBx u4x-xx font-default-body-s-regular"
                                  >
                                    Assigned on the day
                                  </p>
                                </li>
                              </ul>
                            </div> */}

                            {!staff ? (
                              <div className="yhiMeE EuGs2E">
                                <div className="_6lhOdE">
                                  <div className="H3QpUE">
                                    <div className="_2GGq-E">
                                      <span
                                        className="_-6pwFd s_TIMd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M12 3.75a5.25 5.25 0 0 0-.078 10.5h.156A5.25 5.25 0 0 0 12 3.75Zm3.463 11.045a6.75 6.75 0 1 0-6.925 0 11.25 11.25 0 0 0-6.281 5.08.75.75 0 1 0 1.299.75 9.75 9.75 0 0 1 16.888 0 .75.75 0 1 0 1.3-.75 11.25 11.25 0 0 0-6.281-5.08Z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="_002iGE">
                                    <div className="_nTKkE">
                                      <div className="_2rJeYE">
                                        <p className="_-wKhBx font-default-body-m-medium mb-default-25x">
                                          Any professional
                                        </p>
                                        <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                                          Assigned on the day
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                data-qa="cart-employee-info"
                                className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex lx2Dn1 mt-2"
                              >
                                <div
                                  className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <div className="b6xJNc _SbjAc _1WMFBc _0alsKn">
                                  {staffImageError ? (
                                    <div className="noStaff">{staff?.name?.charAt(0).toUpperCase()}</div>
                                  ) : (
                                    <img
                                      src={staff?.image}
                                      className="img-fluid object-fit-cover "
                                      style={{ height: "100%" }}
                                      alt=""
                                      onError={() => {
                                        setStaffImageError(true);
                                      }}
                                    />
                                  )}
                                  </div>
                                  <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                                    <li className="p_e4TX">
                                      <p
                                        data-qa="cart-employee-first-name"
                                        className="_-wKhBx font-default-body-s-medium"
                                      >
                                        {staff?.name}
                                      </p>
                                    </li>
                                    <li className="p_e4TX">
                                      <p
                                        data-qa="cart-employee-title"
                                        className="_-wKhBx u4x-xx font-default-body-s-regular"
                                      >
                                        {staff?.title}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                            <ul
                              data-qa="timeslot-info"
                              className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex"
                            >
                              <li className="p_e4TX">
                                <div className="b6xJNc _SbjAc _1WMFBc _0alsKn">
                                  <span
                                    className="_-6pwFd I-8RVd"
                                    aria-hidden="true"
                                  >
                                    <svg
                                      fill="currentColor"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M7.5 1.5a.75.75 0 0 1 .75.75V3h7.5v-.75a.75.75 0 0 1 1.5 0V3h2.25A1.5 1.5 0 0 1 21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3h2.25v-.75a.75.75 0 0 1 .75-.75Zm-.75 3H4.5v3h15v-3h-2.25v.75a.75.75 0 0 1-1.5 0V4.5h-7.5v.75a.75.75 0 0 1-1.5 0V4.5ZM19.5 9h-15v10.5h15V9Z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  </span>
                                </div>
                              </li>
                              <li className="p_e4TX">
                                <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="timeslot-date"
                                      className="_-wKhBx font-default-body-s-medium"
                                    >
                                      {moment(date).format("dddd D MMMM")}
                                    </p>
                                  </li>
                                  <li className="p_e4TX">
                                    <span
                                      data-qa="timeslot-time"
                                      className="_-wKhBx u4x-xx font-default-body-s-regular"
                                      style={{ display: "flex" }}
                                    >
                                      {time}
                                    </span>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv QpAXqv xv4qgv GNmo0c">
                          <button
                            id="button-continue-button"
                            data-qa="continue-button"
                            type="button"
                            className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU"
                            aria-pressed="false"
                            aria-labelledby="label-continue-button"
                            aria-disabled={isBookingCreating || isConfirming}
                            disabled={isBookingCreating || isConfirming}
                            aria-live="polite"
                            onClick={() => {
                              if (!user?.user?.isVerified) {
                                setShow(true);
                              } else {
                                mutate();
                              }
                            }}
                          >
                            <span className="G9I_8U">
                              <span
                                id="label-continue-button"
                                className="_-wKhBx ZCvJqx font-default-body-m-semibold d-flex justify-content-between align-items-center"
                              >
                                {isBookingCreating || isConfirming ? (
                                  // <Spinner size="sm" />
                                  <BeatLoader color="#fff" />
                                ) : (
                                  "Confirm"
                                )}
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="MZ1PW7" />
              </div>
              <div
                data-qa="bottom-bar"
                className="gMnlBP bjDb5P xa-C1P DETZNP zFOHKP display-default-block display-laptop-none"
              >
                <div className="K9p22P">
                  <ul
                    className="_5zCVQX gap-default-0 direction-default-horizontal display-default-flex"
                    style={{ justifyContent: "space-between", flexGrow: 1 }}
                  >
                    <li className="p_e4TX">
                      <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                        <li className="p_e4TX">
                          <p className="_-wKhBx font-default-body-s-semibold">
                            Total {totalAmount} PKR
                          </p>
                        </li>
                        <li className="p_e4TX">
                          <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                            {services?.length} services
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="p_e4TX">
                      <button
                        onClick={() => {
                          if (!user?.user?.isVerified) {
                            setShow(true);
                          } else {
                            mutate();
                          }
                        }}
                        id="button-react-aria-106"
                        data-qa="continue-button-mobile"
                        className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU JKAulU"
                        type="button"
                        aria-disabled={isBookingCreating || isConfirming}
                        disabled={isBookingCreating || isConfirming}
                        aria-labelledby="label-react-aria-106"
                        aria-live="polite"
                      >
                        <span className="G9I_8U">
                          <span
                            id="label-react-aria-106"
                            className="_-wKhBx ZCvJqx font-default-body-m-semibold d-flex justify-content-between align-items-center"
                          >
                            {isBookingCreating || isConfirming ? (
                              // <Spinner size="sm" />
                              <BeatLoader color="#fff" size={12} />
                            ) : (
                              "Confirm"
                            )}
                          </span>
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="Df5Lk6 UYF1TA">
              <div
                className="fWGqQa _4zQoha Aocp_a Y13v5a fWGqQa OlOvza"
                style={{ growDefault: 1 }}
              >
                <div
                  data-qa="no-connection-placeholder-hidden"
                  className="J9Zio6"
                >
                  <div className="c22bS6">
                    <div className="zulOE6">
                      <div className="nfd4g6">
                        <div
                          tabIndex={-1}
                          className="ybQ4qy _9y4n-y g0e0iy"
                          data-happo-hide="true"
                          style={{
                            widthDefault: "64px",
                            heightDefault: "64px",
                          }}
                        >
                          <canvas
                            width={80}
                            height={80}
                            style={{
                              width: "100%",
                              height: "100%",
                              transformOrigin: "0px 0px 0px",
                              contentVisibility: "visible",
                            }}
                          />
                        </div>
                      </div>
                      <div className="E5euV6">
                        <span
                          className="_-6pwFd _pPv2d gQFycd"
                          aria-hidden="true"
                        >
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="M3.996 3.195a.75.75 0 0 1 1.059.05l5.616 6.179a.64.64 0 0 1 .019.02l4.863 5.35a.759.759 0 0 1 .094.103l4.408 4.848a.75.75 0 0 1-1.11 1.01l-4.431-4.875a4.022 4.022 0 0 0-5.287.234.75.75 0 1 1-1.036-1.085 5.521 5.521 0 0 1 4.17-1.516l-2.495-2.745a8.502 8.502 0 0 0-3.81 2.152.75.75 0 1 1-1.05-1.072 10.004 10.004 0 0 1 3.743-2.31L6.597 7.173A12.91 12.91 0 0 0 2.88 9.739a.75.75 0 0 1-1.056-1.066 14.41 14.41 0 0 1 3.718-2.66L3.945 4.255a.75.75 0 0 1 .05-1.06ZM21.119 9.739A12.9 12.9 0 0 0 12.002 6h-.003c-.62 0-1.238.044-1.85.133a.75.75 0 0 1-.216-1.485c.684-.1 1.375-.149 2.066-.148a14.4 14.4 0 0 1 10.176 4.174.75.75 0 0 1-1.056 1.065ZM13.531 9.85a.75.75 0 0 1 .909-.547 9.89 9.89 0 0 1 4.554 2.546.75.75 0 0 1-1.05 1.07 8.39 8.39 0 0 0-3.865-2.16.75.75 0 0 1-.548-.908Z" />
                            <path d="M13.125 18.75a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z" />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentStepSix;
