import React from 'react'

function Section6() {
    return (
        <div className="lJh45t PUhFg+">
            <div className="cpj9Q+ _5-TPbG">
                <div className="p0zn2V _5-TPbG">
                    <div />
                    <div className="o1hdrw" style={{ backgroundPositionY: '-3103px' }}>
                        <div className="RF2QY5 _9HM3ba -JgtR7 x1o9-V"><h2 className="_-wKgxq font-default-header-l-semibold font-laptop-header-xl-bold">The top-rated destination for beauty and wellness</h2>

                        </div>
                        <div className="RF2QY5 _9HM3ba eh9fCz -JgtR7 x1o9-V">
                            <p className="_-wKgxq font-default-body-m-regular font-laptop-body-m-regular">One solution, one software. Trusted by the best in the beauty and wellness industry
                            </p>

                        </div>
                        <div className="RF2QY5 _2-SJs2 _9HM3ba -JgtR7 x1o9-V I37Q-P">
                            <div className="lC+Yeg">
                                <p className="_-wKgxq font-default-header-m-semibold font-laptop-header-3xl-bold iy5mrL iy5mrL">700 million+
                                </p>

                            </div>
                            <p className="_-wKgxq font-default-body-s-regular font-laptop-body-m-regular">Appointments booked on Beautowns
                            </p>

                        </div>
                        <div className="n5MyOK WcR8jK _7xLjxK _-PJ_bK CzY2UK gAcKyK _0LgBpK EKsrIK xPTDgJ" style={{ columnMin: '0px', columnMax: '1fr', rowMin: '0px', rowMax: 'auto' }}>
                            <div className="RF2QY5 kvSbiA _9HM3ba -JgtR7 x1o9-V I37Q-P">
                                <div className="lC+Yeg">
                                    <p className="_-wKgxq font-default-header-m-semibold font-laptop-header-xl-bold VsetkB VsetkB">100,000+
                                    </p>

                                </div>
                                <div className="RF2QY5 JHWB6f _9HM3ba -JgtR7 x1o9-V">
                                    <p className="_-wKgxq font-default-body-m-medium font-laptop-body-l-medium">partner businesses
                                    </p>

                                </div>

                            </div>
                            <div className="RF2QY5 kvSbiA T2wgWY _9HM3ba -JgtR7 x1o9-V I37Q-P">
                                <div className="lC+Yeg">
                                    <p className="_-wKgxq font-default-header-m-semibold font-laptop-header-xl-bold VsetkB VsetkB">120+ countries
                                    </p>

                                </div>
                                <div className="RF2QY5 JHWB6f _9HM3ba -JgtR7 x1o9-V">
                                    <p className="_-wKgxq font-default-body-m-medium font-laptop-body-l-medium">using Beautowns
                                    </p>

                                </div>

                            </div>
                            <div className="RF2QY5 kvSbiA _9HM3ba -JgtR7 x1o9-V I37Q-P">
                                <div className="lC+Yeg">
                                    <p className="_-wKgxq font-default-header-m-semibold font-laptop-header-xl-bold VsetkB VsetkB">450,000+
                                    </p>

                                </div>
                                <div className="RF2QY5 JHWB6f _9HM3ba -JgtR7 x1o9-V">
                                    <p className="_-wKgxq font-default-body-m-medium font-laptop-body-l-medium">stylists and professionals
                                    </p>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default Section6