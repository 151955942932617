import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineMail,
  AiOutlineMobile,
  AiOutlineUnlock,
  AiFillApple,
} from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import logo2 from "../components/assests/logos2.png";

const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="container-fluid d-flex align-items-center height_10vh">
      <div className="container">
        <div className="row align-items-center justify-content-around">
          <div className="col-lg-4 mx-auto col-md-8 login_border m-auto p-3 p-lg-4 shadow-lg zoom-up">
            <h2 className="fw-bold text-animation-fadeOut">Sign Up</h2>

            <form>
              <div className="my-3 mt-4 position-relative text-animation-fadeOut">
                <input
                  type="email"
                  className="form-control login_input shadow-sm"
                  id="email"
                  placeholder="name@example.com"
                />
                <AiOutlineMail className="msg_icon" />
              </div>
              <div className="my-3 position-relative text-animation-fadeOut">
                <input
                  type="tel"
                  className="form-control login_input shadow-sm"
                  id="phoneno"
                  placeholder="03322978****"
                />
                <AiOutlineMobile className="msg_icon" />
              </div>
              <div className="mb-3 position-relative text-animation-fadeOut">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control login_input shadow-sm"
                  id="password"
                  placeholder="**********"
                />
                <AiOutlineUnlock className="msg_icon" />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="eye_icon"
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </span>
              </div>
              <div className="mb-3 position-relative text-animation-fadeOut">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control login_input shadow-sm"
                  id="confirmPassword"
                  placeholder="Confirm password"
                />
                <AiOutlineUnlock className="msg_icon" />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="eye_icon"
                >
                  {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </span>
              </div>

              <button className="btn btn-primary w-100 pink_btn mt-2 shadow text-animation-fadeOut">
                SignUp
              </button>

              <p className="my-2 mb-5 text-animation-fadeOut mt-3">
                Already registered?{" "}
                <Link to="/login">
                  <span className="pink_text fw-bold hover_underline">
                    Login
                  </span>
                </Link>
              </p>

              <div className="hr_line position-relative text-animation-fadeOut">
                <hr />
                <div className="position-absolute top-50 start-50 translate-middle">
                  <p className="mt-3 text_hr_p">or continue with</p>
                </div>
              </div>

              <div className="continue_logos d-flex justify-content-center mt-5 text-animation-fadeOut">
                <FcGoogle className="mx-3 shadow rounded-circle" />
                <AiFillApple className="mx-3 shadow rounded-circle" />
              </div>
            </form>
          </div>
          <div className="col-lg-3 mx-auto d-none d-lg-block">
            <img src={logo2} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
