import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import LoginForm from "./auth/LoginForm";
import SignUpForm from "./auth/SignUpForm";
import OtpForm from "./auth/OtpForm";
import ResetPasswordForm from "./auth/ResetPasswordForm";
import { IoMdArrowRoundBack } from "react-icons/io";
import ForgotPasswordForm from "./auth/ForgotPasswordForm";

const AuthModal = ({ user, show, setShow }) => {

  const [current, setCurrent] = useState("login");
  const handleClose = () => {
    setCurrent("login");
    setShow(false);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const yourQueryParam = urlParams.get("referralCode");
  useEffect(() => {
    if (yourQueryParam && !user) {
      setCurrent("signup");
    }
  }, []);

  return (
    <Modal
      className="loginModal"
      centered
      size="md"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header
        className="border-0 mb-0"
        closeButton
        onClick={() => {
          setCurrent("login");
        }}
      >
        {current !== "login" && (
          <IoMdArrowRoundBack
            className="fs-3 text-dark hc"
            onClick={() => setCurrent("login")}
          />
        )}
      </Modal.Header>
      <Modal.Body className="mt-0">
        {/* {current === "login" && <ModalMenu setCurrent={setCurrent} />} */}
        {current === "login" && (
          <LoginForm setCurrent={setCurrent} setShow={setShow} />
        )}
        {current === "signup" && <SignUpForm referralCode={yourQueryParam} setCurrent={setCurrent} />}
        {current === "otp-rest" && (
          <OtpForm current={current} setCurrent={setCurrent} />
        )}
        {current === "otp" && (
          <OtpForm
            current={current}
            setShow={setShow}
            setCurrent={setCurrent}
          />
        )}
        {current === "rest-password" && (
          <ResetPasswordForm setCurrent={setCurrent} />
        )}
        {current === "forgot" && <ForgotPasswordForm setCurrent={setCurrent} />}
      </Modal.Body>
    </Modal>
  );
};

export default AuthModal;
