import React from "react";
import HomeScreen from "../../assets/images/images/homeApp.png";
import qrCode from "../../assets/images/images/QRCODE.jpg";

function Section4() {
  return (
    <div className="lJh45t">
      <div className="cpj9Q+ _5-TPbG">
        <div className="p0zn2V _5-TPbG">
          <section className="WSchAe">
            <div className="_9kTURq GepCJh">
              <div className="kVUtYD">
                <div>
                  <div
                    className="n5MyOK gVp-TK WcR8jK _7xLjxK _-PJ_bK IYpR6K CzY2UK gAcKyK _0LgBpK EKsrIK q9GhXO"
                    style={{
                      columnMin: "0px",
                      columnMax: "1fr",
                      rowMin: "0px",
                      rowMax: "auto",
                    }}
                  >
                    <p className="_-wKgxq font-default-body-s-semibold">
                      Available on
                    </p>
                    <div
                      className="n5MyOK WcR8jK _7xLjxK _-PJ_bK V106DK IYpR6K CzY2UK gAcKyK _0LgBpK EKsrIK _6ywQK1"
                      style={{
                        columnMin: "0px",
                        columnMax: "1fr",
                        rowMin: "0px",
                        rowMax: "auto",
                      }}
                    >
                      {/* <span className="_-6pBDG _8oamQG" aria-hidden="true">
                                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M20.026 8.163c-.094.058-2.318 1.293-2.318 4.03.105 3.121 2.807 4.216 2.853 4.216-.046.058-.408 1.491-1.479 2.993C18.232 20.696 17.29 22 15.856 22c-1.363 0-1.852-.862-3.425-.862-1.688 0-2.166.862-3.459.862-1.433 0-2.446-1.374-3.343-2.656-1.164-1.677-2.154-4.31-2.189-6.837-.023-1.339.233-2.655.885-3.774.92-1.56 2.562-2.62 4.356-2.655 1.374-.047 2.597.943 3.436.943.804 0 2.306-.943 4.006-.943.734 0 2.691.221 3.903 2.085ZM12 5.81c-.245-1.223.43-2.446 1.06-3.226C13.864 1.64 15.133 1 16.227 1a4.56 4.56 0 0 1-1.164 3.296C14.354 5.24 13.13 5.95 12 5.81Z" />
                                            </svg>
                                        </span> */}
                      <span className="_-6pBDG _8oamQG" aria-hidden="true">
                        <svg
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12.003 2.4C6.7 2.4 2.4 6.698 2.4 12s4.3 9.6 9.603 9.6c8.008 0 9.812-7.434 9.061-11.2H12v3.2h6.19A6.398 6.398 0 0 1 12 18.4a6.4 6.4 0 0 1 0-12.8c1.607 0 3.071.597 4.195 1.575l2.274-2.272A9.568 9.568 0 0 0 12.003 2.4Z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <h2 className="_-wKgxq font-default-display-m-bold font-laptop-display-xl-bold mb-default-150x mb-laptop-300x">
                  Download the Beautowns app
                </h2>
                <p className="_-wKgxq font-default-body-s-regular font-laptop-body-l-regular mb-default-300x">
                  Book unforgettable beauty and wellness experiences with the
                  Beautowns mobile app
                </p>
                <div className="display-default-inline-block display-laptop-none">
                  <a
                    className="CLnQGg _XdmmM tPDowU igySVU _8oamEU _2qQHCU JKAulU"
                    id="download-app-btn"
                    href="https://play.google.com/store/apps/details?id=com.beautowns"
                    target="_self"
                    data-qa="download-app-btn"
                  >
                    <span className="G9I_8U">
                      <span
                        style={{ color: "#fff" }}
                        id="label-download-app-btn"
                        className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                      >
                        Get the app
                      </span>
                    </span>
                  </a>
                </div>
                <a
                  className="CLnQGg _XdmmM display-default-none display-laptop-inline-block DBA1S5"
                  href="https://play.google.com/store/apps/details?id=com.beautowns"
                  target="_blank"
                  rel="noreferrer"
                >
                  <picture className="rC5TQo _-rOGgo Qs8P0o _9VDIFS">
                    <img
                      src={qrCode}
                      alt=""
                      className="N3bmGo _-rOGgo p0zJqo _9VDIFS"
                      loading="lazy"
                    />
                  </picture>
                </a>
              </div>
              <div className="TsVEOJ">
                <div className="yLABQm">
                  {/* <picture className="rC5TQo _-rOGgo Qs8P0o display-default-none display-laptop-block tLTQ-m -3OpLT">
                                    <source srcSet=" //www.fresha.com/assets/static/media/trendyStudio@2x-7323de5d66a7e52fa30aab8711abf488.webp 2x, //www.fresha.com/assets/static/media/trendyStudio@3x-c3fdf1b3a13841eb696281a92f68580e.webp 3x," media="(min-width: 0px)" type="image/webp" />
                                    <source srcSet="//www.fresha.com/assets/static/media/trendyStudio@1x-95c530d6c569b182ae2d61b4351a97ee.webp" media="(min-width: 0px)" type="image/webp" />
                                    <source srcSet={HomeScreen} media="(min-width: 0px)" type />
                                    <source srcSet="//www.fresha.com/assets/static/media/trendyStudio@1x-6984689be98ef82af31abede595aff3d.png" media="(min-width: 0px)" type />
                                    <img src={HomeScreen} alt="" className="N3bmGo _-rOGgo p0zJqo display-default-none display-laptop-block tLTQ-m" loading="lazy" />
                                    </picture> */}
                  <img src={HomeScreen} className="img-fluid phoneApp" alt="" />
                  {/* <video preload="metadata" className="cGzLC- -3OpLT" loop playsInline>
                                    <source src={HomeScreen} type="video/mp4" />
                                </video> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Section4;
