import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import AuthModal from "../../components/AuthModal";
import { useNavigate } from "react-router-dom";
import StoreCards from "./StoreCards";

const FavoriteSalons = ({ showMenu, setShowMenu }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { favourite } = useSelector((store) => store?.user?.user);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  return (
    <>
      <div id="react" className="react--loading">
        <AuthModal show={show} setShow={setShow} />
        <div className="">
          <div className="">
            <Header
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
            />

            <div className="L5Ntp6 mt-5 pt-5">
              <div className="container min-vh-50">
                <h2 className="fw-bolder text-center my-5">Favorite Salon</h2>
                <div className="row">
                  {favourite?.stores?.length > 0 ? (
                    <StoreCards store={favourite?.stores} />
                  ) : (
                    <h3 className="fw-bolder text-center my-5">
                      No Favorite Salon
                    </h3>
                  )}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavoriteSalons;
