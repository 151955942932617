import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeService,
  setService,
  setStep,
} from "../../redux/slices/serviceSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { StepBar } from "../StepBar";

function AppointmentStepTwo() {
  const { storeDetails } = useSelector((e) => e?.store);
  const dispatch = useDispatch();
  const { staff, services } = useSelector((e) => e?.service);
  const navigate = useNavigate();
  const [staffImageError, setStaffImageError] = useState(false);
  useEffect(() => {
    dispatch(setStep(1));
    // eslint-disable-next-line
  }, []);

  const totalAmount = services?.reduce((acc, obj) => {
    const valueToAdd = obj?.value;
    if (typeof valueToAdd === "number") {
      return acc + valueToAdd;
    } else {
      return acc;
    }
  }, 0);

  const totalDuration = services?.reduce((acc, obj) => {
    const valueToAdd = obj?.duration;
    if (typeof valueToAdd === "number") {
      return acc + valueToAdd;
    } else {
      return acc;
    }
  }, 0);

  const [selectedCards, setSelectedCards] = useState(services || []);

  const handleCardClick = (service) => {
    if (
      selectedCards?.some(
        (selectedService) => selectedService?._id === service._id
      )
    ) {
      setSelectedCards(
        selectedCards?.filter(
          (selectedService) => selectedService?._id !== service._id
        )
      );
      dispatch(removeService(service));
    } else {
      setSelectedCards([...selectedCards, service]);
      dispatch(setService(service));
    }
  };

  const resultArray = Object.values(storeDetails?.services).reduce(
    (acc, array) => {
      return acc.concat(array);
    },
    []
  );
  return (
    <div id="react" className="react--loading">
      <div className="GXRSVM notranslate">
        <div className="mre0Hq w+g1+Q" featureflags="[object Object]">
          <div className="_32iRow">
            <div className="Df5Lk6">
              <div className="E4hVuT JdS1yT xa-rvT">
                <div className="_7nJ-pT JdS1yT">
                  <div className="wwqFAT JdS1yT">
                    <button
                      onClick={() =>
                        navigate(`/salon/${storeDetails?.store?.slug}`)
                      }
                      id="button-header-flow-back"
                      data-qa="header-flow-back"
                      className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU DetaJT"
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="label-header-flow-back"
                      aria-live="polite"
                    >
                      <span className="G9I_8U va37oU">
                        <span
                          id="label-header-flow-back"
                          className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                        >
                          Go back
                        </span>
                      </span>
                      <span className="_0f0EDU">
                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                  <div className="p1pdhT JdS1yT">
                    <span className="_-wKhBx rfrUux Bnx6rx dUG8gx ZCvJqx KfKZXx font-default-header-s-semibold font-tablet-header-s-semibold font-laptop-header-s-semibold font-desktop-header-s-semibold _zngzT">
                      Select servicess
                    </span>
                  </div>
                  <div className="wwqFAT ibymkT">
                    <button
                      onClick={() =>
                        navigate(`/salon/${storeDetails?.store?.slug}`)
                      }
                      id="button-close-button"
                      data-qa="close-button"
                      className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU"
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="label-close-button"
                      aria-live="polite"
                    >
                      <span className="G9I_8U va37oU">
                        <span
                          id="label-close-button"
                          className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                        >
                          Close
                        </span>
                      </span>
                      <span className="_0f0EDU">
                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="YAjmY7 NekGj7">
                <div className="otg7W7 NekGj7 tUf8c7">
                  <div className="iWARt7">
                    <div className="display-default-none display-laptop-flex">
                      <div data-qa="header-title" className="dYmf3l w-100 ">
                        <div className="container my-5">
                          <div className="col-11 mx-auto">
                            <StepBar activeStep={2} />
                          </div>
                        </div>
                        <div className="fWGqQa fwrHUa _4zQoha Aocp_a gap-default-200x fWGqQa">
                          <h1
                            className="_-wKhBx rfrUux font-default-header-l-semibold font-tablet-header-xl-bold font-laptop-header-xl-bold font-desktop-header-xl-bold"
                            style={{ flexGrow: 1 }}
                          >
                            Select services
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="display-default-flex display-laptop-none">
                      <div className="dYmf3l OZAYBl w-100 ">
                        <div className="container mt-3 mb-5">
                          <div className="col-11 mx-auto">
                            <StepBar activeStep={2} />
                          </div>
                        </div>
                        <div className="fWGqQa fwrHUa _4zQoha Aocp_a gap-default-200x fWGqQa">
                          <h1
                            className="_-wKhBx rfrUux font-default-header-l-semibold font-tablet-header-xl-bold font-laptop-header-xl-bold font-desktop-header-xl-bold"
                            style={{ flexGrow: 1 }}
                          >
                            Select services
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gbuHog Nekhlg XHPkvf">
                    <div className="QJln9B" />
                    {/* <div className="sJ9bGT">
                      <div className="DVgwlS _6Bl4_S NViIsS">
                        <div
                          className="g37Js2 ZJbAO2 TGqTR2"
                          aria-hidden="true"
                        />
                        <div className="U5qwWz kxKkdz">
                          <div />
                          <div
                            className="K5RmDQ _6Blv_Q _XdmmM"
                            aria-hidden="true"
                            style={{
                              width: "171px",
                              transform: "translateX(0px)",
                              transition:
                                "transform 300ms ease 0s, width 100ms ease 0s",
                            }}
                          />
                          <ul role="tablist" className="yLdqCN _6BlHcN">
                            <li />
                            <li
                              role="tab"
                              aria-selected="true"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Colours + Highlights
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Cutting + Styling
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Hair Treatments
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Hair Extensions
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Massage
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Face
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Hands
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Feet
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Lashes + Brows
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Hair Removal
                              </span>
                            </li>
                            <li />
                            <li
                              role="tab"
                              aria-selected="false"
                              className="oLa06l _6Bljjl sC8W0i"
                            >
                              <span className="_-wKhBx font-default-body-s-semibold IW0FAl">
                                Packages/Offers
                              </span>
                            </li>
                          </ul>
                          <div />
                        </div>
                        <div
                          className="g37Js2 ZJbAO2 zFO6Z2 l3Z222"
                          aria-hidden="true"
                        />
                        <div className="fWGqQa _4zQoha Aocp_a C28s3a gap-default-150x fWGqQa WTki5b">
                          <button
                            id="button-react-aria-405"
                            data-qa="react-aria-405"
                            className="bXwNAZ _XdmmM tPDowU _3epCrU xR_P5U I-8qUU _2qQHCU JKAulU sQbxHU nE2lYU"
                            type="button"
                            aria-pressed="false"
                            aria-labelledby="label-react-aria-405"
                            aria-disabled="true"
                            aria-live="polite"
                          >
                            <span className="G9I_8U va37oU">
                              <span
                                id="label-react-aria-405"
                                className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                              >
                                Scroll left
                              </span>
                            </span>
                            <span className="_0f0EDU">
                              <span
                                className="_-6pwFd _8oa-nd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14.968 6.782a.75.75 0 0 1 0 1.06L10.81 12l4.157 4.157a.75.75 0 1 1-1.06 1.06L9.22 12.53a.75.75 0 0 1 0-1.06l4.687-4.688a.75.75 0 0 1 1.06 0Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </span>
                          </button>
                          <button
                            id="button-react-aria-406"
                            data-qa="react-aria-406"
                            className="bXwNAZ _XdmmM tPDowU _3epCrU xR_P5U I-8qUU _2qQHCU JKAulU nE2lYU"
                            type="button"
                            aria-pressed="false"
                            aria-labelledby="label-react-aria-406"
                            aria-disabled="false"
                            aria-live="polite"
                          >
                            <span className="G9I_8U va37oU">
                              <span
                                id="label-react-aria-406"
                                className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                              >
                                Scroll right
                              </span>
                            </span>
                            <span className="_0f0EDU">
                              <span
                                className="_-6pwFd _8oa-nd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M9.22 6.782a.75.75 0 0 1 1.06 0l4.688 4.688a.75.75 0 0 1 0 1.06l-4.688 4.688a.75.75 0 1 1-1.06-1.06L13.377 12 9.22 7.843a.75.75 0 0 1 0-1.06Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="bPSRs+">
                      <div
                        aria-labelledby="label-react-aria-407"
                        role="group"
                        id="react-aria-408"
                        className
                      >
                        <div className="KfGRUx _-YxP9x ZWUVlx" />
                        <div className="_5zCVQX gap-default-0 gap-laptop-150x direction-default-vertical display-default-flex REnoxV">
                          <div
                            data-virtuoso-scroller="true"
                            style={{ position: "relative" }}
                          >
                            <div
                              style={{
                                width: "100%",
                                position: "sticky",
                                top: "0px",
                                zIndex: 1,
                                marginTop: "0px",
                              }}
                            >
                              <div data-test-id="virtuoso-top-item-list">
                                <div
                                  data-index={0}
                                  data-known-size={120}
                                  data-item-index={0}
                                  style={{
                                    position: "sticky",
                                    zIndex: 1,
                                    overflowAnchor: "none",
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div
                              data-viewport-type="window"
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: "-130px",
                              }}
                            >
                              <div
                                data-test-id="virtuoso-item-list"
                                style={{
                                  boxSizing: "border-box",
                                  paddingTop: "120px",
                                  // paddingBottom: "18572px",
                                  marginTop: "0px",
                                  paddingBottom: "80px",
                                }}
                              >
                                {/* <div
                                  data-index={1}
                                  data-known-size={152}
                                  data-item-index={0}
                                  data-item-group-index={0}
                                  style={{ overflowAnchor: "none" }}
                                >
                                  <div className="msDJ-o">
                                    <div
                                      id="wrapper-s:7111474"
                                      data-qa="offer-item"
                                     
                                      className="yhiMeE vYRUqE Hm54LE SQVduE lEX9sW T9xES2 OGOHl2 xa-552 siKM82 _XdmmM lEX9sW"
                                    >
                                      <button
                                        className="bXwNAZ _XdmmM mhNisE"
                                        id="button-react-aria-813"
                                        type="button"
                                        aria-pressed="false"
                                        aria-labelledby="label-react-aria-813"
                                        aria-live="polite"
                                      />
                                      <div className="_6lhOdE">
                                        <div className="_002iGE">
                                          <div className="_nTKkE">
                                            <div className="_2rJeYE">
                                              <h3
                                                id="label-s:7111474"
                                                data-qa="offer-item-name"
                                                className="_-wKhBx font-default-body-m-medium mb-default-25x"
                                              >
                                                Colour Consultation
                                              </h3>
                                              <p
                                                data-qa="offer-item-caption"
                                                className="_-wKhBx u4x-xx font-default-body-s-regular mb-default-25x"
                                              >
                                                15min
                                              </p>
                                              <p
                                                id="description-s:7111474"
                                                data-qa="offer-item-description"
                                                className="_-wKhBx u4x-xx ZCvJqx KfKZXx font-default-body-s-regular"
                                              ></p>
                                              <span className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex _41Ivo5">
                                                <p
                                                  data-qa="offer-item-price"
                                                  className="_-wKhBx font-default-body-s-medium"
                                                >
                                                  free
                                                </p>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="kTCnEE">
                                          <div className="t4IcZE">
                                            <button
                                              id="button-primitive-action-s:7111474"
                                              data-qa="primitive-action-undefined"
                                              className="bXwNAZ _XdmmM yvJ5q2"
                                              type="button"
                                              aria-pressed="false"
                                              aria-labelledby="label-primitive-action-s:7111474"
                                              aria-live="polite"
                                            >
                                              <span className="tk-4n5">
                                                Add Colour Consultation to
                                                basket
                                              </span>
                                              <span
                                                className="_-6pwFd _-YxYKd"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  fill="currentColor"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path
                                                    fillRule="evenodd"
                                                    d="M21.295 7.545a1.125 1.125 0 1 0-1.59-1.59L10 15.659l-4.455-4.455a1.125 1.125 0 0 0-1.59 1.591l5.25 5.25c.439.44 1.151.44 1.59 0l10.5-10.5Z"
                                                    clipRule="evenodd"
                                                  />
                                                </svg>
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                        <input
                                          aria-labelledby="label-s:7111474"
                                          id="s:7111474"
                                          type="checkbox"
                                          aria-describedby="description-s:7111474"
                                          aria-live="polite"
                                          className="_7AUnpW"
                                          //   data-qa="input-offer-item"
                                          //   defaultValue="s:7111474"
                                        />
                                      </div>
                                      <hr
                                        aria-orientation="horizontal"
                                        className="KT-if1 CChMQ1 Sm3CD1 pz1vF1 display-laptop-none vpFoTE vYRUqE"
                                      />
                                    </div>
                                    <div />
                                  </div>
                                </div>

                                <div
                                  data-index={3}
                                  data-known-size={152}
                                  data-item-index={2}
                                  data-item-group-index={0}
                                  style={{ overflowAnchor: "none" }}
                                >
                                  <div className="msDJ-o">
                                    <div
                                      id="wrapper-s:9519280"
                                      data-qa="offer-item"
                                      className="yhiMeE vYRUqE SQVduE lEX9sW T9xES2 OGOHl2 xa-552 _XdmmM lEX9sW"
                                    >
                                      <button
                                        className="bXwNAZ _XdmmM mhNisE"
                                        id="button-react-aria-809"
                                        type="button"
                                        aria-pressed="false"
                                        aria-labelledby="label-react-aria-809"
                                        aria-live="polite"
                                      />
                                      <div className="_6lhOdE">
                                        <div className="_002iGE">
                                          <div className="_nTKkE">
                                            <div className="_2rJeYE">
                                              <h3
                                                id="label-s:9519280"
                                                data-qa="offer-item-name"
                                                className="_-wKhBx font-default-body-m-medium mb-default-25x"
                                              >
                                                AirTouch / Babylights
                                              </h3>
                                              <p
                                                data-qa="offer-item-caption"
                                                className="_-wKhBx u4x-xx font-default-body-s-regular mb-default-25x"
                                              >
                                                3h
                                              </p>
                                              <p
                                                id="description-s:9519280"
                                                data-qa="offer-item-description"
                                                className="_-wKhBx u4x-xx ZCvJqx KfKZXx font-default-body-s-regular"
                                              >
                                                Please add a finishing service
                                                (Blow Dry / Cut) with this
                                                appointment. When booking
                                                together with a Cut or Blow Dry
                                                service, please select this
                                                colour service first. All new
                                                clients are required to have a
                                                patch test at least 48 hours
                                                prior to a colouring service.
                                              </p>
                                              <span className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex _41Ivo5">
                                                <p
                                                  data-qa="offer-item-price"
                                                  className="_-wKhBx font-default-body-s-medium"
                                                >
                                                  from £200
                                                </p>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="kTCnEE">
                                          <div className="t4IcZE">
                                            <button
                                              id="button-primitive-action-s:9519280"
                                              data-qa="primitive-action-undefined"
                                              className="bXwNAZ _XdmmM yvJ5q2"
                                              type="button"
                                              aria-pressed="false"
                                              aria-labelledby="label-primitive-action-s:9519280"
                                              aria-live="polite"
                                            >
                                              <span className="tk-4n5">
                                                Add AirTouch / Babylights to
                                                basket
                                              </span>
                                              <span
                                                className="_-6pwFd _-YxYKd"
                                                aria-hidden="true"
                                              >
                                                <svg
                                                  fill="currentColor"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <path d="M13.125 3.75a1.125 1.125 0 0 0-2.25 0v7.125H3.75a1.125 1.125 0 0 0 0 2.25h7.125v7.125a1.125 1.125 0 0 0 2.25 0v-7.125h7.125a1.125 1.125 0 0 0 0-2.25h-7.125V3.75Z" />
                                                </svg>
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                        <input
                                          aria-labelledby="label-s:9519280"
                                          id="s:9519280"
                                          type="checkbox"
                                          aria-describedby="description-s:9519280"
                                          aria-live="polite"
                                          className="_7AUnpW"
                                          data-qa="input-offer-item"
                                          defaultValue="s:9519280"
                                        />
                                      </div>
                                      <hr
                                        aria-orientation="horizontal"
                                        className="KT-if1 CChMQ1 Sm3CD1 pz1vF1 display-laptop-none vpFoTE vYRUqE"
                                      />
                                    </div>
                                    <div />
                                  </div>
                                </div> */}

                                {resultArray?.length > 0 &&
                                  resultArray?.map((service, i) => (
                                    <div
                                      onClick={() => handleCardClick(service)}
                                      key={i}
                                      aria-labelledby="label-react-aria-270"
                                      role="group"
                                      id="react-aria-271"
                                      class=" mb-2"
                                    >
                                      <div class="KfGRUx rIZLlx _-YxP9x ZWUVlx"></div>
                                      <div class="_5zCVQX gap-default-0 gap-laptop-150x direction-default-vertical display-default-flex REnoxV">
                                        <div
                                          id="wrapper-s:7111465"
                                          class={`yhiMeE vYRUqE SQVduE  ${
                                            services?.some(
                                              (selectedService) =>
                                                selectedService?._id ===
                                                service?._id
                                            )
                                              ? " yhiuUl vYRLul Hm56kl"
                                              : "grayBorder"
                                          } `}
                                        >
                                          <div class="_6lhOdE">
                                            <div class="_002iGE">
                                              <div class="_nTKkE">
                                                <div class="_2rJeYE">
                                                  <h3
                                                    id="label-s:7111465"
                                                    class="_-wKhBx font-default-body-m-medium"
                                                  >
                                                    {service?.name}
                                                  </h3>
                                                  <p
                                                    id="description-s:7111465"
                                                    class="_-wKhBx u4x-xx font-default-body-s-regular mb-default-150x"
                                                  >
                                                    {service?.duration} min
                                                  </p>
                                                  <span class="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex">
                                                    <p class="_-wKhBx font-default-body-s-medium">
                                                      {service?.value} PKR
                                                    </p>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="kTCnEE">
                                              <div class="t4IcZE">
                                                <button
                                                  id="button-primitive-action-s:7111465"
                                                  data-qa="primitive-action-undefined"
                                                  class="bXwNAZ _XdmmM yvJ5q2"
                                                  type="button"
                                                  aria-pressed="false"
                                                  aria-labelledby="label-primitive-action-s:7111465"
                                                  aria-live="polite"
                                                >
                                                  <span class="tk-4n5">
                                                    Add Colour Correction to
                                                    basket
                                                  </span>
                                                  <span
                                                    class="_-6pwFd _-YxYKd"
                                                    aria-hidden="true"
                                                  >
                                                    {services?.some(
                                                      (selectedService) =>
                                                        selectedService?._id ===
                                                        service?._id
                                                    ) ? (
                                                      <svg
                                                        fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path
                                                          fillRule="evenodd"
                                                          d="M21.295 7.545a1.125 1.125 0 1 0-1.59-1.59L10 15.659l-4.455-4.455a1.125 1.125 0 0 0-1.59 1.591l5.25 5.25c.439.44 1.151.44 1.59 0l10.5-10.5Z"
                                                          clipRule="evenodd"
                                                        />
                                                      </svg>
                                                    ) : (
                                                      <svg
                                                        fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path d="M13.125 3.75a1.125 1.125 0 0 0-2.25 0v7.125H3.75a1.125 1.125 0 0 0 0 2.25h7.125v7.125a1.125 1.125 0 0 0 2.25 0v-7.125h7.125a1.125 1.125 0 0 0 0-2.25h-7.125V3.75Z"></path>
                                                      </svg>
                                                    )}
                                                  </span>
                                                </button>
                                              </div>
                                            </div>
                                            <input
                                              aria-labelledby="label-s:7111465"
                                              id="s:7111465"
                                              type="checkbox"
                                              aria-describedby="description-s:7111465"
                                              aria-live="polite"
                                              class="_7AUnpW"
                                              value="s:7111465"
                                            />
                                          </div>
                                          <hr
                                            aria-orientation="horizontal"
                                            class="KT-if1 CChMQ1 Sm3CD1 pz1vF1 display-laptop-none vpFoTE vYRUqE"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="Zar0Yb">
                        <div className="X6deAu qDcmf+">
                          <button
                            id="button-button-scroll-down"
                            className="bXwNAZ JDUfE1"
                            type="button"
                            tabIndex={-1}
                            aria-pressed="false"
                            aria-labelledby="label-button-scroll-down"
                            aria-live="polite"
                          >
                            <p className="_-wKhBx _3-CNtx font-default-body-s-regular">
                              1 selected service
                            </p>
                            <span
                              className="_-6pwFd _3-CWGd I-8RVd"
                              aria-hidden="true"
                            >
                              <svg
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M11.47 20.78a.75.75 0 0 0 1.06 0l6.75-6.75a.75.75 0 1 0-1.06-1.06l-5.47 5.47V3.75a.75.75 0 0 0-1.5 0v14.69l-5.47-5.47a.75.75 0 0 0-1.06 1.06l6.75 6.75Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div> */}
                    </div>
                    {/* <div
                      className="fWGqQa R-waSa Aocp_a Hc2v5a YK8xea EKpM7a llVYka ZWBvha w2KJka _0buYia J_28Oa UNoRIa QZTmma _8qrp9a cAWGFa gap-default-300x fWGqQa"
                      style={{
                        paddingTopDefault: 0,
                        paddingTopTablet: "40px",
                        paddingTopLaptop: 0,
                        paddingRightDefault: "20px",
                        paddingRightTablet: "32px",
                        paddingRightLaptop: 0,
                        paddingBottomDefault: "80px",
                        paddingBottomTablet: "80px",
                        paddingBottomLaptop: "32px",
                        paddingLeftDefault: "20px",
                        paddingLeftTablet: "32px",
                        paddingLeftLaptop: 0,
                      }}
                    >
                      <p className="_-wKhBx font-default-header-m-semibold">
                        Try something else
                      </p>
                      <ul className="_5zCVQX gap-default-150x direction-default-vertical display-default-inline-flex">
                        <div
                          id="try-sth-else-membership-option"
                          className="yhiMeE NLjR-E SQVduE"
                        >
                          <a
                            className="CLnQGg _XdmmM mhNisE"
                            href="/a/decadence-hair-and-beauty-london-36-store-st-rrq2c9kq/paid-plans"
                          />
                          <div className="_6lhOdE">
                            <div className="fWGqQa R-waSa Aocp_a fWGqQa">
                              <p className="_-wKhBx font-default-body-m-medium mb-default-25x">
                                Membership
                              </p>
                              <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                                Bundle your services in to a membership
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          id="try-sth-else-voucher-option"
                          className="yhiMeE NLjR-E SQVduE"
                        >
                          <a
                            className="CLnQGg _XdmmM mhNisE"
                            href="/a/decadence-hair-and-beauty-london-36-store-st-rrq2c9kq/vouchers"
                          />
                          <div className="_6lhOdE">
                            <div className="fWGqQa R-waSa Aocp_a fWGqQa">
                              <p className="_-wKhBx font-default-body-m-medium mb-default-25x">
                                Voucher
                              </p>
                              <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                                Treat yourself or a friend to future visits
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          id="try-sth-else-products-option"
                          className="yhiMeE NLjR-E SQVduE"
                        >
                          <a
                            className="CLnQGg _XdmmM mhNisE"
                            href="/store/decadence-hair-beauty-store-ginn71uz?utm_medium=link&utm_campaign=link%2Bbuilder"
                          />
                          <div className="_6lhOdE">
                            <div className="fWGqQa R-waSa Aocp_a fWGqQa">
                              <p className="_-wKhBx font-default-body-m-medium mb-default-25x">
                                Products
                              </p>
                              <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                                Buy products online
                              </p>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </div> */}
                  </div>
                  <div className="QrHNxj Aqljbj UuHXUj NekZnj">
                    <div
                      id="booking-flow-cart"
                      data-qa="cart"
                      className="pzS8Sv OGOgmv Y5Bsdv whlC2v _10Cwnv Mnqemv PqhSRN"
                    >
                      <div className="h0MWJS">
                        <div className="POY5Gv XXoKXv BSVEBv dTkYBv WfxCyR">
                          <div className="hB0YAs">
                            <div className="CLnQGg _XdmmM">
                              <div className="b6xJNc J0Qdgc GmK7bc W-cbgF">
                                <picture className="rC5TQo _-rOGgo oN-sZo gsRUJF GmKi4F">
                                  <img
                                    src={storeDetails?.store?.image}
                                    alt={storeDetails?.store?.name}
                                    className="N3bmGo _-rOGgo p0zJqo gsRUJF GmKi4F object-fit-cover"
                                    loading="lazy"
                                  />
                                </picture>
                              </div>
                            </div>
                            <div
                              className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa"
                              style={{ minWidth: "0px" }}
                            >
                              <p className="_-wKhBx font-default-body-s-semibold">
                                {storeDetails?.store?.name}
                              </p>
                              <div
                                className="gQF-0-"
                                id="rating"
                                data-qa="rating"
                              >
                                <div className="aZptzi I-8Vzi">
                                  <p id="rating-aria" className="qTs2Ai">
                                    {storeDetails?.store?.rating} rating
                                  </p>
                                  <div className="ADCqki I-8Vzi">
                                    <span
                                      aria-hidden="true"
                                      className="_-wKhBx rfrUux _9CRQ7x font-default-body-s-semibold"
                                    >
                                      {storeDetails?.store?.rating}
                                    </span>
                                    <div
                                      className="mKS7Nt I-8Lrt"
                                      aria-hidden="true"
                                    >
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                    </div>
                                    <span
                                      aria-hidden="true"
                                      className="_-wKhBx rfrUux font-default-body-s-regular MRYHBB I-8UmB"
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <p className="_-wKhBx u4x-xx ZCvJqx KfKZXx font-default-body-s-regular">
                                {storeDetails?.store?.location}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv ZRg0kv _8WTxMv zsU6-w">
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Overview
                          </p>
                          <ul className="_5zCVQX gap-default-100 direction-default-vertical display-default-flex">
                            {services?.length > 0 &&
                              services?.map((service) => (
                                <>
                                  <li className="p_e4TX">
                                    <div
                                      data-qa="booking-info-service-colour-consultation"
                                      className="yhiMeE EuGs2E"
                                    >
                                      <div className="_6lhOdE">
                                        <div className="_002iGE">
                                          <div className="_nTKkE">
                                            <div className="_2rJeYE">
                                              <p
                                                data-qa="list-item-name"
                                                className="_-wKhBx font-default-body-s-medium"
                                              >
                                                {service?.name}
                                              </p>
                                              <p
                                                data-qa="list-item-description"
                                                className="_-wKhBx u4x-xx font-default-body-s-regular"
                                              >
                                                {service?.duration
                                                  ? service?.duration + "min"
                                                  : ""}
                                              </p>
                                            </div>
                                            <div className="AzPtSE beZrJE">
                                              <div className="Yer71q">
                                                <p
                                                  data-qa="list-item-retail-price"
                                                  className="_-wKhBx rfrUux font-default-body-s-medium"
                                                >
                                                  {service?.value
                                                    ? service?.value + " PKR"
                                                    : ""}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              ))}

                            <li className="p_e4TX">
                              <div
                                data-qa="booking-info-service-colour-consultation"
                                className="yhiMeE EuGs2E"
                              >
                                <div className="_6lhOdE">
                                  <div className="_002iGE">
                                    <div className="_nTKkE">
                                      <div className="_2rJeYE">
                                        <p
                                          data-qa="list-item-name"
                                          className="_-wKhBx font-default-body-s-medium"
                                        >
                                          Total Duration
                                        </p>
                                      </div>
                                      <div className="AzPtSE beZrJE">
                                        <div className="Yer71q">
                                          <p
                                            data-qa="list-item-retail-price"
                                            className="_-wKhBx rfrUux font-default-body-s-medium"
                                          >
                                            {totalDuration + " Minutes"}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>

                            {/* totalDuration */}
                          </ul>
                        </div>
                        <div className="POY5Gv XXoKXv c0imxv _8WTxMv oEA38h">
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 pz1vF1 oM6NSq"
                          />
                          <div className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa">
                            <div className="yhiMeE EuGs2E">
                              <div className="_6lhOdE">
                                <div className="_002iGE">
                                  <div className="_nTKkE">
                                    <div className="_2rJeYE">
                                      <p className="_-wKhBx font-default-body-m-semibold">
                                        Total
                                      </p>
                                    </div>
                                    <div className="AzPtSE beZrJE">
                                      <p
                                        data-qa="total-price"
                                        className="_-wKhBx font-default-body-m-semibold"
                                      >
                                        {totalAmount} PKR
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv _5l5B-v _8WTxMv vM7X54">
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Appointment
                          </p>
                          {staff && (
                            <div
                              data-qa="cart-employee-info"
                              className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex lx2Dn1 mt-2"
                            >
                              <div
                                className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="b6xJNc _SbjAc _1WMFBc _0alsKn">
                                {staffImageError ? (
                                    <div className="noStaff">{staff?.name?.charAt(0).toUpperCase()}</div>
                                  ) : (
                                    <img
                                      src={staff?.image}
                                      className="img-fluid object-fit-cover "
                                      style={{ height: "100%" }}
                                      alt=""
                                      onError={() => {
                                        setStaffImageError(true);
                                      }}
                                    />
                                  )}
                                </div>
                                <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-first-name"
                                      className="_-wKhBx font-default-body-s-medium"
                                    >
                                      {staff?.name}
                                    </p>
                                  </li>
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-title"
                                      className="_-wKhBx u4x-xx font-default-body-s-regular"
                                    >
                                      {staff?.title}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="POY5Gv XXoKXv QpAXqv xv4qgv GNmo0c">
                          <button
                            id="button-continue-button"
                            data-qa="continue-button"
                            type="button"
                            className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU"
                            aria-pressed="false"
                            aria-labelledby="label-continue-button"
                            aria-disabled="false"
                            aria-live="polite"
                            onClick={() => {
                              if (services?.length > 0) {
                                navigate("/salon/select-staff");
                              } else {
                                toast.error("Please Select Service", { id: 1 });
                              }
                            }}
                          >
                            <span className="G9I_8U">
                              <span
                                id="label-continue-button"
                                className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                              >
                                Continue
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="MZ1PW7" />
              </div>
              <div
                data-qa="bottom-bar"
                className="gMnlBP bjDb5P xa-C1P DETZNP zFOHKP fixed-bottom  display-default-block display-laptop-none"
              >
                <div className="K9p22P">
                  <ul
                    className="_5zCVQX gap-default-0 direction-default-horizontal display-default-flex"
                    style={{ justifyContent: "space-between", flexGrow: 1 }}
                  >
                    <li className="p_e4TX">
                      <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                        <li className="p_e4TX">
                          <p className="_-wKhBx font-default-body-s-semibold">
                            Total {totalAmount} PKR
                          </p>
                        </li>
                        <li className="p_e4TX">
                          <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                            {services?.length} services
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="p_e4TX">
                      <button
                        onClick={() => {
                          if (services?.length > 0) {
                            navigate("/salon/select-staff");
                          } else {
                            toast.error("Please Select Service", { id: 1 });
                          }
                        }}
                        id="button-react-aria-106"
                        data-qa="continue-button-mobile"
                        className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU JKAulU"
                        type="button"
                        aria-pressed="false"
                        aria-labelledby="label-react-aria-106"
                        aria-live="polite"
                      >
                        <span className="G9I_8U">
                          <span
                            id="label-react-aria-106"
                            className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                          >
                            Continue
                          </span>
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppointmentStepTwo;
