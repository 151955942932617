import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import ForgotPassword from "./screens/ForgotPassword";
// import SalonDescritption from "./screens/SalonDescritption";
// import SelectService from "./screens/SelectService";
// import SelectStaff from "./screens/SelectStaff";
// import BookAppointment from "./screens/BookAppointment";
// import SalonReciptScreen from "./screens/SalonReciptScreen";
// import Bookings from "./screens/Bookings";
import Payment from "./screens/Payment";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./home/home/Home";
import Appointment from "./home/appointment/Appointment";
import AppointmentStepTwo from "./home/appointmentStepTwo/AppointmentStepTwo";
import AppointmentStepThree from "./home/appointmentStepThree/AppointmentStepThree";
import AppointmentStepFour from "./home/appointmentStepFour/AppointmentStepFour";
import AppointmentHistory from "./home/appointment-history/AppointmentHistory";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SearchResult from "./home/home/SearchResult";
import AppointmentStepSix from "./home/appointmentStepSix/AppointmentStepSix";
import FavoriteSalons from "./home/home/FavoriteSalons";
import UserProfile from "./home/home/UserProfile";
import ChatBot from "./home/ChatBot";
import { io } from "socket.io-client";
import UserSalonChat from "./home/home/UserSalonChat";

const socket = io(process.env.REACT_APP_SERVER_URL);

function App() {
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state?.store);

  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && pathname.includes("booking")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const handleGlobalMouseDown = (event) => {
      const menuParent = document.getElementById("menu-parent");
      if (
        showMenu &&
        event.target !== event.currentTarget &&
        (!menuParent || !menuParent.contains(event.target))
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleGlobalMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleGlobalMouseDown);
    };
  }, [showMenu]);

  const [isChatOpen, setIsChatOpen] = useState(false);


  return (
    <div className="App">
      {loading && <div id="cover-spin"></div>}
      <ChatBot
        isChatOpen={isChatOpen}
        setIsChatOpen={setIsChatOpen}
      />
      <Routes>
        <Route
          path="/"
          element={<Home showMenu={showMenu} setShowMenu={setShowMenu} />}
        />
        <Route
          path="*"
          element={<Home showMenu={showMenu} setShowMenu={setShowMenu} />}
        />
        <Route path="/salon/:slug" element={<Appointment />} />
        <Route path="/salon/select-service" element={<AppointmentStepTwo />} />
        <Route path="/salon/select-staff" element={<AppointmentStepThree />} />
        <Route path="/salon/select-time" element={<AppointmentStepFour />} />
        <Route path="/salon/confirm" element={<AppointmentStepSix />} />
        {user && (
          <Route
            path="/salon/bookings"
            element={
              <AppointmentHistory
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
            }
          />
        )}
        {user && (
          <Route
            path="/salon/favorite"
            element={
              <FavoriteSalons showMenu={showMenu} setShowMenu={setShowMenu} />
            }
          />
        )}
        {user && (
          <Route
            path="/salon/chat"
            element={
              <UserSalonChat socket={socket} showMenu={showMenu} setShowMenu={setShowMenu} />
            }
          />
        )}
        {user && (
          <Route
            path="/user/profile"
            element={
              <UserProfile showMenu={showMenu} setShowMenu={setShowMenu} />
            }
          />
        )}
        {/* <Route path="/salon-details/:id" element={<SalonDescritption />} />
        <Route path="/salon/select-service" element={<SelectService />} />
        <Route path="/salon/recipt" element={<SalonReciptScreen />} />
        <Route path="/salon/select-staff" element={<SelectStaff />} />
        <Route path="/salon/book-appointment" element={<BookAppointment />} /> */}
        <Route path="/salon/payment" element={<Payment />} />
        <Route
          path="/salon/search"
          element={
            <SearchResult showMenu={showMenu} setShowMenu={setShowMenu} />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
      </Routes>
      <ScrollToTop />
    </div>
  );
}
export default App;
