import React from "react";
import { useNavigate } from "react-router-dom";

const StoreCards = ({ store }) => {
  const navigate = useNavigate();
  return (
    <>
      {store?.length > 0 &&
        store?.map((salon, i) => (
          <div className="col-sm-6 col-md-4 col-lg-3 my-3 mdFLex" key={i}>
            <div
              className="BbJlYx"
              onClick={() => {
                navigate(`/salon/${salon?.slug}`);
              }}
            >
              <div
                className="CLnQGg _XdmmM pzS8Sv OGOgmv M1UcMv whlC2v _6DdyDa IL3Tia _52Hhta"
                id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii"
              >
                <div className="outerWrapper">
                  <img
                    src={salon?.image}
                    alt="SliderCuts"
                    className="img-fluid salonMainImage"
                    loading="lazy"
                  />
                </div>
                <div className="POY5Gv XXoKXv TIeclv hC0kLv">
                  <h3 className="_-wKgxq rfrYcq ZCvNLq font-default-header-xs-semibold mb-default-50x PlLb3a PlLb3a">
                    {salon?.name}
                  </h3>
                  <div
                    className="gQF-0- mb-default-50x"
                    id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii-rating-block"
                  >
                    <div className="aZpowB _-YxhLB">
                      <p
                        id="location-card-slidercuts-london-slidercuts-176-hackney-road-v7k7c0ii-rating-block-aria"
                        className="qTsy4B"
                      >
                        4.9 rating with 2446 votes
                      </p>
                      <div className="ADCqxB _-YxhLB">
                        <span
                          aria-hidden="true"
                          className="_-wKgxq rfrYcq _9CRVlq font-default-body-xs-semibold"
                        >
                          4.9
                        </span>
                        <div className="mKSgmD _-Yx7ND" aria-hidden="true">
                          <span className="_-6pBDG Ua7BMG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                            </svg>
                          </span>
                        </div>
                        <span
                          aria-hidden="true"
                          className="_-wKgxq rfrYcq font-default-body-xs-regular MRYiy_ _-YxE4_ MRYiy_ _-YxE4_"
                        >
                          (2,446)
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className="_-wKgxq u4xwZq ZCvNLq font-default-body-xs-regular mb-default-100 text-nowrap">
                    {salon?.location}
                  </p>
                  <ul className="_5zCVQX gap-default-50x direction-default-horizontal display-default-inline-flex">
                    <li className="p_e4TX">
                      <div
                        aria-label="Barbershop"
                        className="Hs97ZQ _Sb9eQ OGOU7Q"
                      >
                        <span className="_-wKgxq rfrYcq ZCvNLq KfKHkq font-default-body-xs-medium">
                          {salon?.city}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default StoreCards;
