import React, { useEffect, useRef, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useDispatch, useSelector } from "react-redux";
import AuthModal from "../../components/AuthModal";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import imageIcon from "../../assets/images/images/imageIcon.png";
import apis from "../../services";
import { PiGenderFemaleBold } from "react-icons/pi";
import { TbPhotoEdit } from "react-icons/tb";
import { useMutation } from "@tanstack/react-query";
import { setUser } from "../../redux/slices/userSlice";
import { MdOutlineEmail } from "react-icons/md";
import { FaIdCard } from "react-icons/fa6";
import { errorValidate } from "../../Validations/errorHandle";
import { UpdateUserValidate } from "../../Validations/RegisterValidation";
// import { Spinner } from "react-bootstrap";
import Referral from "./Referral";
import RatingModal from "./RatingModal";
import { BeatLoader } from "react-spinners";

const UserProfile = ({ showMenu, setShowMenu }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { user } = useSelector((store) => store?.user);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  let [data, setData] = useState({
    name: "",
    gender: "female",
    email: "",
    role: "user",
    image: "",
  });

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      name: user?.name,
      gender: user?.gender,
      email: user?.email,
      ...(user?.image && { image: user?.image }),
    }));
    setImagePreview(user?.image);
  }, [user]);

  const { mutate, isLoading: updating } = useMutation(apis.updateUser, {
    onError: function ({ message }) {
      setError("");
      toast.error(message?.message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 200) {
        dispatch(setUser(user?.user));
        toast.success(user?.message, { id: 1 });
      }
    },
  });

  const [imagePreview, setImagePreview] = useState(null);

  const fileInputRef = useRef();
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const handleFileSelection = () => {
    const selectedFile = fileInputRef.current.files[0];
    setImagePreview(URL.createObjectURL(selectedFile));
    setData((prevData) => ({
      ...prevData,
      image: selectedFile,
    }));
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [error, setError] = useState();
  const onSubmit = (e) => {
    e.preventDefault();
    const { role, password, email, favourite, ...rest } = data || {};
    let response = errorValidate(
      UpdateUserValidate({
        ...data,
      })
    );
    if (response === true) {
      const form_data = new FormData();
      for (const [key, value] of Object.entries(rest)) {
        form_data.append(key, value);
      }

      mutate({ id: user._id, body: form_data });
    } else {
      setError(response);
    }
  };

  return (
    <>
      <div id="react" className="react--loading">
        {/* <AuthModal show={show} setShow={setShow} /> */}

        <RatingModal show={show} setShow={setShow} />
        <div className="">
          <div className="">
            <Header
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
            />
            <div className="L5Ntp6 mt-5 pt-5">
              <div className="container min-vh-50">
                <h2
                  className="fw-bolder text-center my-5"
                  onClick={() => setShow(true)}
                >
                  Profile
                </h2>
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  className="d-none"
                  onChange={handleFileSelection}
                  ref={fileInputRef}
                />
                <div className="profile_image_wrapper">
                  <img
                    src={imagePreview || imageIcon}
                    onClick={triggerFileInput}
                    className="img-fluid hc d-block mx-auto salonProfileImage"
                    alt=""
                  />
                  <TbPhotoEdit
                    onClick={triggerFileInput}
                    className="text-center hc d-block mx-auto fs-1 mt-1 inputImage"
                  />
                </div>
                <form onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3 position-relative ">
                        <label htmlFor="name" className="ms-2 mb-1">
                          Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={data?.name}
                          onChange={onChange}
                          className="form-control form-control-login iconsPadding"
                        />
                        <FaIdCard className="inputIconss" />
                        {error?.name && (
                          <p className="text-danger">{error?.name}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3 position-relative">
                        <label htmlFor="gender" className="ms-2 mb-1">
                          Gender
                        </label>
                        <select
                          id=""
                          className="form-control form-control-login iconsPadding"
                          name="gender"
                          value={data?.gender}
                          onChange={onChange}
                        >
                          <option value="" disabled="" selected="">
                            Choose...
                          </option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        <PiGenderFemaleBold className="inputIconss" />
                        {error?.gender && (
                          <p className="text-danger">{error?.gender}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3 position-relative ">
                        <label htmlFor="email" className="ms-2 mb-1">
                          Email
                        </label>
                        <input
                          type="text"
                          onChange={onChange}
                          name="email"
                          value={data?.email}
                          className="form-control form-control-login iconsPadding"
                          disabled={true}
                          readOnly={true}
                        />
                        <MdOutlineEmail className="inputIconss" />
                        {error?.email && (
                          <p className="text-danger">{error?.email}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3 mx-auto my-2">
                      <button
                        className="loginButton border-0 w-100"
                        type="submit"
                      >
                        {updating ? <BeatLoader color="#fff" /> : "UPDATE"}
                      </button>
                    </div>
                  </div>
                </form>

                <Referral user={user} />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
