import React from "react";

const TimeMobile = ({ setTimeMobile, data, setData }) => {
  return (
    <div className="fresha-partner-react-portal-wrapper">
      <div>
        <div className="display-default-block display-laptop-none">
          <div className="efxtmb vQhmf5 rPV9JO">
            <div className="mobile-time-wrapper">
              <div className="_0UuadL u0oPO3 rPV9JO" data-qa="overlay">
                <div className="RBvj7V rPV9JO">
                  <div>
                    <div className="efxtmb _9dsAbT KiIPz9 rPV9JO" role="dialog">
                      <div className="DGeIbr" data-scroll="true">
                        <div className="wh2NbF">
                          <div className="BZrTWG">
                            <button
                              onClick={() => setTimeMobile(false)}
                              id="button-nom"
                              className="bXwNAZ _XdmmM Dd8ZaL"
                              type="button"
                              aria-pressed="false"
                              aria-label="label-back-button"
                              aria-labelledby="label-nom"
                              aria-live="polite"
                            >
                              <span
                                className="_-6pwFd _8oa-nd"
                                aria-hidden="true"
                              >
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  style={{ width: "24px" }}
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </button>
                            <p className="_-wKhBx font-default-header-l-semibold LiZoI4 VLv1zl">
                              Time
                            </p>
                          </div>
                          <div className="mobile-time-wrapper">
                            <div className="kAQAWG ALEl-x">
                              <div>
                                <span
                                  tabIndex={0}
                                  style={{
                                    height: "0px",
                                    opacity: 0,
                                    pointerEvents: "none",
                                    position: "absolute",
                                    width: "0px",
                                    zIndex: -1,
                                  }}
                                />
                                <div className="nrBwy3">
                                  {/* <ul className="_5zCVQX gap-default-100 direction-default-horizontal display-default-inline-flex">
                                    <li className="p_e4TX">
                                      <div
                                        data-qa="undefined-wrapper"
                                        className="QHBvhd"
                                      >
                                        <button
                                          id="button-any-time"
                                          className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l WxGKSl"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-any-time"
                                          aria-live="polite"
                                        >
                                          <p
                                            id="label-any-time"
                                            className="_-wKhBx font-default-body-s-medium _2ptCkl"
                                          >
                                            Any time
                                          </p>
                                        </button>
                                      </div>
                                    </li>
                                    <li className="p_e4TX">
                                      <div
                                        data-qa="undefined-wrapper"
                                        className="QHBvhd"
                                      >
                                        <button
                                          id="button-morning"
                                          className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-morning"
                                          aria-live="polite"
                                        >
                                          <p
                                            id="label-morning"
                                            className="_-wKhBx font-default-body-s-medium _2ptCkl"
                                          >
                                            Morning
                                          </p>
                                        </button>
                                      </div>
                                    </li>
                                    <li className="p_e4TX">
                                      <div
                                        data-qa="undefined-wrapper"
                                        className="QHBvhd"
                                      >
                                        <button
                                          id="button-afternoon"
                                          className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-afternoon"
                                          aria-live="polite"
                                        >
                                          <p
                                            id="label-afternoon"
                                            className="_-wKhBx font-default-body-s-medium _2ptCkl"
                                          >
                                            Afternoon
                                          </p>
                                        </button>
                                      </div>
                                    </li>
                                    <li className="p_e4TX">
                                      <div
                                        data-qa="undefined-wrapper"
                                        className="QHBvhd"
                                      >
                                        <button
                                          id="button-evening"
                                          className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l"
                                          type="button"
                                          aria-pressed="false"
                                          aria-labelledby="label-evening"
                                          aria-live="polite"
                                        >
                                          <p
                                            id="label-evening"
                                            className="_-wKhBx font-default-body-s-medium _2ptCkl"
                                          >
                                            Evening
                                          </p>
                                        </button>
                                      </div>
                                    </li>
                                  </ul> */}
                                  <div className="fWGqQa _75L7da _4zQoha Aocp_a gap-default-200x fWGqQa">
                                    <div data-qa="From" className="upDE_A">
                                      <label
                                        data-qa="From-label"
                                        className="Do86vA X2Xg2A"
                                        htmlFor="input-react-aria-377"
                                      >
                                        <span className="_-wKhBx rfrUux font-default-body-s-semibold">
                                          Time
                                        </span>
                                      </label>
                                      <div
                                        data-qa="select-structure-From"
                                        className="_XdmmM _2Y13C5 xa-BG5 OGOrK5 GDqGW5"
                                        id="react-aria-377"
                                      >
                                        <div className="SF1Nz5">
                                          <span className="rZ6sI5">
                                            {" "}
                                            {data?.time ? data?.time : "From"}
                                          </span>
                                        </div>
                                        <select
                                          onChange={(e) => {
                                            setData((prev) => ({
                                              ...prev,
                                              time: e.target.value,
                                            }));
                                            setTimeMobile(false);
                                          }}
                                          value={data?.time}
                                          id="input-react-aria-377"
                                          name="From"
                                          className="c6_ax5"
                                          data-qa="select-structure-native-select-From"
                                        >
                                         
                                          <option value={"12:00am"}>
                                            12:00am
                                          </option>
                                          <option value={"1:00am"}>
                                            1:00am
                                          </option>
                                          <option value={"2:00am"}>
                                            2:00am
                                          </option>
                                          <option value={"3:00am"}>
                                            3:00am
                                          </option>
                                          <option value={"4:00am"}>
                                            4:00am
                                          </option>
                                          <option value={"5:00am"}>
                                            5:00am
                                          </option>
                                          <option value={"6:00am"}>
                                            6:00am
                                          </option>
                                          <option value={"7:00am"}>
                                            7:00am
                                          </option>
                                          <option value={"8:00am"}>
                                            8:00am
                                          </option>
                                          <option value={"9:00am"}>
                                            9:00am
                                          </option>
                                          <option value={"10:00am"}>
                                            10:00am
                                          </option>
                                          <option value={"11:00am"}>
                                            11:00am
                                          </option>
                                          <option value={"12:00pm"}>
                                            12:00pm
                                          </option>
                                          <option value={"1:00pm"}>
                                            1:00pm
                                          </option>
                                          <option value={"2:00pm"}>
                                            2:00pm
                                          </option>
                                          <option value={"3:00pm"}>
                                            3:00pm
                                          </option>
                                          <option value={"4:00pm"}>
                                            4:00pm
                                          </option>
                                          <option value={"5:00pm"}>
                                            5:00pm
                                          </option>
                                          <option value={"6:00pm"}>
                                            6:00pm
                                          </option>
                                          <option value={"7:00pm"}>
                                            7:00pm
                                          </option>
                                          <option value={"8:00pm"}>
                                            8:00pm
                                          </option>
                                          <option value={"9:00pm"}>
                                            9:00pm
                                          </option>
                                          <option value={"10:00pm"}>
                                            10:00pm
                                          </option>
                                          <option value={"11:00pm"}>
                                            11:00pm
                                          </option>
                                        </select>
                                        <div className="_1h-qMD xa-H1D OGOJOD">
                                          <span
                                            className="_-6pwFd rfr1wd I-8RVd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M6.782 9.22a.75.75 0 0 1 1.06 0L12 13.377l4.157-4.157a.75.75 0 1 1 1.06 1.06l-4.687 4.688a.75.75 0 0 1-1.06 0L6.782 10.28a.75.75 0 0 1 0-1.06Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div data-qa="To" className="upDE_A">
                                      <label
                                        data-qa="To-label"
                                        className="Do86vA X2Xg2A"
                                        htmlFor="input-react-aria-378"
                                      >
                                        <span className="_-wKhBx rfrUux font-default-body-s-semibold">
                                          To
                                        </span>
                                      </label>
                                      <div
                                        data-qa="select-structure-To"
                                        className="_XdmmM _2Y13C5 xa-BG5 OGOrK5 GDqGW5"
                                        id="react-aria-378"
                                      >
                                        <div className="SF1Nz5">
                                          <span className="rZ6sI5">To</span>
                                        </div>
                                        <select
                                          id="input-react-aria-378"
                                          name="To"
                                          className="c6_ax5"
                                          data-qa="select-structure-native-select-To"
                                        >
                                          <option value disabled>
                                            To
                                          </option>
                                          <option value={1}>1:00am</option>
                                          <option value={2}>2:00am</option>
                                          <option value={3}>3:00am</option>
                                          <option value={4}>4:00am</option>
                                          <option value={5}>5:00am</option>
                                          <option value={6}>6:00am</option>
                                          <option value={7}>7:00am</option>
                                          <option value={8}>8:00am</option>
                                          <option value={9}>9:00am</option>
                                          <option value={10}>10:00am</option>
                                          <option value={11}>11:00am</option>
                                          <option value={12}>12:00pm</option>
                                          <option value={13}>1:00pm</option>
                                          <option value={14}>2:00pm</option>
                                          <option value={15}>3:00pm</option>
                                          <option value={16}>4:00pm</option>
                                          <option value={17}>5:00pm</option>
                                          <option value={18}>6:00pm</option>
                                          <option value={19}>7:00pm</option>
                                          <option value={20}>8:00pm</option>
                                          <option value={21}>9:00pm</option>
                                          <option value={22}>10:00pm</option>
                                          <option value={23}>11:00pm</option>
                                          <option value={24}>12:00am</option>
                                        </select>
                                        <div className="_1h-qMD xa-H1D OGOJOD">
                                          <span
                                            className="_-6pwFd rfr1wd I-8RVd"
                                            aria-hidden="true"
                                          >
                                            <svg
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M6.782 9.22a.75.75 0 0 1 1.06 0L12 13.377l4.157-4.157a.75.75 0 1 1 1.06 1.06l-4.687 4.688a.75.75 0 0 1-1.06 0L6.782 10.28a.75.75 0 0 1 0-1.06Z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                                <span
                                  tabIndex={0}
                                  style={{
                                    height: "0px",
                                    opacity: 0,
                                    pointerEvents: "none",
                                    position: "absolute",
                                    width: "0px",
                                    zIndex: -1,
                                  }}
                                />
                              </div>
                            </div>
                            <div className="display-default-flex display-laptop-none FGimzb">
                              <button
                                onClick={()=>setTimeMobile(false)}
                                id="button-react-aria-375"
                                data-qa="react-aria-375"
                                className="bXwNAZ _XdmmM tPDowU _i2EgU _8oamEU WnQevU GGLUcU"
                                type="button"
                                aria-pressed="false"
                                aria-labelledby="label-react-aria-375"
                                aria-live="polite"
                              >
                                <span className="G9I_8U">
                                  <span
                                    id="label-react-aria-375"
                                    className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                                  >
                                    Cancel
                                  </span>
                                </span>
                              </button>
                              <button
                              onClick={()=>setTimeMobile(false)}
                                id="button-react-aria-376"
                                data-qa="react-aria-376"
                                className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU"
                                type="button"
                                aria-pressed="false"
                                aria-labelledby="label-react-aria-376"
                                aria-live="polite"
                              >
                                <span className="G9I_8U">
                                  <span
                                    id="label-react-aria-376"
                                    className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                                  >
                                    Done
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeMobile;
