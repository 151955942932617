import React from "react";
import moment from "moment";

const DateDesktop = ({ data, setData }) => {
  const handleDateChange = (event, date) => {
    event.stopPropagation();
    setData((prev) => ({ ...prev, date: moment(date) }));
  };

  const handleNextMonth = () => {
    setData((data) => ({
      ...data,
      date: moment(data?.date).add(1, "month"),
    }));
  };

  const handlePrevMonth = () => {
    setData((data) => ({
      ...data,
      date: moment(data?.date).subtract(1, "month"),
    }));
  };

  const inputDateFormat = "DD-MM-YYYY";

  const daysInMonth = moment(data?.date).daysInMonth();

  const firstDayOfMonth = moment(data?.date)
    .startOf("month")
    .day();
  const cells = [];

  for (let i = 0; i < firstDayOfMonth; i++) {
    cells.push(<div key={i} className="_8FZSQX sQbOfX" role="gridcell" />);
  }

  for (let i = 1; i <= daysInMonth; i++) {
    const currentDate = moment(data?.date).date(i);
  
    cells.push(
      <div
        key={i + firstDayOfMonth - 1}
        onClick={(event) => handleDateChange(event, currentDate)}
        className={`_8FZSQX sQbOfX ${
          currentDate.isSame(data?.date, "day") ? "_8FZSQX tZfwGX" : ""
        }`}
        role="gridcell"
      >
        <span aria-hidden="true" className="TCjguX">
          <span className="ij3SCX" />
          <span className="x1wR_X" />
        </span>
        <span className="S3evEX">{i}</span>
      </div>
    );
  }

  for (let i = 0; i < 7 - ((firstDayOfMonth + daysInMonth) % 7); i++) {
    cells.push(
      <div
        key={i + firstDayOfMonth + daysInMonth}
        className="_8FZSQX sQbOfX"
        role="gridcell"
      />
    );
  }

  const weeks = [];
  for (let i = 0; i < cells.length; i += 7) {
    const week = cells.slice(i, i + 7);
    weeks.push(
      <div className="_8FYVIX" role="row" key={i}>
        {week}
      </div>
    );
  }

  return (
    <div
      className="kAQAWG yjb19T IU8D5m"
      role="dialog"
      id="calanderParent"
      style={{ left: "45.4544%", width: "258px" }}
    >
      <div>
        <span
          tabIndex={0}
          style={{
            height: "0px",
            opacity: 0,
            pointerEvents: "none",
            position: "absolute",
            width: "0px",
            zIndex: -1,
          }}
        />
        <div className="d6Dpu5">
          <ul className="_5zCVQX gap-default-150x direction-default-horizontal display-default-inline-flex">
            <li className="p_e4TX">
              <div data-qa="undefined-wrapper" className="QHBvhd">
                <button
                  id="button-any-date"
                  className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l WxGKSl"
                  type="button"
                  aria-pressed="false"
                  aria-labelledby="label-any-date"
                  aria-live="polite"
                >
                  <p
                    id="label-any-date"
                    className="_-wKhBx font-default-body-s-medium _2ptCkl"
                  >
                    Any date
                  </p>
                </button>
              </div>
            </li>
            <li className="p_e4TX">
              <div data-qa="undefined-wrapper" className="QHBvhd">
                <button
                  id="button-today"
                  className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l"
                  type="button"
                  aria-pressed="false"
                  aria-labelledby="label-today"
                  aria-live="polite"
                >
                  <p
                    id="label-today"
                    className="_-wKhBx font-default-body-s-medium _2ptCkl"
                  >
                    Today
                  </p>
                </button>
              </div>
            </li>
            <li className="p_e4TX">
              <div data-qa="undefined-wrapper" className="QHBvhd">
                <button
                  id="button-tomorrow"
                  className="bXwNAZ _XdmmM X1QNMl amByfl igyz0l"
                  type="button"
                  aria-pressed="false"
                  aria-labelledby="label-tomorrow"
                  aria-live="polite"
                >
                  <p
                    id="label-tomorrow"
                    className="_-wKhBx font-default-body-s-medium _2ptCkl"
                  >
                    Tomorrow
                  </p>
                </button>
              </div>
            </li>
          </ul>
          <div className>
            <div lang="en">
              <div className="eUzqDX" tabIndex={0}>
                <div className="WThT8X">
                  <button
                    id="button-react-aria-233"
                    data-qa="react-aria-233"
                    tabIndex={-1}
                    className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU GM_kfX"
                    type="button"
                    aria-pressed="false"
                    aria-labelledby="label-react-aria-233"
                    aria-live="polite"
                    onClick={handlePrevMonth}
                  >
                    <span className="G9I_8U va37oU">
                      <span
                        id="label-react-aria-233"
                        className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                      >
                        Previous month
                      </span>
                    </span>
                    <span className="_0f0EDU">
                      <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                        <svg
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M14.968 6.782a.75.75 0 0 1 0 1.06L10.81 12l4.157 4.157a.75.75 0 1 1-1.06 1.06L9.22 12.53a.75.75 0 0 1 0-1.06l4.687-4.688a.75.75 0 0 1 1.06 0Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </span>
                  </button>
                  <button
                    id="button-react-aria-234"
                    data-qa="react-aria-234"
                    tabIndex={-1}
                    className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU Cuct_X"
                    type="button"
                    aria-pressed="false"
                    aria-labelledby="label-react-aria-234"
                    aria-live="polite"
                    onClick={handleNextMonth}
                  >
                    <span className="G9I_8U va37oU">
                      <span
                        id="label-react-aria-234"
                        className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                      >
                        Next month
                      </span>
                    </span>
                    <span className="_0f0EDU">
                      <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                        <svg
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M9.22 6.782a.75.75 0 0 1 1.06 0l4.688 4.688a.75.75 0 0 1 0 1.06l-4.688 4.688a.75.75 0 1 1-1.06-1.06L13.377 12 9.22 7.843a.75.75 0 0 1 0-1.06Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
                <div className="VwadfX">
                  <div className="nGnG_X" role="grid" data-current-month="true">
                    <div className="bx3qpX" role="heading" aria-level={3}>
                      {moment(data?.date).format("MMMM YYYY")}
                    </div>
                    <div className="XjsOkX" role="rowgroup">
                      <div className="dxcLdX" role="row">
                        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                          (day) => (
                            <div
                              key={day}
                              className="_6RoZ4X"
                              role="columnheader"
                            >
                              <abbr title={day}>{day}</abbr>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    {weeks}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          tabIndex={0}
          style={{
            height: "0px",
            opacity: 0,
            pointerEvents: "none",
            position: "absolute",
            width: "0px",
            zIndex: -1,
          }}
        />
      </div>
    </div>
  );
};

export default DateDesktop;
