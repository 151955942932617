import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Rating from "react-rating";
import { ImStarFull } from "react-icons/im";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

const RatingModal = ({ show, setShow }) => {
  const { user } = useSelector((e) => e.user);
  const [rating, setRating] = useState(5);
  const [message, setMessage] = useState("");
  const handleRating = (rate) => {
    setRating(rate);
  };

  const { mutate, isLoading } = useMutation(
    () =>
      apis.giveRating({
        // booking_Id: "6599663235da29cf2c31c9e5",
        rating: rating,
        comment: message,
        user_Id: user?._id,
      }),
    {
      onError: function ({ message }) {
        toast.error(message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          toast.success(data?.message, { id: 1 });
        }
      },
    }
  );

  return (
    <Modal centered show={show} onHide={setShow} size="md">
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>Rate the salon</Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-md-3 text-start">
        <div className="d-flex justify-content-center ">
          <Rating
            initialRating={rating}
            onChange={handleRating}
            emptySymbol={
              <ImStarFull className="empty-star fs-1 m-1" color="#CCCCCC" />
            }
            fullSymbol={
              <ImStarFull className="full-star fs-1 m-1" color="#FFD700" />
            }
            fractions={2}
          />
        </div>

        <textarea
          className="comentTextArea"
          placeholder="Comments..."
          rows="5"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>

        <div className="col-md-3 mx-auto my-2">
          <button
            className="loginButton border-0 w-100"
            type="submit"
            onClick={mutate}
            disabled={isLoading}
          >
            {isLoading ? <BeatLoader color="#fff" /> : "Submit"}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RatingModal;
