import React, { useEffect, useState } from "react";
import "../home.css";
import "../client.c7918688.css";
import "../src_widgets_StickyBar_index_ts.d212fab5.chunk.css";
import "../design-system.dd5c5e65.css";
import "../static-pages-chnk.5b17223c.chunk.css";
import "../vendor.bbe55722.css";
import "../design-system.e4a4ab19.css";
import "../client.417b6a9f.css";
import "../vendor.9daa5ce5.css";
import Header from "../Header";
import Footer from "../Footer";
import TreatmentMobile from "../../shared/components/TreatmentMobile";
import SearchLocationMobile from "../../shared/components/SearchLocationMobile";
import DateMobile from "../../shared/components/DateMobile";
import TimeMobile from "../../shared/components/TimeMobile";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/userSlice";
import AuthModal from "../../components/AuthModal";
import apis from "../../services";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import BookingDetailModal from "./BookingDetailModal";

const AppointmentHistory = ({ showMenu, setShowMenu }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [treatmentMobile, setTreatmentMobile] = useState(false);
  const [locationMobile, setLocationMobile] = useState(false);
  const [dateMobile, setDateMobile] = useState(false);
  const [timeMobile, setTimeMobile] = useState(false);
  const [login, setLogin] = useState(false);
  const { user } = useSelector((e) => e?.user);
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState("upcoming");
  const navigate = useNavigate();
  const [booking, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [bookingDetails, setBookingDetails] = useState("");

  const showBookingDetails = (data) => {
    setBookingDetails(data);
    setShowDetails(true);
  };

  const getBookings = async () => {
    try {
      setLoading(true);
      const bookings = await apis.getUserBookings(user?._id);
      if (bookings?.data?.booking) {
        setBookings(bookings?.data?.booking);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  const cancelBooking = async (id) => {
    try {
      setLoading(true);
      const bookings = await apis.cancelBooking({
        user_Id: user?._id,
        booking_Id: id,
      });
      if (bookings?.status === 200) {
        toast.success(bookings?.data?.message);
        getBookings();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "error");
    }
  };

  const filters = booking?.filter((e) =>
    current === "upcoming"
      ? e?.isCheckIn === false && e?.isCancel === false
      : current === "cancelled"
      ? e?.isCancel === true
      : current === "completed"
      ? e?.isCheckIn === true && e?.isCancel === false
      : false
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!user?.isVerified) {
      navigate("/");
    }
    getBookings();
  }, []);

  return (
    <>
      <BookingDetailModal
        show={showDetails}
        setShow={setShowDetails}
        data={bookingDetails}
      />
      <div id="react" className="react--loading">
        {loading && <div id="cover-spin"></div>}

        <AuthModal show={show} setShow={setShow} />
        <div className="GXRSVM notranslate">
          <div className="_32iRow">
            <Header
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              login={login}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              setLogin={setLogin}
            />

            <div className="L5Ntp6">
              {/* <div className="_4jKGSa">
                <div className="SWiJzX -cUJrF">
                  <div className="SWiJzX MQH7Px">
                    <span className="_2JTEU2 nkQSL5" />
                    <span className="_2JTEU2 rK-SRb" />
                    
                  </div>
                </div>
              </div> */}
              {/* booking */}
              <div className="pt-5 mt-5"></div>
              <div className="d-flex justify-content-center">
                <button
                  onClick={() => setCurrent("completed")}
                  className={`${
                    current === "completed" ? "bookingBtn-active" : "bookingBtn"
                  }`}
                >
                  Completed
                </button>
                <button
                  onClick={() => setCurrent("upcoming")}
                  className={`${
                    current === "upcoming" ? "bookingBtn-active" : "bookingBtn"
                  }`}
                >
                  Upcoming
                </button>
                <button
                  onClick={() => setCurrent("cancelled")}
                  className={`${
                    current === "cancelled" ? "bookingBtn-active" : "bookingBtn"
                  }`}
                >
                  Cancelled
                </button>
              </div>
              <div className="container bookingHeight">
                <div className="row mx-auto mt-5">
                  {filters?.length > 0 ? (
                    filters?.map((book, i) => (
                      <div className="col-md-6 col-lg-4">
                        <div className="historyCard">
                          <div className="d-flex align-items-start">
                            <div className="historyCard-img">
                              <img src={book?.store_Id?.image} alt="" />
                            </div>
                            <div>
                              <h6 className="m-0">{book?.store_Id?.name}</h6>
                              <p className="fs-14 m-0 text-nowrap ">
                                {book?.store_Id?.location}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mt-3">
                            <div className="d-flex flex-column align-items-center">
                              <h6 className="m-0">Start Time</h6>
                              <p className="fs-14 m-0 text-nowrap ">
                                {book?.time}
                              </p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <h6 className="m-0">Amount</h6>
                              <p className="fs-14 m-0 text-nowrap ">
                                {book?.amount} PKR
                              </p>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <h6 className="m-0">End Time</h6>
                              <p className="fs-14 m-0 text-nowrap ">
                                {book?.end}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex mt-3 justify-content-between flex-gap">
                            <button
                              onClick={() => showBookingDetails(book)}
                              type="button"
                              className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU detailsButton bg-success border-0 "
                            >
                              <span className="G9I_8U">
                                <span
                                  id="label-continue-button"
                                  className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                                >
                                  Details
                                </span>
                              </span>
                            </button>
                            {!book?.isCancel && !book?.isCheckIn && (
                              <button
                                onClick={() => cancelBooking(book?._id)}
                                type="button"
                                className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU detailsButton bg-danger"
                              >
                                <span className="G9I_8U">
                                  <span
                                    id="label-continue-button"
                                    className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                                  >
                                    Cancel
                                  </span>
                                </span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h1 className="text-center pt-5">
                      {loading ? "" : "No Bookings found"}
                    </h1>
                  )}
                </div>
              </div>
              <div className="pt-5 mt-5"></div>
              <Footer />
            </div>
          </div>
        </div>
      </div>

      {showDropdown && (
        <>
          {!login ? (
            <div
              className="XXCymX sXnfiX xa-QVX JjHJ9X"
              style={{
                minWidth: "200px",
                position: "absolute",
                zIndex: 100000,
                right: "0px",
                top: "77px",
                maxHeight: "400px",
              }}
            >
              <div
                className="EYPJ2X"
                style={{ right: "0px", top: "77px", maxHeight: "240px" }}
              >
                <ul
                  id="react-aria-1178"
                  aria-labelledby="react-aria-1177"
                  role="menu"
                  tabIndex={-1}
                  className="_8TerEn"
                >
                  <li
                    data-qa="menu-items"
                    role="presentation"
                    className="SvLLjn"
                  >
                    <p
                      id="react-aria-1495"
                      role="presentation"
                      className="_-wKhBx rfrUux font-default-body-m-semibold font-laptop-body-s-semibold DrmJ1n"
                    >
                      <span className="Xt5KTu text-capitalize ">
                        Hi, {user?.name}
                      </span>
                    </p>
                    <ul
                      aria-labelledby="react-aria-1495"
                      role="group"
                      className="rmG1Kn"
                    >
                      <li
                        data-qa="dropdown-btn-info-profile"
                        className="fK-93n OGO9an wtRMzb"
                      >
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                            Profile
                          </p>
                        </div>
                      </li>
                      <li
                        data-qa="menu-item-my-appointments"
                        className="fK-93n OGO9an wtRMzb"
                      >
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                            Appointments
                          </p>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li role="presentation" className="SvLLjn">
                    <ul role="group" className="rmG1Kn">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.beautowns"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <li className="fK-93n OGO9an wtRMzb">
                          <div className="aHMJCn">
                            <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                              Download the app
                            </p>
                          </div>
                        </li>
                      </a>

                      {/* <li
                        role="menuitem"
                        tabIndex={-1}
                        data-key="language-switch"
                        className="fK-93n OGO9an wtRMzb"
                      >
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                            🇬🇧 English
                          </p>
                        </div>
                      </li> */}
                      <li
                        onClick={() => {
                          setShowDropdown(false);
                          dispatch(logout());
                        }}
                        data-qa="menu-item-logout"
                        role="menuitem"
                        tabIndex={-1}
                        data-key="logout"
                        className="fK-93n OGO9an wtRMzb"
                      >
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                            Logout
                          </p>
                        </div>
                      </li>
                      <li className="mC_J8n" aria-hidden="true">
                        <hr
                          aria-orientation="horizontal"
                          className="KT-if1 CChMQ1 Sm3CD1 PiTGa1"
                        />
                      </li>
                    </ul>
                  </li>
                  {/* <li role="presentation" className="SvLLjn">
                    <ul role="group" className="rmG1Kn">
                      <li className="fK-93n OGO9an wtRMzb">
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-semibold font-laptop-body-s-semibold">
                            Fresha for business
                          </p>
                        </div>
                      </li>
                      <li className="mC_J8n" aria-hidden="true">
                        <hr
                          aria-orientation="horizontal"
                          className="KT-if1 CChMQ1 Sm3CD1 PiTGa1"
                        />
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>
          ) : (
            <div
              className="XXCymX sXnfiX xa-QVX JjHJ9X"
              style={{
                minWidth: "200px",
                position: "absolute",
                zIndex: 100000,
                right: "0px",
                top: "77px",
                maxHeight: "400px",
              }}
            >
              <div className="_7ixdAX" />
              <div
                className="EYPJ2X"
                style={{ right: "0px", top: "77px", maxHeight: "240px" }}
              >
                <div className="w997NX" aria-hidden="true">
                  <span className="ToEGtX" />
                </div>
                <span data-focus-scope-start="true" hidden />
                <ul
                  id="react-aria-3"
                  aria-labelledby="react-aria-2"
                  role="menu"
                  tabIndex={-1}
                  className="_8TerEn"
                >
                  <li role="presentation" className="SvLLjn">
                    <ul role="group" className="rmG1Kn">
                      <li
                        data-qa="menu-item-log-in"
                        className="fK-93n OGO9an wtRMzb _XdmmM"
                      >
                        <div
                          className="CLnQGg eQ2tJn"
                          data-key="login"
                          onClick={() => setLogin(true)}
                          role="menuitem"
                          data-qa="action-menu-item-log-in"
                          tabIndex={0}
                        />
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                            Log in
                          </p>
                        </div>
                      </li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.beautowns"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <li className="fK-93n OGO9an wtRMzb">
                          <div
                            className="CLnQGg eQ2tJn"
                            data-key="download-the-app"
                            role="menuitem"
                            tabIndex={-1}
                          />
                          <div className="aHMJCn">
                            <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                              Download the app
                            </p>
                          </div>
                        </li>
                      </a>
                      {/* <li className="fK-93n OGO9an wtRMzb">
                        <div
                          className="CLnQGg eQ2tJn"
                          data-key="customer-support"
                          role="menuitem"
                          tabIndex={-1}
                        />
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                            Customer support
                          </p>
                        </div>
                      </li> */}
                      <li
                        role="menuitem"
                        tabIndex={-1}
                        data-key="language-switch"
                        className="fK-93n OGO9an wtRMzb"
                      >
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-medium font-laptop-body-s-medium">
                            English
                          </p>
                        </div>
                      </li>
                      <li className="mC_J8n" aria-hidden="true">
                        <hr
                          aria-orientation="horizontal"
                          className="KT-if1 CChMQ1 Sm3CD1 PiTGa1"
                        />
                      </li>
                    </ul>
                  </li>
                  <li role="presentation" className="SvLLjn">
                    <ul role="group" className="rmG1Kn">
                      <li className="fK-93n OGO9an wtRMzb">
                        <div
                          className="CLnQGg eQ2tJn"
                          data-key="$.1.0"
                          target="_self"
                          role="menuitem"
                          tabIndex={-1}
                        />
                        <div className="aHMJCn">
                          <p className="_-wKhBx rfrUux font-default-body-m-semibold font-laptop-body-s-semibold">
                            Beautowns for business
                          </p>
                        </div>
                      </li>
                      <li className="mC_J8n" aria-hidden="true">
                        <hr
                          aria-orientation="horizontal"
                          className="KT-if1 CChMQ1 Sm3CD1 PiTGa1"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div
                  style={{
                    border: "0px",
                    clip: "rect(0px, 0px, 0px, 0px)",
                    clipPath: "inset(50%)",
                    height: "1px",
                    margin: "-1px",
                    overflow: "hidden",
                    padding: "0px",
                    position: "absolute",
                    width: "1px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <button
                    id="react-aria-264"
                    aria-label="Dismiss"
                    tabIndex={-1}
                  />
                </div>
                <span data-focus-scope-end="true" hidden />
              </div>
            </div>
          )}
        </>
      )}
      {treatmentMobile && (
        <TreatmentMobile setTreatmentMobile={setTreatmentMobile} />
      )}
      {locationMobile && (
        <SearchLocationMobile setLocationMobile={setLocationMobile} />
      )}
      {dateMobile && <DateMobile setDateMobile={setDateMobile} />}
      {timeMobile && <TimeMobile setTimeMobile={setTimeMobile} />}
    </>
  );
};

export default AppointmentHistory;
