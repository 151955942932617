import React from "react";
import dashboard from "../../assets/images/images/BeautownsDashbaord.png";

function BusinessPartner() {
  return (
    <div className="lJh45t">
      <div className="cpj9Q+ _5-TPbG">
        <div className="p0zn2V _5-TPbG">
          <section className="WSchAe">
            <div className="_9kTURq GepCJh">
              <div className="kVUtYD">
                <h2 className="_-wKgxq font-default-display-m-bold font-laptop-display-xl-bold mb-default-150x mb-laptop-300x">
                  Beautowns for Business
                </h2>
                <p className="_-wKgxq font-default-body-s-regular font-laptop-body-l-regular mb-default-300x">
                  Supercharge your business for free with the world's top
                  booking platform for salons and spas. Independently voted no.
                  1 by industry professionals.
                </p>
              </div>
              <div className="TsVEOJ">
                <div className="yLABQm">
                  <img src={dashboard} className=" dashboardImage" alt="" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default BusinessPartner;
