import React from "react";
import logo from "../../src/assets/images/logo/new-logo.png";
import moment from "moment";
import { now } from "moment/moment";

function Footer() {
  return (
    <>
      <div className="LroeFo">
        <footer className="gA46Mc">
          <div className="OaefOb">
            <ul className="_5zCVQX gap-default-300x gap-laptop-600x direction-default-vertical display-default-inline-flex MgV07m">
              <li className="p_e4TX">
                <img src={logo} alt="" width={"180px"} />
              </li>

              <li className="p_e4TX">
                <a
                  className="CLnQGg _XdmmM tPDowU _i2EgU _8oamEU _2qQHCU JKAulU"
                  id="download-app-btn-footer"
                  href="https://play.google.com/store/apps/details?id=com.beautowns"
                  target="_self"
                  data-qa="download-app-btn-footer"
                >
                  <span className="G9I_8U">
                    <span
                      id="label-download-app-btn-footer"
                      className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                    >
                      Get the app
                    </span>
                  </span>
                  <span className="_0f0EDU">
                    <span className="_-6pBDG _8oamQG" aria-hidden="true">
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12.003 2.4C6.7 2.4 2.4 6.698 2.4 12s4.3 9.6 9.603 9.6c8.008 0 9.812-7.434 9.061-11.2H12v3.2h6.19A6.398 6.398 0 0 1 12 18.4a6.4 6.4 0 0 1 0-12.8c1.607 0 3.071.597 4.195 1.575l2.274-2.272A9.568 9.568 0 0 0 12.003 2.4Z" />
                      </svg>
                    </span>
                  </span>
                </a>
              </li>
            </ul>
            <ul className="_5zCVQX gap-default-150x direction-default-vertical display-default-inline-flex MgV07m">
              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-m-semibold">
                  About Beautowns
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-careers"
                    id="footer-careers"
                  >
                    Careers
                  </div>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-customer-support"
                    id="footer-customer-support"
                  >
                    Customer Support
                  </div>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-blog"
                    id="footer-blog"
                  >
                    Blog
                  </div>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-sitemap"
                  >
                    Sitemap
                  </div>
                </p>
              </li>
            </ul>
            <ul className="_5zCVQX gap-default-150x direction-default-vertical display-default-inline-flex MgV07m">
              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-m-semibold">
                  For business
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <a
                    className="CLnQGg _XdmmM ZzVQ7B"
                    href="https://dashboard.beautowns.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    For partners
                  </a>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-pricing"
                    id="footer-pricing"
                  >
                    Pricing
                  </div>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-partners-support"
                    id="footer-partners-support"
                  >
                    Support
                  </div>
                </p>
              </li>
            </ul>
            <ul className="_5zCVQX gap-default-150x direction-default-vertical display-default-inline-flex MgV07m">
              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-m-semibold">
                  Legal
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-privacy-policy"
                    id="footer-privacy-policy"
                  >
                    Privacy Policy
                  </div>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-service-terms"
                    id="footer-service-terms"
                  >
                    Terms of service
                  </div>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div
                    className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                    data-qa="footer-use-terms"
                    id="footer-use-terms"
                  >
                    Terms of use
                  </div>
                </p>
              </li>
            </ul>
            <ul className="_5zCVQX gap-default-150x direction-default-vertical display-default-inline-flex MgV07m">
              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-m-semibold">
                  Find us on social
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <a
                    className="CLnQGg _XdmmM ZzVQ7B"
                    href="https://www.facebook.com/profile.php?id=61555036463248"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="S25v1b">
                      <span className="_-6pBDG I-8MNG" aria-hidden="true">
                        <svg
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.97 4.72a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 0 1 0 1.06l-6.75 6.75a.75.75 0 1 1-1.06-1.06l5.47-5.47H3.75a.75.75 0 0 1 0-1.5h14.69l-5.47-5.47a.75.75 0 0 1 0-1.06Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </span>
                    Facebook
                  </a>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <a
                    className="CLnQGg _XdmmM ZzVQ7B"
                    href="https://twitter.com/Beautowns1403"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="S25v1b">
                      <span className="_-6pBDG I-8MNG" aria-hidden="true">
                        <svg
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.97 4.72a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 0 1 0 1.06l-6.75 6.75a.75.75 0 1 1-1.06-1.06l5.47-5.47H3.75a.75.75 0 0 1 0-1.5h14.69l-5.47-5.47a.75.75 0 0 1 0-1.06Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </span>
                    Twitter
                  </a>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <div className="CLnQGg _XdmmM ZzVQ7B">
                    <span className="S25v1b">
                      <span className="_-6pBDG I-8MNG" aria-hidden="true">
                        <svg
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.97 4.72a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 0 1 0 1.06l-6.75 6.75a.75.75 0 1 1-1.06-1.06l5.47-5.47H3.75a.75.75 0 0 1 0-1.5h14.69l-5.47-5.47a.75.75 0 0 1 0-1.06Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </span>
                    Linkedin
                  </div>
                </p>
              </li>

              <li className="p_e4TX">
                <p className="_-wKgxq rfrYcq font-default-body-s-regular">
                  <a
                    className="CLnQGg _XdmmM ZzVQ7B"
                    href="https://www.instagram.com/beautowns_/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="S25v1b">
                      <span className="_-6pBDG I-8MNG" aria-hidden="true">
                        <svg
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.97 4.72a.75.75 0 0 1 1.06 0l6.75 6.75a.75.75 0 0 1 0 1.06l-6.75 6.75a.75.75 0 1 1-1.06-1.06l5.47-5.47H3.75a.75.75 0 0 1 0-1.5h14.69l-5.47-5.47a.75.75 0 0 1 0-1.06Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </span>
                    Instagram
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </footer>
        <footer className="xBA7Ha">
          <div className="tyiR60">
            <p className="_-wKgxq font-default-body-s-regular">
              <div
                className="CLnQGg _XdmmM c2b9YA POrQ0A _XdmmM"
                data-qa="footer-language-selector"
                id="footer-language-selector"
              >
                English
              </div>
            </p>
            <div className>
              <p className="_-wKgxq u4xwZq font-default-body-s-regular">
                © {moment().format("YYYY")} Beautowns.com
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footer;
