import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { setUser } from "../../redux/slices/userSlice";
import toast from "react-hot-toast";
import { loginValidate } from "../../Validations/LoginValidation";
import { errorValidate } from "../../Validations/errorHandle";
import apis from "../../services";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { BeatLoader } from "react-spinners";

const LoginForm = ({ setCurrent, setShow }) => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  let [data, setData] = useState({ email: "", password: "", role: "user" });
  const onChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  let [error, setError] = useState();
  console.log(error, "error");

  const { mutate, isLoading } = useMutation(apis.authLogin, {
    onError: function ({ message }) {
      setError("");
      toast.error(message, { id: 1 });
    },
    onSuccess: ({ data: user, status }) => {
      if (status === 200) {
        if (user?.user?.isVerified) {
          setShow(false);
          setCurrent("options");
          dispatch(setUser(user?.user));
          toast.success(user?.message, { id: 1 });
        }
      }
    },
  });

  const signIn = (e) => {
    e.preventDefault();
    let response = errorValidate(loginValidate(data));
    if (response === true) {
      mutate(data);
    } else {
      setError(response);
    }
  };
  return (
    <>
      <form onSubmit={signIn}>
        <h1 className="text-center">Login</h1>
        <div className="mb-3">
          <label htmlFor="email" className="ms-2 mb-1">
            Email
          </label>
          <input
            type="text"
            name="email"
            className="form-control form-control-login"
            placeholder="user@example.com"
            onChange={onChange}
          />
          {error?.email && <p className="text-danger ms-1">{error?.email}</p>}
        </div>
        <div className="mb-3 position-relative">
          <label htmlFor="password" className="ms-2 mb-1">
            Password
          </label>
          <input
            name="password"
            type={showPassword ? "text" : "password"}
            className="form-control form-control-login"
            placeholder="**********"
            onChange={onChange}
          />
          <div
            className="eyeDiv"
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? (
              <FaRegEyeSlash className="eyeIcon" />
            ) : (
              <FaRegEye className="eyeIcon" />
            )}
          </div>
        </div>
        {error?.password && (
          <p className="text-danger password-error ms-1">{error?.password}</p>
        )}

        <p className="text-end m-0 mb-2">
          <Link to="" onClick={() => setCurrent("forgot")}>
            Forgot Password ?
          </Link>
        </p>
        <button className="loginButton border-0 w-100" type="submit">
          {isLoading ? 
          // <Spinner animation="border" />
          <BeatLoader color="#fff" />
           : "LOGIN"}
        </button>
      </form>
      <p className="text-center mb-0 my-4">
        Have No Account?{" "}
        <b>
          <Link to="" onClick={() => setCurrent("signup")}>
            SignUp Now!
          </Link>
        </b>
      </p>
    </>
  );
};

export default LoginForm;
