import React, { useEffect, useState, useRef } from "react";
import TreatmentDesktop from "../../shared/components/TreatmentDesktop";
import DateDesktop from "../../shared/components/DateDesktop";
// import TimeDesktop from "../../shared/components/TimeDesktop";
import qrCode from "../../assets/images/images/QRCODE.jpg";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setStores } from "../../redux/slices/storeSlice";
import { Spinner } from "react-bootstrap";

function Section1({
  data,
  setData,
  treatmentMobile,
  setTreatmentMobile,
  locationMobile,
  setLocationMobile,
  dateMobile,
  setDateMobile,
  timeMobile,
  setTimeMobile,
  showCalander,
  setShowCalander,
}) {
  const [treatment, setTreatment] = useState(false);
  // const [date, setDate] = useState(false);
  const [desktopTime, setDesktopTime] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) => {
    let { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const dispatch = useDispatch();
  const { mutate, isLoading: isSearching } = useMutation(apis.searchSalon, {
    onSuccess: ({ data, status }) => {
      if (status === 200) {
        dispatch(setStores(data?.salonResults));
      }
    },
  });

  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const options = {
    componentRestrictions: { country: "pk" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"],
  };

  useEffect(() => {
if(window.google)
{

  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
    inputRef.current,
    options
  );
  autoCompleteRef?.current?.addListener("place_changed", handlePlaceSelect);

}}, [options]);

  const handlePlaceSelect = () => {
    const selectedPlace = autoCompleteRef?.current?.getPlace();

    if (selectedPlace) {
      if (selectedPlace.geometry && selectedPlace.geometry.location) {
        const latitude = selectedPlace?.geometry?.location?.lat() || null;
        const longitude = selectedPlace?.geometry?.location?.lng() || null;
        setData((prev) => ({
          ...prev,
          longitude: longitude,
          latitude: latitude,
        }));
      } else {
        console.error(
          "Geometry information is not available for the selected place:",
          selectedPlace
        );
      }
    } else {
      console.error("Unable to retrieve information for the selected place");
    }
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get("search");
  const category = searchParams.get("category");
  const locationParam = searchParams.get("location");
  const longitudeParam = searchParams.get("longitude");
  const latitudeParam = searchParams.get("latitude");

  useEffect(() => {
    if (location.pathname.includes("/search")) {
      setData((data) => ({
        ...data,
        search: search,
        category: category,
        longitude: longitudeParam,
        latitude: latitudeParam,
      }));

      mutate({
        ...(search && { search: search }),
        ...(category && { category: category }),
        ...(locationParam && { location: locationParam }),
        ...(latitudeParam && { latitude: latitudeParam }),
        ...(longitudeParam && { longitude: longitudeParam }),
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="">
      <div className="container">
        {/* <div className="searchFormWrapper">
          <div className="searchBoxInput">
            <IoIosSearch className="searchFormIcon" />
            <input type="text" placeholder="Salon or Service" />
          </div>
          <div className="vBar d-none d-lg-block"></div>
          <div className="searchBoxInput">
            <IoLocationOutline className="searchFormIcon" />
            <input type="text" placeholder="Location i.e Karachi" />
          </div>
          <button className="searhFormButton d-flex align-items-center justify-content-center">
          <IoIosSearch className="text-white fs-3 me-2" />
            Search
          </button>
        </div> */}
        <div className="p0zn2V _5-TPbG">
          <div
            className={`${
              !location.pathname.includes("/search")
                ? "wePodA"
                : " wePodA spMargin"
            }`}
          >
            {!location.pathname.includes("/search") && (
              <h1 className="_-wKgxq font-laptop-display-xl-bold nDsxFQ nDsxFQ">
                Book local beauty and wellness services
              </h1>
            )}
            <div className="nhefE3">
              <div className="Rso3Yb _7W+EKV">
                <div className="H5imaw _66GsIr qiGaKv">
                  <div className="ZYfX8+ rkv1Dm">
                    <div className="EHlO6T">
                      <div
                        className="KRX-Tm _6j6RNv avz66h display-default-flex display-laptop-none ICZmSz"
                        data-qa="venue-treatment-search"
                        onClick={() => {
                          setTreatmentMobile(true);
                        }}
                      >
                        <p className="_-wKgxq font-default-body-xs-semibold -iA7Wy -iA7Wy">
                          Treatment or venue
                        </p>
                        <div className="tyeEeg">
                          <span className="_-6pBDG I-8MNG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.875 3.75a7.125 7.125 0 1 0 0 14.25 7.125 7.125 0 0 0 0-14.25ZM2.25 10.875a8.625 8.625 0 1 1 15.231 5.546l4.05 4.049a.75.75 0 1 1-1.061 1.06l-4.05-4.049a8.625 8.625 0 0 1-14.17-6.606Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                        <input
                          placeholder="Any treatment"
                          name="search"
                          onChange={onChange}
                          value={data?.search ? data?.search : data?.category}
                        />
                        <button
                          aria-label="Clear"
                          className="tyeEeg pQGfZy"
                          type="button"
                          data-qa="venue-treatment-search-clear"
                        >
                          <span className="_-6pBDG I-8MNG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm6.22-3.53a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 0-1.06Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        onClick={() => {
                          setTreatment(!treatment);
                        }}
                        className="KRX-Tm _6j6RNv avz66h display-default-none display-laptop-flex ICZmSz"
                        data-qa="venue-treatment-search"
                      >
                        <p className="_-wKgxq font-default-body-xs-semibold -iA7Wy -iA7Wy">
                          Treatment or venue
                        </p>
                        <div className="tyeEeg">
                          <span className="_-6pBDG I-8MNG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.875 3.75a7.125 7.125 0 1 0 0 14.25 7.125 7.125 0 0 0 0-14.25ZM2.25 10.875a8.625 8.625 0 1 1 15.231 5.546l4.05 4.049a.75.75 0 1 1-1.061 1.06l-4.05-4.049a8.625 8.625 0 0 1-14.17-6.606Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                        <input
                          placeholder="Any treatment"
                          name="search"
                          autoComplete="false"
                          onChange={onChange}
                          value={data?.search ? data?.search : data?.category}
                        />
                        <button
                          aria-label="Clear"
                          className="tyeEeg pQGfZy"
                          type="button"
                          data-qa="venue-treatment-search-clear"
                        >
                          <span className="_-6pBDG I-8MNG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm6.22-3.53a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 0-1.06Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      {/* Mobile location search */}
                      <div
                        className="KRX-Tm _6j6RNv avz66h display-default-flex display-laptop-none gJ1v3P"
                        data-qa="location-search"
                        onClick={() => setLocationMobile(true)}
                      >
                        <p className="_-wKgxq font-default-body-xs-semibold -iA7Wy -iA7Wy">
                          Location
                        </p>
                        <div className="tyeEeg">
                          <span className="_-6pBDG I-8MNG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11.543 22.345a.84.84 0 0 0 .983-.05 21.987 21.987 0 0 0 1.131-.9 25.156 25.156 0 0 0 2.659-2.622c1.918-2.205 3.934-5.378 3.934-9.023a8.25 8.25 0 1 0-16.5 0c0 3.645 2.016 6.818 3.934 9.023a25.146 25.146 0 0 0 3.538 3.334 14.102 14.102 0 0 0 .32.238ZM12 3a6.75 6.75 0 0 0-6.75 6.75c0 3.105 1.734 5.932 3.566 8.04A23.64 23.64 0 0 0 12 20.812a23.633 23.633 0 0 0 3.184-3.023c1.832-2.108 3.566-4.935 3.566-8.04A6.75 6.75 0 0 0 12 3Z"
                                clipRule="evenodd"
                              />
                              <path
                                fillRule="evenodd"
                                d="M12 6a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 6Zm0 1.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                        {/* <input
                        // value={data?.location}
                        placeholder="Current location"
                        name="location"
                        onChange={onChange}
                      /> */}
                        <input
                          type="text"
                          id="myInput"
                          defaultValue={data?.location}
                          placeholder="Enter a location"
                          ref={inputRef}
                        />
                        <button
                          aria-label="Clear"
                          className="tyeEeg pQGfZy"
                          type="button"
                          data-qa="location-search-clear"
                        >
                          <span className="_-6pBDG I-8MNG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm6.22-3.53a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 0-1.06Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        // onClick={() => {
                        //   setTreatment(false);
                        // }}
                        className="KRX-Tm _6j6RNv avz66h display-default-none display-laptop-flex gJ1v3P"
                        data-qa="location-search"
                      >
                        <p className="_-wKgxq font-default-body-xs-semibold -iA7Wy -iA7Wy">
                          Location
                        </p>
                        <div className="tyeEeg">
                          <span className="_-6pBDG I-8MNG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11.543 22.345a.84.84 0 0 0 .983-.05 21.987 21.987 0 0 0 1.131-.9 25.156 25.156 0 0 0 2.659-2.622c1.918-2.205 3.934-5.378 3.934-9.023a8.25 8.25 0 1 0-16.5 0c0 3.645 2.016 6.818 3.934 9.023a25.146 25.146 0 0 0 3.538 3.334 14.102 14.102 0 0 0 .32.238ZM12 3a6.75 6.75 0 0 0-6.75 6.75c0 3.105 1.734 5.932 3.566 8.04A23.64 23.64 0 0 0 12 20.812a23.633 23.633 0 0 0 3.184-3.023c1.832-2.108 3.566-4.935 3.566-8.04A6.75 6.75 0 0 0 12 3Z"
                                clipRule="evenodd"
                              />
                              <path
                                fillRule="evenodd"
                                d="M12 6a3.75 3.75 0 1 0 0 7.5A3.75 3.75 0 0 0 12 6Zm0 1.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                        {/* <input
                        value={data?.location}
                        placeholder="Current location"
                        name="location"
                        onChange={onChange}
                      /> */}
                        {/* <GooglePlacesAutocomplete
      autocompletionRequest={{
        componentRestrictions: {
          country: ["pk"],
        },
      }}
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
    /> */}

                        <input
                          type="text"
                          id="myInput"
                          placeholder="Enter a location"
                          ref={inputRef}
                          // defaultValue={searchParams ? searchParams : inputRef?.current?.value}
                          defaultValue={
                            locationParam
                              ? locationParam
                              : inputRef?.current?.value
                          }
                        />
                        <button
                          aria-label="Clear"
                          className="tyeEeg pQGfZy"
                          type="button"
                          data-qa="location-search-clear"
                        >
                          <span className="_-6pBDG I-8MNG" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12 3.75a8.25 8.25 0 1 0 0 16.5 8.25 8.25 0 0 0 0-16.5ZM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm6.22-3.53a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 0-1.06Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="display-default-none display-laptop-flex _6z3i6C">
                      <button
                        id="button-search-button-desktop"
                        type="button"
                        onClick={() => {
                          if (location?.pathname?.includes("salon/search")) {
                            mutate({
                              ...(search && { search: search }),
                              ...(category && { category: category }),
                              ...(locationParam && {
                                location: inputRef?.current?.value,
                              }),
                              ...(data?.latitude && {
                                latitude: data?.latitude,
                              }),
                              ...(data?.longitude && {
                                longitude: data?.longitude,
                              }),
                            });
                          } else {
                            navigate(
                              `/salon/search?search=${data?.search}&category=${data?.category}&location=${inputRef?.current?.value}&longitude=${data?.longitude}&latitude=${data?.latitude}}`
                            );
                          }
                        }}
                        className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU _2qQHCU JKAulU"
                        aria-pressed="false"
                        aria-labelledby="label-search-button-desktop"
                        aria-disabled="false"
                        aria-live="polite"
                      >
                        <span className="G9I_8U">
                          <span
                            id="label-search-button-desktop"
                            className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                          >
                            {isSearching ? <Spinner size="sm" /> : "Search"}
                          </span>
                        </span>
                      </button>
                    </div>

                    <div className="display-default-block display-laptop-none MJKZHK">
                      <button
                        onClick={() => {
                          if (location?.pathname?.includes("salon/search")) {
                            mutate({
                              ...(search && { search: search }),
                              ...(category && { category: category }),
                              ...(locationParam && {
                                location: inputRef?.current?.value,
                              }),
                              ...(data?.latitude && {
                                latitude: data?.latitude,
                              }),
                              ...(data?.longitude && {
                                longitude: data?.longitude,
                              }),
                            });
                          } else {
                            navigate(
                              // `/salon/search?search=${data?.search}&date=${data?.date}&category=${data?.category}&location=${data?.location}&time=${data?.time}`
                              `/salon/search?search=${data?.search}&category=${data?.category}&location=${inputRef?.current?.value}&longitude=${data?.longitude}&latitude=${data?.latitude}}`
                            );
                          }
                        }}
                        id="button-search-button-mobile-variant-home"
                        data-qa="search-button-mobile-variant-home"
                        type="button"
                        className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU"
                        aria-pressed="false"
                        aria-labelledby="label-search-button-mobile-variant-home"
                        aria-disabled="false"
                        aria-live="polite"
                      >
                        <span className="G9I_8U">
                          <span
                            id="label-search-button-mobile-variant-home"
                            className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                          >
                            {isSearching ? <Spinner size="sm" /> : "Search"}
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                {treatment && (
                  <TreatmentDesktop
                    setTreatment={setTreatment}
                    data={data}
                    setData={setData}
                  />
                )}
                {showCalander && (
                  <DateDesktop
                    setShowCalander={setShowCalander}
                    data={data}
                    setData={setData}
                  />
                )}
              </div>
            </div>
            {!location.pathname.includes("salon/search") && (
              <span className="+vayHW d-flex justify-content-center my-5">
                <a
                  className="CLnQGg _XdmmM tPDowU _i2EgU _8oamEU _2qQHCU JKAulU"
                  id="get-app"
                  href="https://play.google.com/store/apps/details?id=com.beautowns"
                  target="_blank"
                  data-qa="get-app"
                  rel="noreferrer"
                >
                  <span className="G9I_8U">
                    <span
                      id="label-get-app"
                      className="_-wKgxq ZCvNLq font-default-body-m-semibold"
                    >
                      Get the app
                    </span>
                  </span>
                  <span className="_0f0EDU">
                    <span className="_-6pBDG _8oamQG" aria-hidden="true">
                      <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.75 5.25a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5v-4.5Zm6 0h-4.5v4.5h4.5v-4.5Zm3 0a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5v-4.5Zm6 0h-4.5v4.5h4.5v-4.5Zm-15 9a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5v4.5a1.5 1.5 0 0 1-1.5 1.5h-4.5a1.5 1.5 0 0 1-1.5-1.5v-4.5Zm6 0h-4.5v4.5h4.5v-4.5Zm3.75-1.5a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75Zm3 0a.75.75 0 0 1 .75.75v.75h2.25a.75.75 0 0 1 0 1.5h-2.25v3.75a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1 0-1.5h2.25V13.5a.75.75 0 0 1 .75-.75Zm3 4.5a.75.75 0 0 1 .75.75v1.5a.75.75 0 0 1-1.5 0V18a.75.75 0 0 1 .75-.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </span>
                </a>
                <span className="p27OtY">
                  <a
                    className="CLnQGg _XdmmM _1FWB8T"
                    href="https://play.google.com/store/apps/details?id=com.beautowns"
                    target="_self"
                  >
                    <picture className="rC5TQo _-rOGgo Qs8P0o -ywKMp">
                      <img
                        src={qrCode}
                        alt="QR CODE OF BEAUTOWNS"
                        className="N3bmGo _-rOGgo p0zJqo -ywKMp"
                        loading="lazy"
                      />
                    </picture>
                  </a>
                  <p className="_-wKgxq font-default-body-s-semibold">
                    Scan to download
                  </p>
                </span>
              </span>
            )}
            {/* <div className="hmmt-O">
            <span className="_-wKgxq _9CRVlq font-default-body-m-semibold font-laptop-header-m-semibold">
              795,736
            </span>
            <span className="_-wKgxq KfKHkq font-default-body-m-regular font-laptop-body-l-regular">
              appointments booked today
            </span>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section1;
