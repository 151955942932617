import React, { useEffect, useState } from "react";
import DateScroll from "./DateScroll";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import apis from "../../services";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { setDate, setStep, setTime } from "../../redux/slices/serviceSlice";
import { StepBar } from "../StepBar";

const AppointmentStepFour = () => {
  const navigate = useNavigate();
  const { store } = useSelector((e) => e?.store?.storeDetails);
  const { services, staff, date, time } = useSelector((e) => e?.service);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [storeSlots, setStoreSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectdSlots, setSelectedSlots] = useState();
  const [staffImageError, setStaffImageError] = useState(false);
  const totalAmount = services?.reduce((acc, obj) => {
    const valueToAdd = obj?.value;
    if (typeof valueToAdd === "number") {
      return acc + valueToAdd;
    } else {
      return acc;
    }
  }, 0);

  const totalDuration = services?.reduce((acc, obj) => {
    const valueToAdd = obj?.duration;
    if (typeof valueToAdd === "number") {
      return acc + valueToAdd;
    } else {
      return acc;
    }
  }, 0);

  const get_slot = async () => {
    try {
      setIsLoading(true);
      let slots;
      if (staff) {
        slots = await apis.getStoreSlot({
          id: store?._id,
          duration: totalDuration,
          staff_Id: staff?._id,
        });
      } else {
        slots = await apis.getStoreSlotwithoutStaff({
          id: store?._id,
          duration: totalDuration,
        });
      }
      setStoreSlots(slots?.data?.data);
      setIsLoading(false);
      setSelectedSlots(slots?.data?.data?.[0]);
      dispatch(
        setDate(
          moment(slots?.data?.data?.[0]?.date?.completeDate).format(
            "YYYY-MM-DD"
          )
        )
      );
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(setStep(3));
    get_slot();
    if (!services?.length > 0) {
      navigate("/salon/select-service");
    }
  }, []);

  return (
    <div id="react" className="react--loading">
      {isLoading && <div id="cover-spin"></div>}

      <div className="GXRSVM notranslate">
        <div className="mre0Hq w+g1+Q" featureflags="[object Object]">
          <div className="_32iRow">
            <div className="Df5Lk6">
              <div className="E4hVuT JdS1yT xa-rvT">
                <div className="_7nJ-pT JdS1yT">
                  <div className="wwqFAT JdS1yT">
                    <Link to="/salon/select-staff">
                      <button
                        id="button-header-flow-back"
                        data-qa="header-flow-back"
                        className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU DetaJT"
                        type="button"
                        aria-pressed="false"
                        aria-labelledby="label-header-flow-back"
                        aria-live="polite"
                      >
                        <span className="G9I_8U va37oU">
                          <span
                            id="label-header-flow-back"
                            className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                          >
                            Go back
                          </span>
                        </span>
                        <span className="_0f0EDU">
                          <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                            <svg
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11.03 4.72a.75.75 0 0 1 0 1.06l-5.47 5.47h14.69a.75.75 0 0 1 0 1.5H5.56l5.47 5.47a.75.75 0 1 1-1.06 1.06l-6.75-6.75a.75.75 0 0 1 0-1.06l6.75-6.75a.75.75 0 0 1 1.06 0Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>

                  <div className="p1pdhT JdS1yT">
                    <span className="_-wKhBx rfrUux Bnx6rx dUG8gx ZCvJqx KfKZXx font-default-header-s-semibold font-tablet-header-s-semibold font-laptop-header-s-semibold font-desktop-header-s-semibold _zngzT">
                      Select time
                    </span>
                  </div>

                  <div
                    className="wwqFAT ibymkT"
                    onClick={() => navigate(`/salon/${store?.slug}`)}
                  >
                    <button
                      id="button-close-button"
                      data-qa="close-button"
                      className="bXwNAZ _XdmmM tPDowU _3epCrU p0rQDU _8oamEU WnQevU JKAulU nE2lYU"
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="label-close-button"
                      aria-live="polite"
                    >
                      <span className="G9I_8U va37oU">
                        <span
                          id="label-close-button"
                          className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                        >
                          Close
                        </span>
                      </span>
                      <span className="_0f0EDU">
                        <span className="_-6pwFd _8oa-nd" aria-hidden="true">
                          <svg
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="YAjmY7 NekGj7">
                <div className="MZ1PW7" />

                <div className="otg7W7 NekGj7 tUf8c7">
                  <div className="iWARt7">
                    <div className="display-default-none display-laptop-flex">
                      <div data-qa="header-title" className="dYmf3l w-100">
                        <div className="container my-5">
                          <div className="col-11 mx-auto">
                            <StepBar />
                          </div>
                        </div>

                        <div className="fWGqQa fwrHUa _4zQoha Aocp_a gap-default-200x fWGqQa">
                          <h1
                            className="_-wKhBx rfrUux font-default-header-l-semibold font-tablet-header-xl-bold font-laptop-header-xl-bold font-desktop-header-xl-bold"
                            style={{ flexGrow: 1 }}
                          >
                            Select time
                          </h1>
                        </div>
                      </div>
                    </div>

                    <div className="display-default-flex display-laptop-none">
                      <div className="dYmf3l OZAYBl w-100">
                        <div className="container mt-3 mb-5">
                          <div className="col-11 mx-auto">
                            <StepBar />
                          </div>
                        </div>
                        <div className="fWGqQa fwrHUa _4zQoha Aocp_a gap-default-200x fWGqQa">
                          <h1
                            className="_-wKhBx rfrUux font-default-header-l-semibold font-tablet-header-xl-bold font-laptop-header-xl-bold font-desktop-header-xl-bold"
                            style={{ flexGrow: 1 }}
                          >
                            Select time
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="gbuHog Nekhlg XHPkvf p-2 mb-5 pb-3">
                    <DateScroll
                      storeSlots={storeSlots}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      setDate={setDate}
                      date={date}
                      setTime={setTime}
                      time={time}
                      selectdSlots={selectdSlots}
                      dispatch={dispatch}
                      setSelectedSlots={setSelectedSlots}
                      // setData={setData}
                    />
                  </div>
                  <div className="QrHNxj Aqljbj UuHXUj NekZnj">
                    <div
                      id="booking-flow-cart"
                      data-qa="cart"
                      className="pzS8Sv OGOgmv Y5Bsdv whlC2v _10Cwnv Mnqemv PqhSRN"
                    >
                      <div className="h0MWJS">
                        <div className="POY5Gv XXoKXv BSVEBv dTkYBv WfxCyR">
                          <div className="hB0YAs">
                            <div className="b6xJNc J0Qdgc GmK7bc W-cbgF">
                              <picture className="rC5TQo _-rOGgo oN-sZo gsRUJF GmKi4F">
                                <img
                                  src={store?.image}
                                  alt={store?.name}
                                  className="N3bmGo _-rOGgo p0zJqo gsRUJF GmKi4F object-fit-cover"
                                  loading="lazy"
                                />
                              </picture>
                            </div>

                            <div
                              className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa"
                              style={{ minWidth: "0px" }}
                            >
                              <p className="_-wKhBx font-default-body-s-semibold">
                                {store?.name}
                              </p>
                              <div
                                className="gQF-0-"
                                id="rating"
                                data-qa="rating"
                              >
                                <div className="aZptzi I-8Vzi">
                                  <p id="rating-aria" className="qTs2Ai">
                                    5 rating
                                  </p>
                                  <div className="ADCqki I-8Vzi">
                                    <span
                                      aria-hidden="true"
                                      className="_-wKhBx rfrUux _9CRQ7x font-default-body-s-semibold"
                                    >
                                      5.0
                                    </span>
                                    <div
                                      className="mKS7Nt I-8Lrt"
                                      aria-hidden="true"
                                    >
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                      <span
                                        className="_-6pwFd _-YxYKd"
                                        aria-hidden="true"
                                      >
                                        <svg
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                        >
                                          <path d="m21.975 9.974-4.225 3.69 1.266 5.495a1.502 1.502 0 0 1-1.395 1.84 1.499 1.499 0 0 1-.839-.214l-4.788-2.907-4.778 2.907a1.499 1.499 0 0 1-2.234-1.626l1.264-5.489L2.02 9.974a1.5 1.5 0 0 1 .853-2.63l5.571-.484 2.175-5.19a1.495 1.495 0 0 1 2.758 0l2.18 5.19 5.57.483a1.498 1.498 0 0 1 1.318 1.899 1.5 1.5 0 0 1-.465.732h-.005Z" />
                                        </svg>
                                      </span>
                                    </div>
                                    <span
                                      aria-hidden="true"
                                      className="_-wKhBx rfrUux font-default-body-s-regular MRYHBB I-8UmB"
                                    ></span>
                                  </div>
                                </div>
                              </div>
                              <p className="_-wKhBx u4x-xx ZCvJqx KfKZXx font-default-body-s-regular">
                                {store?.location}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv ZRg0kv _8WTxMv zsU6-w">
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Overview
                          </p>
                          <ul className="_5zCVQX gap-default-100 direction-default-vertical display-default-flex">
                            {services?.length > 0 &&
                              services?.map((e, i) => (
                                <li className="p_e4TX" key={i}>
                                  <div
                                    data-qa="booking-info-service-colour-consultation"
                                    className="yhiMeE EuGs2E"
                                  >
                                    <div className="_6lhOdE">
                                      <div className="_002iGE">
                                        <div className="_nTKkE">
                                          <div className="_2rJeYE">
                                            <p
                                              data-qa="list-item-name"
                                              className="_-wKhBx font-default-body-s-medium"
                                            >
                                              {e?.name}
                                            </p>
                                            <p
                                              data-qa="list-item-description"
                                              className="_-wKhBx u4x-xx font-default-body-s-regular"
                                            >
                                              {e?.duration} min
                                            </p>
                                          </div>
                                          <div className="AzPtSE beZrJE">
                                            <div className="Yer71q">
                                              <p
                                                data-qa="list-item-retail-price"
                                                className="_-wKhBx rfrUux font-default-body-s-medium"
                                              >
                                                {e?.value} PKR
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            <li className="p_e4TX">
                              <div
                                data-qa="booking-info-service-colour-consultation"
                                className="yhiMeE EuGs2E"
                              >
                                <div className="_6lhOdE">
                                  <div className="_002iGE">
                                    <div className="_nTKkE">
                                      <div className="_2rJeYE">
                                        <p
                                          data-qa="list-item-name"
                                          className="_-wKhBx font-default-body-s-medium"
                                        >
                                          Total Duration
                                        </p>
                                      </div>
                                      <div className="AzPtSE beZrJE">
                                        <div className="Yer71q">
                                          <p
                                            data-qa="list-item-retail-price"
                                            className="_-wKhBx rfrUux font-default-body-s-medium"
                                          >
                                            {totalDuration} min
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="POY5Gv XXoKXv c0imxv _8WTxMv oEA38h">
                          <hr
                            aria-orientation="horizontal"
                            className="KT-if1 CChMQ1 Sm3CD1 pz1vF1 oM6NSq"
                          />
                          <div className="fWGqQa R-waSa Aocp_a gap-default-25x fWGqQa">
                            <div className="yhiMeE EuGs2E">
                              <div className="_6lhOdE">
                                <div className="_002iGE">
                                  <div className="_nTKkE">
                                    <div className="_2rJeYE">
                                      <p className="_-wKhBx font-default-body-m-semibold">
                                        Total
                                      </p>
                                    </div>
                                    <div className="AzPtSE beZrJE">
                                      <p
                                        data-qa="total-price"
                                        className="_-wKhBx font-default-body-m-semibold"
                                      >
                                        {totalAmount} PKR
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="POY5Gv XXoKXv _5l5B-v _8WTxMv vM7X54">
                          <p className="_-wKhBx font-default-header-s-semibold mb-default-250x">
                            Appointment
                          </p>
                          {staff && (
                            <div
                              data-qa="cart-employee-info"
                              className="_5zCVQX gap-default-200x direction-default-vertical display-default-inline-flex lx2Dn1 mt-2"
                            >
                              <div
                                className="_5zCVQX gap-default-200x direction-default-horizontal display-default-flex"
                                style={{ alignItems: "center" }}
                              >
                                <div className="b6xJNc _SbjAc _1WMFBc _0alsKn">
                                {staffImageError ? (
                                    <div className="noStaff">{staff?.name?.charAt(0).toUpperCase()}</div>
                                  ) : (
                                    <img
                                      src={staff?.image}
                                      className="img-fluid object-fit-cover "
                                      style={{ height: "100%" }}
                                      alt=""
                                      onError={() => {
                                        setStaffImageError(true);
                                      }}
                                    />
                                  )}
                                </div>
                                <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-first-name"
                                      className="_-wKhBx font-default-body-s-medium"
                                    >
                                      {staff?.name}
                                    </p>
                                  </li>
                                  <li className="p_e4TX">
                                    <p
                                      data-qa="cart-employee-title"
                                      className="_-wKhBx u4x-xx font-default-body-s-regular"
                                    >
                                      {staff?.title}
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="POY5Gv XXoKXv QpAXqv xv4qgv GNmo0c">
                          <button
                            onClick={() => {
                              if (date && time) {
                                navigate("/salon/confirm");
                              } else {
                                toast.error("Please select time", { id: 1 });
                              }
                            }}
                            id="button-continue-button"
                            data-qa="continue-button"
                            type="button"
                            className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU rounded-2 w-100"
                            aria-pressed="false"
                            aria-labelledby="label-continue-button"
                            aria-live="polite"
                          >
                            <span className="G9I_8U">
                              <span
                                id="label-continue-button"
                                className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                              >
                                Continue
                                {/* {isBookingCreating || isConfirming ? (
                                 
                                 <>
                                  <Spinner size="sm" />
                                 </>
                                ) : (
                                  "Book Now"
                                )} */}
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="MZ1PW7" />
              </div>
              <div
                data-qa="bottom-bar"
                className="gMnlBP bjDb5P xa-C1P DETZNP zFOHKP fixed-bottom  display-default-block display-laptop-none"
              >
                <div className="K9p22P">
                  <ul
                    className="_5zCVQX gap-default-0 direction-default-horizontal display-default-flex"
                    style={{ justifyContent: "space-between", flexGrow: 1 }}
                  >
                    <li className="p_e4TX">
                      <ul className="_5zCVQX gap-default-0 direction-default-vertical display-default-inline-flex">
                        <li className="p_e4TX">
                          <p className="_-wKhBx font-default-body-s-semibold">
                            Total {totalAmount} PKR
                          </p>
                        </li>
                        <li className="p_e4TX">
                          <p className="_-wKhBx u4x-xx font-default-body-s-regular">
                            {services?.length} services
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="p_e4TX">
                      <button
                        onClick={() => {
                          if (date && time) {
                            navigate("/salon/confirm");
                          } else {
                            toast.error("Please select time", { id: 1 });
                          }
                        }}
                        id="button-react-aria-106"
                        data-qa="continue-button-mobile"
                        className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU JKAulU"
                        type="button"
                        aria-pressed="false"
                        aria-labelledby="label-react-aria-106"
                        aria-live="polite"
                      >
                        <span className="G9I_8U">
                          <span
                            id="label-react-aria-106"
                            className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                          >
                            Continue
                          </span>
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
                {/* <div className="K9p22P d-flex justify-content-between">
                  <div className="d-flex flex-column ">
                    <h5 className="m-0">Total {totalAmount} PKR</h5>
                    <p>{services?.length} Service</p>
                  </div>

                  <button
                    id="button-continue-button"
                    data-qa="continue-button"
                    type="button"
                    className="bXwNAZ _XdmmM tPDowU igySVU _8oamEU WnQevU GGLUcU w-auto"
                    aria-pressed="false"
                    aria-labelledby="label-continue-button"
                   
                    aria-live="polite"
                    onClick={() => {
                      if (!user?.user?.isVerified) {
                        setShow(true);
                      } else if (data?.time === "") {
                        toast.error("Please select time");
                      } else {
                        mutate();
                      }
                    }}
                  >
                    <span className="G9I_8U">
                      <span

                        id="label-continue-button"
                        className="_-wKhBx ZCvJqx font-default-body-m-semibold"
                      >
                        {isBookingCreating || isConfirming ? (
                          <>
                         Booking <Spinner size="sm" />
                          </>
                        ) : (
                          "Book Now"
                        )}
                        Continue
                      </span>
                    </span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentStepFour;
