import React, { useEffect, useRef, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { IoIosSend } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";

const UserSalonChat = ({ showMenu, setShowMenu, socket }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { user } = useSelector((store) => store?.user);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showMessages, setShowMessages] = useState(false);
  const [chatWith, setChatWith] = useState(0);
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [imageError, setImageError] = useState([]);
  const [bookings, setBookings] = useState([]);
  const chatHistoryRef = useRef(null);
  const { mutate: getBookings, isLoading } = useMutation(
    () => apis?.getUserBookings(user?._id),
    {
      onError: function ({ message }) {
        console.log(message, "message");
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          setBookings(data?.booking);
        }
      },
    }
  );

  useEffect(() => {
    getBookings();
  }, []);

  const uniqueBookings = () => {
    const uniqueSalonIds = new Set();
    const result = bookings?.filter((booking) => {
      if (uniqueSalonIds?.has(booking?.store_Id?._id)) {
        return false;
      }
      uniqueSalonIds?.add(booking?.store_Id?._id);
      return true;
    });

    return result;
  };

  const uniqueSalons = uniqueBookings();

  const [selectedCustomer, setSelectedCustomer] = useState();

  const handleImageError = (index) => {
    setImageError((prevErrors) => {
      let newErrors = [...prevErrors];
      newErrors[index] = true;
      return newErrors;
    });
  };

  socket.on("receiveMessage", (data) => {
    setMessages(data?.previousMessages || []);
  });

  useEffect(() => {
    setMessages([])
    socket.emit(
      "sendHistory",
      { receiver_Id: selectedCustomer?.salon_owner_Id, sender_Id: user?._id },
      (data) => {
        setMessages(data?.previousMessages || []);
      }
    );
  }, [selectedCustomer]);


  useEffect(() => {
    setSelectedCustomer(uniqueSalons?.[0]?.store_Id);
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    
    socket.emit(
      "sendMessage",
      {
        receiver_Id: selectedCustomer?.salon_owner_Id,
        sender_Id: user?._id,
        message: messageInput,
      },
      (ack) => {
        setMessages(ack?.previousMessages || []);
      }
    );
    setMessageInput("");
  };

  const [StoreName, setStoreName] = useState("");

  const filterCustomer = () => {
    let customer;
    const lowercaseCustomerName = StoreName?.toLowerCase();
    customer = uniqueSalons?.filter(({ store_Id }) =>
      store_Id?.name?.toLowerCase()?.includes(lowercaseCustomerName)
    );
    return customer;
  };


  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);


  return (
    <>
      <div id="react" className="react--loading">
        {isLoading && <div id="cover-spin"></div>}

        <div className="">
          <div className="">
            <Header
              setShowDropdown={setShowDropdown}
              showDropdown={showDropdown}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
            />
            <div className="L5Ntp6 mt-5 pt-5">
              <div className="container min-vh-50">
                <div className="row clearfix mt-4">
                  <div className="col-lg-12">
                    <div className="chat-app bg-white rounded-3 shadow mb-5 w-100 d-flex">
                      <div
                        className={`people-list overflow-hidden  ${
                          windowWidth < 768 && showMessages && "d-none"
                        }`}
                      >
                        <div className="input-group p-3">
                          <input
                            type="text"
                            value={StoreName}
                            onChange={(e) => setStoreName(e.target.value)}
                            className="form-control"
                            placeholder="Search..."
                          />
                        </div>
                        <div className="overflow-y-auto px-3">
                          <ul className="list-unstyled chat-list mt-2 mb-0">
                            {filterCustomer()?.map(({ store_Id }, index) => (
                              <li
                                className={`clearfix d-flex align-items-center ${
                                  chatWith === index && "active"
                                }`}
                                onClick={() => {
                                  setShowMessages(true);
                                  setSelectedCustomer(store_Id);
                                  setChatWith(index);
                                }}
                                key={index}
                              >
                                {store_Id?.image && (
                                  <img
                                    src={store_Id?.image}
                                    className="img-fluid chatUserListImg"
                                    alt="avatar"
                                    style={{
                                      display: imageError[index]
                                        ? "none"
                                        : "block",
                                    }}
                                    onError={() => handleImageError(index)}
                                  />
                                )}
                                {(imageError[index] || !store_Id?.image) && (
                                  <div className="alterWrapper text-uppercase ">
                                    {store_Id?.name?.substring(0, 1)}
                                  </div>
                                )}
                                <div className="about">
                                  <div className="name textEclips">
                                    {store_Id?.name}
                                  </div>
                                  <div className="status textEclips text-nowrap">
                                    Salon Owner
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`chat ${
                          windowWidth < 768 && !showMessages && "d-none"
                        }`}
                      >
                        <div className="chat-header clearfix">
                          <div className="row">
                            <div className="col-lg-6 d-flex">
                              {windowWidth < 768 ? (
                                <IoIosArrowBack
                                  onClick={() => setShowMessages(!showMessages)}
                                  className="fs-1 hc mt-2 me-3"
                                />
                              ) : (
                                ""
                              )}
                              {selectedCustomer?.image && (
                                <img
                                  src={selectedCustomer?.image}
                                  className="img-fluid object-fit-cover"
                                  alt="avatar"
                                  style={{
                                    display: imageError[chatWith]
                                      ? "none"
                                      : "block",
                                  }}
                                />
                              )}
                              {(imageError[chatWith] ||
                                !selectedCustomer?.image) && (
                                <div className="alterWrapper text-uppercase ">
                                  {selectedCustomer?.name?.substring(0, 1)}
                                </div>
                              )}

                              <div className="chat-about">
                                <h6 className="m-b-0 mb-0 textEclips1">
                                  {selectedCustomer?.name}
                                </h6>
                                <smal className="textEclips1">Salon Owner</smal>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="chat-history"   ref={chatHistoryRef}>
                          <ul className="m-b-0">
                            {messages?.map((message) => {
                              return (
                                <li className="clearfix">
                                  <div
                                    className={`message ${
                                      message?.sender_Id === user?._id
                                        ? "my-message float-right"
                                        : "other-message"
                                    }`}
                                  >
                                    {message?.message}
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <form onSubmit={sendMessage}>
                        <div className="chat-message clearfix">
                          <div className="position-relative">
                            <IoIosSend
                              type="button"
                           
                              className="sendIcon"
                            />
                            <input
                              type="text"
                              value={messageInput}
                              onChange={(e) => setMessageInput(e.target.value)}
                              className="form-control"
                              placeholder="Enter text here..."
                            />
                          </div>
                        </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSalonChat;
