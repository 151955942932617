import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import apis from "../../services";
import { useMutation } from "@tanstack/react-query";
import { setUser } from "../../redux/slices/userSlice";
import { RiAiGenerate } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { FaCopy } from "react-icons/fa6";
import ReferralPolicyModal from "./ReferralPolicyModal";

const Referral = ({ user }) => {
  const dispatch = useDispatch();
  const [allReferrals, setAllReferrals] = useState([]);
  const [totalReward, setTotalReward] = useState(0);
  const [show, setShow] = useState(false);

  const { mutate: generateReferralLink, isLoading } = useMutation(
    () => apis.generateUserReferral(user?._id),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(setUser(data?.user));
          toast.success(data?.message, { id: 1 });
        }
      },
    }
  );

  const { mutate: getUserReferrals, isLoading: gettingRefferals } = useMutation(
    () => apis.getUserReferral(user?._id),
    {
      onError: function ({ message }) {
        toast.error(message?.message, { id: 1 });
      },
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          setTotalReward(data?.totalReferral);
          setAllReferrals(data?.referral);
        }
      },
    }
  );

  useEffect(() => {
    getUserReferrals();
  }, []);

  const referralLink = `https://beautowns.com?referralCode=${user?.referralCode}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success("Link copied to clipboard", { id: 2 });
  };

  return (
    <>
      {gettingRefferals && <div id="cover-spin"></div>}
      <ReferralPolicyModal show={show} setShow={setShow} />
      <h3 className="fw-bold mt-5 ">Wallet : {user?.wallet || 0}</h3>
      <h3 className="fw-bold my-5">Referrals</h3>
      <div className="d-flex justify-content-between align-items-start flex-wrap mt-5">
        <div className=" my-3 d-flex flex-column justify-content-center align-items-center">
          <h4>Total Rewards</h4>
          <h2 className="fw-bold">{totalReward || 0}</h2>
        </div>
        <button
          className=" border-0 bg-secondary text-white p-3 rounded-3"
          onClick={() => setShow(true)}
        >
          Referral Policy
        </button>
        <div className=" my-3 d-flex flex-column justify-content-center align-items-center">
          <h4>Total Invites</h4>
          <h2 className="fw-bold">{allReferrals && allReferrals?.length}</h2>
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-center justify-content-md-start  align-items-center">
        {user?.referralCode && (
          <>
            <div className="m-0 fs-5 my-2 bg-white px-3 py-2 rounded-3 text-wrap shadow-sm ">
              {referralLink}
            </div>
          </>
        )}
        {user?.referralCode ? (
          <button
            onClick={() => handleCopyToClipboard()}
            className="fs-6 my-2 ms-3 d-flex align-items-center btn btn-outline-secondary"
          >
            <FaCopy className="me-2" /> COPY
          </button>
        ) : (
          <button
            onClick={generateReferralLink}
            className="fs-6 my-2 ms-3 d-flex align-items-center btn btn-outline-secondary"
          >
            <RiAiGenerate className="me-2" />
            {isLoading ? <Spinner size="sm" /> : "Generate Referral Code"}
          </button>
        )}
      </div>
      <div class="table-responsive mb-4">
        <table class="table table-hover table-striped mt-5">
          <thead className="table-secondary">
            <tr>
              <th scope="col">Refered By</th>
              <th scope="col">Referral User</th>
              <th scope="col">Role</th>
              <th scope="col">Rewarded Amount</th>
              <th scope="col">Level</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {allReferrals?.length > 0 ? (
              allReferrals?.map((ref, i) => {
                return (
                  <tr key={i}>
                    <td>{ref?.from_referral_userId?.name}</td>
                    <td>{ref?.to_referral_userId?.name}</td>
                    <td>{ref?.to_referral_userId?.role}</td>
                    <td>{ref?.rewarded_amount}</td>
                    <td>{ref?.rewarded_level}</td>
                    <td>{ref?.status}</td>
                  </tr>
                );
              })
            ) : (
              <td colSpan={6} className="text-center py-5">
                No data found
              </td>
            )}
          </tbody>
        </table>
      </div>

      {/* {allReferrals?.length > 0 && (
        <div className="w-90 position-absolute bottom-0 start-50 translate-middle-x mb-3">
          <div className="row align-items-center text-center text-md-start">
            <div className="col-sm-6 ms-auto col-xs-6">
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage > 1 ? currentPage - 1 : currentPage
                    )
                  }
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {[...Array(totalPages)].map((_, i) => (
                  <div
                    key={i}
                    className={`pageNo ${
                      i + 1 === currentPage ? "pageNo_active" : ""
                    }`}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </div>
                ))}
                <button
                  className="pagination_btn"
                  onClick={() =>
                    setCurrentPage(
                      currentPage < totalPages ? currentPage + 1 : currentPage
                    )
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Referral;
